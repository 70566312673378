import React, { useContext, useEffect, useState } from "react";
import { styled } from "styled-components";
import {
  build,
  calendar,
  cow,
  curve,
  dollar,
  farmers,
  left_arrow,
  milk,
  payment,
  r_build,
  right_arrow,
  user,
} from "../../../../components/assets";
import { payment_history, routes } from "../../../../utils/arrays";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Context } from "../../../../context/Store";
import { FarmerSinglePage } from "../../../../api/society";
import FarmerEdit from "../../../plant/farmer-edit/FarmerEdit";
import SectionLoader from "../../../../components/sectionloader/SectionLoader";
import ComingSoon from "../../../includes/comingsoon/ComingSoon";
import Loader from "../../../../components/loader/Loader";
import FarmerPaymentList from "../../../plant/farmers/payment";

function SocietyFarmerSingle() {
  const navigate = useNavigate();
  const [farmerData, setFarmerdata] = useState();
  const [isInputDisabled, setInputDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const goBack = () => {
    navigate(-1);
  };
  const { id } = useParams();
  const {
    dispatch,
    state: { user_data },
  } = useContext(Context);

  const token = user_data.access_token;
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  useEffect(() => {
    FarmerSinglePage(config, id)
      .then((res) => {
        if (res.app_data.StatusCode == 6000) {
          setFarmerdata(res.app_data.data);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        setIsLoading(false);
      });
    setTimeout(() => setIsLoading(false), 1100);
  }, []);
  return isLoading ? (
    <Loader />
  ) : (
    <div>
      <MainContainer>
        <TopBar>
          <TopCover>
            <Arrow onClick={goBack}>
              <img src={left_arrow} alt="image" />
            </Arrow>
            <Text>
              <TopTitle>{farmerData?.farmer} </TopTitle>
              <RoutePath>Farmers / {farmerData?.farmer}</RoutePath>
            </Text>
          </TopCover>
          <Curve>
            <img src={curve} alt="" />
          </Curve>
        </TopBar>
        <Bottom>
          {/* ----------------todays milk quantity-----------------------  */}
          <QuantityContainer onClick={() => navigate(`detail`)}>
            <CoverCard>
              <Icon>
                <img src={milk} alt="icon" />
              </Icon>
              <Title>Today's Milk Quantity</Title>
            </CoverCard>
            <BottomCover>
              <Cover>
                <span>AM</span>
                <Span>
                  <span>{farmerData?.farmer_milk_am}</span> Litres
                </Span>
              </Cover>
              <Cover>
                <span>PM</span>
                <Span>
                  <span>{farmerData?.farmer_milk_pm}</span> Litres
                </Span>
              </Cover>
            </BottomCover>

            <Curve>
              <img src={curve} alt="image" />
            </Curve>
          </QuantityContainer>
          {/* -------------Todays milk quality---------------- */}
          <Container>
            <CoverCard>
              <Icon>
                <img src={milk} alt="icon" />
              </Icon>
              <Title>Today's Milk Quality</Title>
            </CoverCard>
            <BottomCover>
              <Cover>
                <span>CLR</span>
                <Span>
                  <span>
                    {farmerData?.average_clr_value == 0
                      ? 0
                      : farmerData?.average_clr_value.toFixed(2)}
                  </span>
                </Span>
              </Cover>
              <Cover>
                <span>FAT</span>
                <Span>
                  <span>
                    {farmerData?.fat_average_value == 0
                      ? 0
                      : farmerData?.fat_average_value.toFixed(2)}
                  </span>
                </Span>
              </Cover>
              <Cover>
                <span>SNF</span>
                <Span>
                  <span>
                    {farmerData?.snf_average_value == 0
                      ? 0
                      : farmerData?.snf_average_value.toFixed(2)}
                  </span>
                </Span>
              </Cover>
            </BottomCover>

            <Curve>
              <img src={curve} alt="image" />
            </Curve>
          </Container>
          {/* ------------------Cows--------------- */}
          <Container onClick={() => navigate(`/cows/${id}/`)}>
            <CoverCard>
              <Icon>
                <img src={cow} alt="icon" />
              </Icon>
              <Title>Cows</Title>
            </CoverCard>
            <BottomCover>
              <Cover>
                <Span>
                  <span>{farmerData?.number_of_cows}</span>
                </Span>
                <span>Total Cows</span>
              </Cover>
              <Cover>
                <Span>
                  <span>{farmerData?.insured_cow_count}</span>
                </Span>
                <span>Insured Cows</span>
              </Cover>
            </BottomCover>

            <Curve>
              <img src={curve} alt="image" />
            </Curve>
          </Container>
          <Average>
            <AvgCard>
              <TextAvg>
                Average milk <br /> quantity
              </TextAvg>
              <AvgCount>
                <span>
                  {farmerData?.avg_milk_quantity == 0
                    ? 0
                    : farmerData?.avg_milk_quantity.toFixed(2)}
                </span>{" "}
                Litres
              </AvgCount>
              <Curve>
                <img src={curve} alt="" />
              </Curve>
            </AvgCard>
            <AvgCard>
              <TextAvg>Average quality readings</TextAvg>
              <ContAvg>
                <Cover>
                  <span>CLR</span>
                  <Span>
                    <span>
                      {farmerData?.avg_total_clr_value == 0
                        ? 0
                        : farmerData?.avg_total_clr_value.toFixed(2)}
                    </span>
                  </Span>
                </Cover>
                <Cover>
                  <span>FAT</span>
                  <Span>
                    <span>
                      {farmerData?.avg_total_fat_value == 0
                        ? 0
                        : farmerData?.avg_total_fat_value.toFixed(2)}
                    </span>
                  </Span>
                </Cover>
                <Cover>
                  <span>SNF</span>
                  <Span>
                    <span>
                      {farmerData?.avg_total_snf_value == 0
                        ? 0
                        : farmerData?.avg_total_snf_value.toFixed(2)}
                    </span>
                  </Span>
                </Cover>
              </ContAvg>
              <Curve>
                <img src={curve} alt="" />
              </Curve>
            </AvgCard>
          </Average>
        </Bottom>
        <CoverBottom>
          <RouteInfo>
            {/* ------------------------------------farmer edit-------------------------- */}
            <FarmerEdit
              getFarmerData={farmerData}
              setInputDisabled={setInputDisabled}
              isInputDisabled={isInputDisabled}
            />
            {/* ------------------------------------farmer edit-------------------------- */}
          </RouteInfo>
          <PaymentSection>
            <PaymentTop>
              <TitlePayment>Payment history</TitlePayment>
              {/* <TitleView to={`/farmers/${"01"}/paymentlist`}>
                            View all
                            <ArrowIcon>
                                <img src={right_arrow} alt="right-arrow" />
                            </ArrowIcon>
                        </TitleView> */}
            </PaymentTop>
            {/* {payment_history.map((item, index) => (
                        <PayCard key={index}>
                            <PayLeft>
                                <PayIcon>
                                    <img src={payment} alt="icon" />
                                </PayIcon>
                                <CoverAmount>
                                    <AmountLabel>Amount</AmountLabel>
                                    <Amount>{item.amount}</Amount>
                                </CoverAmount>
                            </PayLeft>
                            <PayRight>
                                <Date>
                                    <span>{item.date}</span>
                                    <CoverIcon>
                                        <img src={calendar} alt="image" />
                                    </CoverIcon>
                                </Date>
                                <DollarIcon>
                                    <span>Bank</span>
                                    <CoverIcon>
                                        <img src={dollar} alt="image" />
                                    </CoverIcon>
                                </DollarIcon>
                            </PayRight>
                        </PayCard>
                    ))} */}
            <PaymentCover>
              <FarmerPaymentList />
            </PaymentCover>
            {/* <ComingSoon /> */}
          </PaymentSection>
        </CoverBottom>
      </MainContainer>
    </div>
  );
}

export default SocietyFarmerSingle;
const MainContainer = styled.div`
  padding: 20px;
  max-height: calc(100vh - 100px);
  overflow: scroll;
  overflow-x: hidden;
  
`;
const TopBar = styled.div`
  padding: 20px;
  background-color: #fff;
  position: relative;
  border: 1px solid #dfe8ed;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #dfe8ed;
  border-radius: 5px;
`;
const TopCover = styled.div`
  display: flex;
`;
const Arrow = styled.div`
  border: 1px solid #2382d4;
  display: flex;
  cursor: pointer;
  border-radius: 5px;
  padding: 4px;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
`;
const Text = styled.div``;
const PaymentSection = styled.div`
  background-color: #fff;
  width: 40%;
  border: 1px solid #dfe8ed;
  border-radius: 8px;
  padding: 30px;
  @media (max-width:980px) {
    width: 100%;
  }
`;
const CoverBottom = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 20px;
  @media (max-width:980px) {
    flex-direction:column;
    gap: 40px;
  }
`;
const TopTitle = styled.h2`
  color: #0a0a0a;
  margin-bottom: 5px;
`;
const RoutePath = styled.h4`
  color: #747474;
`;
const Curve = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: -1;
  img {
    width: 100%;
    display: block;
  }
`;
const Bottom = styled.div`
  display: flex;
  width:100%;
  @media (max-width:768px) {
    display:grid;
    grid-template-columns: repeat(1, 1fr);
    gap:20px;
    width:100%;
  }
  @media (min-width: 769px)and (max-width:1080px) {
    display:grid;
    grid-template-columns: repeat(2, 1fr);
    gap:20px;
    width:100%;
  }
`;

const sharedStyles = `
  position: relative;
  background-color: #fff;
  padding: 20px 30px;
  width: 20%;
  /* height: 250px; */
  margin-right: 20px;
  border: 1px solid #dfe8ed;
  border-radius: 8px;
  z-index: 1;
  &:last-child {
    margin-right: 0;
  }
  h1 {
    color: #2382d4;
    font-size: 40px;
    margin-right: 10px;
  }
  h3 {
    color: #2382d4;
    font-family: "inter_regular";
    font-size: 22px;
  }
  @media (max-width:1080px) {
    
    width:100%;
  }
`;

const Container = styled.div`
  ${sharedStyles};
  cursor: pointer;
`;
const QuantityContainer = styled.div`
  ${sharedStyles};
  cursor: pointer;
`;
const Icon = styled.div`
  width: 40px;
  margin-right: 10px;
  img {
    width: 100%;
    display: block;
  }
`;
const Title = styled.h4`
  color: #0a0a0a;
  font-family: "inter_regular";
  font-weight: unset;
  font-size: 20px;
`;
const Cover = styled.div`
  /* margin-top: 10px;
  margin-bottom: 20px;
  margin-right: 50px; */
  span {
    color: #747474;
    font-family: "inter_regular";
  }
`;
const CoverCard = styled.div`
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  margin-bottom: 20px;
`;
const RouteInfo = styled.div`
  background-color: #fff;
  border: 1px solid #dfe8ed;
  border-radius: 8px;
  padding: 30px;
  width: 60%;
  margin-right: 20px;
  @media (max-width:980px) {
    width: 100%;
  }
`;
const PaymentTop = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
`;
const TitlePayment = styled.h2``;
const BottomCover = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;
const Span = styled.h2`
  font-size: 20px;
  span {
    font-size: 20px;
    font-family: "inter_medium";
    color: #2382d4;
  }
  color: #2382d4;
  margin-top: 10px;
`;
const Average = styled.div`
  width: 35%;
  @media (max-width:1080px) {
    width: 100%;
  }
`;
const AvgCard = styled.div`
  display: flex;
  border: 1px solid #dfe8ed;
  padding: 20px;
  border-radius: 5px;
  align-items: center;
  justify-content: space-between;
  position: relative;
  background-color: #fff;
  margin-bottom: 20px;
  z-index: 1;
  &:last-child {
    margin-bottom: 0;
  }
  
`;
const AvgCount = styled.div`
  color: #2382d4;
  z-index: 1;
  span {
    font-size: 28px;
    font-family: "inter_medium";
    color: #2382d4;
  }
`;
const TextAvg = styled.div`
  margin-right: 20px;
`;
const ContAvg = styled.div`
  display: flex;
  justify-content: space-between;
  width: 60%;
`;
const PaymentCover = styled.div``;
