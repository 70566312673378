import React, { useContext, useEffect, useState } from "react";
import { styled } from "styled-components";
import { Card, LineChartGraph } from "../../../components";
import {
  clr,
  curve,
  dropdown,
  fat,
  filter,
  milk,
  print,
  snf,
} from "../../../components/assets";
import BarChartGraph from "../../../components/chart/BarChartGrapfh";
import {
  SocietyMilkStatusView,
  SocietyQualityGraphData,
  SocietyQuantityGraphData,
} from "../../../api/society";
import { Context } from "../../../context/Store";
import { Link, NavLink, useParams } from "react-router-dom";
import Loader from "../../../components/loader/Loader";

function MilkView() {
  const [filter, setFilter] = useState("all");
  const [milkStatusValues, setMilkStatusValues] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [getFormattedDate, setFormatedDate] = useState();
  const [graphData, setGraphData] = useState({});
  const [qualityGraphData, setQualityGraphdata] = useState();
  const [isDropDown, setDropDown] = useState("");
  const [qualityFilter, setQualityFilter] = useState("This Week");
  const [QualityFilterValues, setQualityFilterValues] = useState("");
  const [filterData, setFilterData] = useState("This Week");
  const [filterValues, setFilterValues] = useState("");

  const {
    dispatch,
    state: { user_data },
  } = useContext(Context);
  const token = user_data.access_token;
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const param = filter;
  useEffect(() => {
    SocietyMilkStatusView(config, param)
      .then((res) => {
        if (res.app_data.StatusCode == 6000) {
          setMilkStatusValues(res.app_data);
          setIsLoading(false);
        } else {
          setMilkStatusValues("");
          setIsLoading(false);
        }
      })
      .catch((err) => {
        setIsLoading(false);
      });
    setTimeout(() => setIsLoading(false), 1100);
  }, [filter]);
  useEffect(() => {
    dispatch({
      type: "UPDATE_USER_DATA",
      user_data: {
        active_menu: "milk-report",
      },
    });
  }, []);
  useEffect(() => {
    if (milkStatusValues) {
      const inputDate = milkStatusValues?.current_date;
      const parts = inputDate?.split(",");
      const formattedDate = parts[0] + ", " + parts[1];
      setFormatedDate(formattedDate);
    }
  }, [milkStatusValues]);
  const filter_tabs = [
    {
      id: 1,
      title: "All",
      value: "all",
    },
    {
      id: 1,
      title: "AM",
      value: "am",
    },
    {
      id: 1,
      title: "PM",
      value: "pm",
    },
  ];
  const quantity = "quantity";
  const quality = "quality";
  const filter_keys = [
    {
      id: 1,
      title: "This Month",
      value: "30",
    },
    {
      id: 2,
      title: "This Week",
      value: "10",
    },
  ];
  const handleFiter = (item) => {
    setFilterData(item.title);
    setDropDown(null);
  };
  const handleQualityFiter = (item) => {
    setQualityFilter(item.title);
    setDropDown(null);
  };
  const handleDropDown = (item) => {
    if (isDropDown) {
      setDropDown("");
    } else {
      setDropDown(item);
    }
  };
  // ------------graph datas--------------------
  useEffect(() => {
    SocietyQuantityGraphData(config, filterValues).then((res) => {
      setGraphData(res.data);
    });
    SocietyQualityGraphData(config, QualityFilterValues).then((res) => {
      setQualityGraphdata(res.data);
    });
  }, [filterValues, QualityFilterValues]);
  return isLoading ? (
    <Loader />
  ) : (
    <div>
      <Container>
        <TopBar>
          <Div>
            <Title>Milk report</Title>
            <ReportButton to="/milk-report">View report</ReportButton>
          </Div>
          <FilterSection>
            <Date>{getFormattedDate}</Date>
            <Time>
              {filter_tabs.map((item) => (
                <TimeTab
                  onClick={() => setFilter(item.value)}
                  className={filter === item.value && "active"}
                >
                  {item.title}
                </TimeTab>
              ))}
            </Time>
          </FilterSection>
        </TopBar>
        <BottomBar>
          <CoverCard>
            <Card
              title="Total milk received"
              icon={milk}
              number={
                milkStatusValues?.total_milk_recieved
                  ? milkStatusValues?.total_milk_recieved
                  : "0"
              }
              label="Litres"
            />
            <Card
              title="Spoiled milk"
              icon={milk}
              number={
                milkStatusValues?.spoiled_milk
                  ? milkStatusValues?.spoiled_milk
                  : "0"
              }
              label="Litres"
            />
          </CoverCard>
          <TotalValue>
            <TotalCard>
              <Icon>
                <img src={clr} alt="image" />
              </Icon>
              <Span>Total CLR value</Span>
              <Count>
                {milkStatusValues?.avg_total_clr_value
                  ? milkStatusValues?.avg_total_clr_value
                  : "0"}
              </Count>
              <Curve>
                <img src={curve} alt="image" />
              </Curve>
            </TotalCard>
            <TotalCard>
              <Icon>
                <img src={fat} alt="image" />
              </Icon>
              <Span>Total FAT value</Span>
              <Count>
                {milkStatusValues?.avg_total_fat_value
                  ? milkStatusValues?.avg_total_fat_value
                  : "0"}
              </Count>
              <Curve>
                <img src={curve} alt="image" />
              </Curve>
            </TotalCard>
            <TotalCard>
              <Icon>
                <img src={snf} alt="image" />
              </Icon>
              <Span>Total SNF value</Span>
              <Count>
                {" "}
                {milkStatusValues?.avg_total_snf_value
                  ? milkStatusValues?.avg_total_snf_value
                  : "0"}
              </Count>
              <Curve>
                <img src={curve} alt="image" />
              </Curve>
            </TotalCard>
          </TotalValue>
        </BottomBar>
        <GraphContainer>
          <Cover>
            <TitleCover>
              <TitleGraph>Quantity Analysis</TitleGraph>
              <Drop onClick={() => handleDropDown(quantity)}>
                {filterData}
                <Icon>
                  <img src={dropdown} alt="icon" />
                </Icon>
                {isDropDown == "quantity" && (
                  <DropDown>
                    {filter_keys.map((item, index) => (
                      <Fil
                        key={index}
                        onClick={() => {
                          handleFiter(item);
                          setFilterValues(item.value);
                        }}
                      >
                        {item.title}
                      </Fil>
                    ))}
                  </DropDown>
                )}
              </Drop>
            </TitleCover>

            <LineChartGraph graphData={graphData} />
          </Cover>
          <Cover>
            <TitleCover>
              <TitleGraph>Quantity Analysis</TitleGraph>
              <Drop onClick={() => handleDropDown(quality)}>
                {qualityFilter}
                <Icon>
                  <img src={dropdown} alt="icon" />
                </Icon>
                {isDropDown == "quality" && (
                  <DropDown>
                    {filter_keys.map((item, index) => (
                      <Fil
                        key={index}
                        onClick={() => {
                          handleQualityFiter(item);
                          setQualityFilterValues(item.value);
                        }}
                      >
                        {item.title}
                      </Fil>
                    ))}
                  </DropDown>
                )}
              </Drop>
            </TitleCover>
            <BarChartGraph qualityGraphData={qualityGraphData} />
          </Cover>
        </GraphContainer>
      </Container>
    </div>
  );
}

export default MilkView;

const CoverLink = styled(Link)`
  width: 100%;
  text-decoration: none;
  display: block;
`;
const Container = styled.div`
  padding: 20px;
  /* height: calc(100vh - 50px); */
  /* overflow: scroll; */
`;
const TopBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 14px;
  flex-wrap: wrap;
`;
const ReportButton = styled(Link)`
  background: #2382d4;
  color: #fff;
  height: 40px;
  width: 120px;
  display: flex;
  text-decoration: none;
  justify-content: center;
  font-size: 14px;
  border-radius: 8px;
  cursor: pointer;
  align-items: center;
  justify-content: center;
`;
const Div = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;
const Title = styled.h2`
  color: #0a0a0a;
  /* margin-bottom: 20px; */
  font-size: 26px;
`;
const FilterSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const Date = styled.div`
  color: #747474;
  font-family: "inter_regular";
  margin-right: 20px;
`;
const Time = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const TimeTab = styled.div`
  color: #747474;
  cursor: pointer;
  border: 1px solid #7fadd4;
  padding: 6px 16px;
  margin-right: 3px;
  &.active {
    background: #2382d4;
    color: #fff;
  }
  &:first-child {
    border-radius: 8px 0 0 8px;
  }
  &:last-child {
    border-radius: 0 8px 8px 0;
    margin-right: 20px;
  }
`;
const Switch = styled.div`
  display: flex;
  border-radius: 8px;
  justify-content: space-between;
  align-items: center;
  padding: 6px 16px;
  border: 1px solid #7fadd4;
  margin-right: 10px;
  cursor: pointer;
  &:last-child {
    margin-right: 0;
  }
`;
const SwitchTitle = styled.div`
  color: #2382d4;
`;
const BottomBar = styled.div`
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  margin-top: 20px;
  @media (max-width:1080px) {
    display:grid;
    grid-template-columns: repeat(1, 1fr);
    gap:20px;
  }
`;
const CoverCard = styled.div`
  display: flex;
  gap: 20px;
  width: 70%;
  @media (max-width:1080px) {
    display:grid;
    grid-template-columns: repeat(2, 1fr);
    gap:20px;
    width: 100%;
  }
  @media (max-width:680px) {
    display:grid;
    grid-template-columns: repeat(1, 1fr);
    gap:20px;
    width: 100%;
  }
`;
const TotalValue = styled.div`
  width: 30%;
  @media (max-width:1080px) {
    width: 100%;
  }
`;
const TotalCard = styled.div`
  display: flex;
  border: 1px solid #dfe8ed;
  z-index: 1;
  border-radius: 8px;
  padding: 10px 20px;
  background-color: #fff;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin-bottom: 10px;
  margin-left: 20px;
  &:last-child {
    margin-bottom: 0;
  }
  @media (max-width:1080px) {
    margin-left: 0px;
  }
`;
const Icon = styled.div`
  img {
    display: block;
    width: 100%;
  }
`;
const Span = styled.div`
  color: #0a0a0a;
`;
const Curve = styled.div`
  position: absolute;
  width: 20%;
  right: 0;
  z-index: -1;
  bottom: 0;
  img {
    display: block;
    width: 100%;
  }
`;
const Count = styled.h1`
  color: #2382d4;
`;
const GraphContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 30px 0 0;
  @media (max-width:980px) {
    flex-direction:column;
    gap: 40px;
    height:100%;
  }
`;
const Cover = styled.div`
  background-color: #fff;
  margin-right: 20px;
  padding: 20px;
  width: 49%;
  &:last-child {
    margin-right: 0;
  }
  @media (max-width:980px) {
    width: 99%;
    height:100%;
    margin-right: 8px;
  }
`;
const TitleCover = styled.h3`
  color: #0a0a0a;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  box-sizing: border-box;
`;
const TitleGraph = styled.div``;
const Drop = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #0a0a0a;
  font-family: "inter_regular";
  font-size: 14px;
`;
const DropDown = styled.div`
  background-color: #e0f3ff;
  border-radius: 5px;
  position: absolute;
  width: 120px;
  top: 30px;
`;
const Fil = styled.div`
  padding: 10px 20px;
  border-bottom: 1px solid #c4c4c4;
  cursor: pointer;
  font-size: 13px;
  &:last-child {
    border-bottom: 1px solid transparent;
  }
  transition: 0.4s ease;
  &:hover {
    background-color: #2382d4;
    color: #fff;
    transition: 0.4s ease;
  }
`;
