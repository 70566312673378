import { useContext } from "react";
import api from "..";
import { getYYMMDD } from "../../utils/functions/function";

//dashboard count --------------------GET---------------------
export const DashboardCount = (config, Id) => {
  const apiUrl = `activities/society-dashboard-count/`;

  return api.get(apiUrl, config).then((res) => {
    return res;
  });
};

//Create Local Sale-----------------POST-----------------------
export const CreateLocalSale = (config, formData) => {
  return api
    .post("activities/create-local-sale/", formData, config)
    .then((res) => {
      return res;
    });
};

//get local sales --------------------GET---------------------
export const GetLocalSales = (config, currentPage) => {
  const apiUrl = `/activities/view-society-local-sales/`;
  return api
    .get(
      apiUrl,
      {
        params: {
          page: currentPage,
        },
      },
      config
    )
    .then((res) => {});
};

// Can Detail Entries-----------------POST-----------------------
export const CanDetailEntry = (config, formData) => {
  return api.post("activities/create-can/", formData, config).then((res) => {
    return res;
  });
};

// milk recieved-----------------POST-----------------------
export const ReceivedMilk = (config, formData) => {
  return api
    .post("activities/create-farmer-milk/", formData, config)
    .then((res) => {
      return res;
    });
};

//farmer listing-------------------GET----------------------
export const GetAllFarmers = (config, currentPage, searched) => {
  const apiUrl = `accounts/farmers-list/`;

  return api
    .get(apiUrl, { params: { page: currentPage, q: searched } }, config)
    .then((res) => {
      return res;
    });
};
//farmer listing-------------------GET----------------------
export const GetAllFarmersforsearch = (config, param) => {
  const apiUrl = `accounts/farmers-list/?q=${param}`;

  return api.get(apiUrl, config).then((res) => {
    return res;
  });
};
//farmer single page-------------------GET----------------------
export const FarmerSinglePage = (config, id) => {
  const apiUrl = `activities/farmer-milk-status/${id}/`;

  return api.get(apiUrl, config).then((res) => {
    return res;
  });
};

export const SocietyMilkStatusView = (config, param) => {
  const apiUrl = `activities/society-milk-status/?time=${param}`;

  return api.get(apiUrl, config).then((res) => {
    return res;
  });
};

//graph datas----------------------GET--------------------

export const SocietyQuantityGraphData = (config, filterValues) => {
  const apiUrl = `activities/milk-quantity-status-graph/?days=${filterValues}`;
  return api.get(apiUrl, config).then((res) => {
    return res;
  });
};

export const SocietyQualityGraphData = (config, QualityfilterValues) => {
  const apiUrl = `activities/milk-quality-status-graph/?days=${QualityfilterValues}`;
  return api.get(apiUrl, config).then((res) => {
    return res;
  });
};

// bonus ----------------------- GET-------------------

export const getSocietyBonus = (config) => {
  const apiUrl = `activities/view-farmer-bonus/`;
  return api.get(apiUrl, config).then((res) => {
    return res;
  });
};
// CREATE BONUS-----------------POST-----------------------

export const createBonus = (config, formData) => {
  return api
    .post("activities/create-farmer-bonus/", formData, config)
    .then((res) => {
      return res;
    });
};

// Incentives ----------------------- GET-------------------
export const getIncentivesData = (config, startDate, endDate) => {
  const apiUrl = `activities/get-farmer-incentive/`;
  return api.get(apiUrl, config).then((res) => {
    return res;
  });
};

// CREATE INCENTIVES-----------------POST-----------------------

export const createIncentives = (config, formData) => {
  return api
    .post("activities/create-farmer-incentive/", formData, config)
    .then((res) => {
      return res;
    });
};

// Loan ----------------------- GET-------------------
export const getFarmerLoan = (config) => {
  const apiUrl = `activities/get-farmer-loans/`;
  return api.get(apiUrl, config).then((res) => {
    return res;
  });
};
//single Loan ----------------------- GET-------------------
export const getSingleLoanData = (config, id) => {
  const apiUrl = `activities/get-farmer-loan/${id}`;
  return api.get(apiUrl, config).then((res) => {
    return res;
  });
};
// CREATE LOAN-----------------POST-----------------------

export const creatLoan = (config, formData) => {
  return api
    .post("activities/create-farmer-loan/", formData, config)
    .then((res) => {
      return res;
    });
};

// Create Price per litre in local sale-----------------POST-----------------------

export const PricePerLitreLocalSale = (config, pricePerMilkFormData) => {
  return api
    .post(
      "activities/create-price-per-liter-local-sale/",
      pricePerMilkFormData,
      config
    )
    .then((res) => {
      return res;
    });
};

//single bonus ----------------------- GET-------------------
export const getSingleFarmerBonus = (config, id) => {
  const apiUrl = `activities/single-farmer-bonus/${id}`;
  return api.get(apiUrl, config).then((res) => {
    return res;
  });
};
//edit bonus ----------------------- POST-------------------

export const editFarmerBonus = (id, formData, config) => {
  return api
    .post(`activities/edit-farmer-bonus/${id}/`, formData, config)
    .then((res) => {
      return res;
    });
};

//single incentive ----------------------- GET-------------------
export const getSingleFarmerIncentive = (config, id) => {
  const apiUrl = `activities/single-farmer-incentive/${id}`;
  return api.get(apiUrl, config).then((res) => {
    return res;
  });
};
//edit incentive ----------------------- POST-------------------

export const editFarmerIncentive = (id, formData, config) => {
  return api
    .post(`activities/edit-farmer-incentive/${id}/`, formData, config)
    .then((res) => {
      return res;
    });
};

//single incentive ----------------------- GET-------------------
export const getTotalMilkStatus = (config, role, id, startDate, endDate) => {
  const apiUrl = `activities/total-milk-status-to-plant/`;
  return api
    .get(
      apiUrl,
      {
        params: {
          society_id: role == "Society" ? "" : id,
          from_date: getYYMMDD(startDate),
          to_date: getYYMMDD(endDate),
        },
      },
      config
    )
    .then((res) => {
      return res;
    });
};

//Delete Society ----------------------- POST-------------------

export const deleteSociety = (id, config) => {
  return api.post(`accounts/delete-society/${id}/`, {}, config).then((res) => {
    return res;
  });
};

//single incentive ----------------------- GET-------------------
export const getCanDetails = (config, id, startDate, endDate) => {
  const apiUrl = `activities/list-can-details/`;
  return api
    .get(
      apiUrl,
      {
        params: {
          society_id: id,
          from_date: getYYMMDD(startDate),
          to_date: getYYMMDD(endDate),
        },
      },
      config
    )
    .then((res) => {
      return res;
    });
};
