import React from "react";
import { Line } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";
import styled from "styled-components";

function LineChartGraph({ graphData }) {
  Chart.register(...registerables);
  const data = {
    labels: graphData?.label_data,
    datasets: [
      {
        label: "Litres",
        data: graphData?.data,
        borderColor: [
          "rgb(5, 220, 190,1)",
          "rgb(30, 220, 190,0.8)",
          "rgb(5, 220, 190,0.5)",
          "rgb(75, 220, 190,0.4)",
          "rgb(5, 220, 190,0.2)",
          "rgb(5, 220, 190,0.2)",
        ],
        backgroundColor: ["rgba(32, 162, 219, 0.3)"],
        fill: "origin",
        fillOpacity: 0.4,
      },
      //   {
      //     label: "Enquiries",
      //     data: [100, 50, 60, 15, 40, 30, 25, 80, 70, 20, 55, 70],
      //     borderColor: [
      //       "rgba(255,99,132,1)",
      //       "rgba(54, 162, 235, 1)",
      //       "rgba(255, 206, 86, 1)",
      //       "rgba(75, 192, 192, 1)",
      //       "rgba(153, 102, 255, 1)",
      //       "rgba(255, 159, 64, 1)",
      //     ],
      //     backgroundColor: [
      //       "rgba(255, 99, 132, 0.2)",
      //       "rgba(54, 162, 235, 0.2)",
      //       "rgba(255, 206, 86, 0.2)",
      //       "rgba(75, 192, 192, 0.2)",
      //       "rgba(153, 102, 255, 0.2)",
      //       "rgba(255, 159, 64, 0.2)",
      //     ],
      //     fillOpacity: 0.4,
      //     fill: "origin",
      //   },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: false,
      },
      drawBorder: true,
      title: {
        display: true,
        text: "Litres",
      },
    },
    layout: {
      padding: 10,
    },
    responsive: true,
    scales: {
      x: {
        stacked: true,
        grid: {
          display: false,
        },
      },
      y: {
        stacked: true,
        grid: {
          display: false,
        },
      },
    },
  };

  return (
    <Container>
      <Line data={data} options={options} width={"100%"} height={"50px"} />
    </Container>
  );
}

export default LineChartGraph;

const Container = styled.div`
  /* width: 100%;
  height: 200px; */
`;
