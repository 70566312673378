import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Context } from "../../../context/Store";
import DatePicker from "react-datepicker";
import ReactPaginate from "react-paginate";

import NodataFound from "../../includes/nodata/NodataFound";
import { arrivalsSociety, milkStatusSociety } from "../../../api/auth";
import { getYYMMDD } from "../../../utils/functions/function";

const MilkStatusSociety = () => {
    const [getData, setData] = useState([]);

    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [isLastMonth, setIsLastMonth] = useState("False");
    const [isLastTenDays, setIsLastTenDays] = useState("False");
    const [time, setTime] = useState("all");

    const [milkQuantity, setMilkQuantity] = useState("Litre");
    const [phaseOfDay, setPhaseOfDay] = useState("all");
    const [isLoading, setIsLoading] = useState(true);
    const [pagination, setPagination] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const [searchedItem, setSearchedItem] = useState("");

    const startIndex = (currentPage - 1) * 8;

    const {
        dispatch,
        state: { user_data },
    } = useContext(Context);
    const token = user_data.access_token;
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };

    const customStyles = {
        width: "100px",
        height: "30px",
        borderRadis: "8px",
        // Add any other custom styles here if needed
    };

    const CustomInput = ({ value, onClick }) => (
        <input
            type="text"
            value={value}
            onClick={onClick}
            style={{ width: "100px", height: "30px" }}
        />
    );

    useEffect(() => {
        dispatch({
            type: "UPDATE_USER_DATA",
            user_data: {
                active_menu: "milk-status",
            },
        });
    }, []);

    useEffect(() => {
        milkStatusSociety(
            config,
            currentPage,
            getYYMMDD(startDate),
            getYYMMDD(endDate),
            isLastTenDays,
            isLastMonth,
            phaseOfDay
        )
            .then((res) => {
                if (res.app_data.StatusCode === 6000) {
                    setData(res.app_data.data);
                    setIsLoading(false);
                    setPagination(res.app_data.pagination_data);
                } else {
                    console.log(res.app_data.data);
                    setIsLoading(false);
                }
            })
            .catch((err) => {
                console.error("Error fetching data:", err);
                setIsLoading(false);
            });
    }, [endDate, isLastMonth, isLastTenDays, phaseOfDay, currentPage]);

    function handlePageClick({ selected: selectedPage }) {
        setCurrentPage(selectedPage + 1);
    }

    return (
        <>
            <TotalContainer>
                <CoverTop>
                    <Top>
                        <h3>Milk Status</h3>
                        <TopRight>
                            <ButContainer>From</ButContainer>
                            <DatePicker
                                selected={startDate}
                                onChange={(date) => setStartDate(date)}
                                required
                                dateFormat="dd-MM-yyyy"
                                customInput={<CustomInput />}
                                style={customStyles}
                            />
                            <ButContainer>To</ButContainer>
                            <DatePicker
                                selected={endDate}
                                onChange={(date) => setEndDate(date)}
                                required
                                minDate={startDate}
                                dateFormat="dd-MM-yyyy"
                                customInput={<CustomInput />}
                                style={customStyles}
                            />
                            <TimeSect>
                                <SelectButton
                                    className={`left ${
                                        time === "tenDays" && "active"
                                    }`}
                                    onClick={() => {
                                        setIsLastMonth("False");
                                        if (time === "tenDays") {
                                            setTime("all");
                                            setIsLastTenDays("False");
                                        } else {
                                            setTime("tenDays");
                                            setIsLastTenDays("True");
                                        }
                                    }}
                                >
                                    Last 10 days
                                </SelectButton>
                                <SelectButton
                                    className={`right ${
                                        time === "month" && "active"
                                    }`}
                                    onClick={() => {
                                        setIsLastTenDays("False");
                                        if (time === "month") {
                                            setTime("all");
                                            setIsLastMonth("False");
                                        } else {
                                            setTime("month");
                                            setIsLastMonth("True");
                                        }
                                    }}
                                >
                                    Last Month
                                </SelectButton>
                            </TimeSect>
                        </TopRight>
                    </Top>
                    <div className="parent-scroll">
                        <Bottom className="scroll">
                            <TableHead>
                                <TableItem className="milk-index">
                                    Sl No.
                                </TableItem>
                                <TableItem>Date</TableItem>
                                <TableItem className="milk-value">
                                    Time Period
                                    <div className="toggle-button">
                                        <span
                                            className={`left ${
                                                phaseOfDay === "am" && "active"
                                            }`}
                                            onClick={() => {
                                                if (phaseOfDay === "am") {
                                                    setPhaseOfDay("all");
                                                } else {
                                                    setPhaseOfDay("am");
                                                }
                                            }}
                                        >
                                            AM
                                        </span>
                                        <span
                                            className={`right ${
                                                phaseOfDay === "pm" && "active"
                                            }`}
                                            onClick={() => {
                                                if (phaseOfDay === "pm") {
                                                    setPhaseOfDay("all");
                                                } else {
                                                    setPhaseOfDay("pm");
                                                }
                                            }}
                                        >
                                            PM
                                        </span>
                                    </div>
                                </TableItem>
                                <TableItem>
                                    Milk Quantity
                                    <div className="toggle-button">
                                        <span
                                            className={`L ${
                                                milkQuantity === "Litre" &&
                                                "active"
                                            }`}
                                            onClick={() =>
                                                setMilkQuantity("Litre")
                                            }
                                        >
                                            Litre
                                        </span>
                                        <span
                                            className={`Kg ${
                                                milkQuantity === "Kilogram" &&
                                                "active"
                                            }`}
                                            onClick={() =>
                                                setMilkQuantity("Kilogram")
                                            }
                                        >
                                            Kg
                                        </span>
                                    </div>
                                </TableItem>
                                <TableItem className="milk-value">
                                    CLR
                                </TableItem>
                                <TableItem className="milk-value">
                                    FAT
                                </TableItem>
                                <TableItem className="milk-value">
                                    SNF
                                </TableItem>
                                <TableItem>Damaged Milk</TableItem>
                                <TableItem className="amount">Amount</TableItem>
                                {/* <TableItem className="action">Action</TableItem> */}
                            </TableHead>
                            <TableBody>
                                {getData?.length == 0 ? (
                                    <NodataFound />
                                ) : (
                                    getData.map((item, index) => (
                                        <TableContainer key={index}>
                                            <TableItems className="milk-index">
                                                {startIndex + index + 1}{" "}
                                            </TableItems>
                                            <TableItems
                                                className="name"
                                                title={item.created_at}
                                            >
                                                {item.created_at}
                                            </TableItems>
                                            <TableItems className="milk-value">
                                                {item.time_status}
                                            </TableItems>
                                            <TableItems>
                                                {milkQuantity === "Litre"
                                                    ? item.quantity
                                                    : item.quantity_in_kg}
                                            </TableItems>
                                            <TableItems className="milk-value">
                                                {item.clr_value}
                                            </TableItems>
                                            <TableItems className="milk-value">
                                                {item.fat_value}
                                            </TableItems>
                                            <TableItems className="milk-value">
                                                {item.snf_value}
                                            </TableItems>
                                            <TableItems>
                                                {item.damaged_milk_quantity}
                                            </TableItems>
                                            <TableItems className="total">
                                                {item.total_price}
                                                {/* <TableItems className="total action">
                                                <i
                                                    class="ri-file-edit-fill"
                                                    onClick={() =>
                                                        navigate(
                                                            `/edit-arrivals/${item.id}`
                                                        )
                                                    }
                                                ></i>
                                                <i
                                                    class="ri-delete-bin-6-line"
                                                    style={{
                                                        color: "red",
                                                        marginLeft: "10px",
                                                    }}
                                                    onClick={() => {
                                                        setSelectedArrival(
                                                            item.id
                                                        );
                                                        setDeleteModal(true);
                                                    }}
                                                ></i>
                                            </TableItems> */}
                                            </TableItems>
                                        </TableContainer>
                                    ))
                                )}
                            </TableBody>
                        </Bottom>
                    </div>
                </CoverTop>
            </TotalContainer>
            {/* {deleteModal && (
                <Modal>
                    <ModalContainer>
                        <p>Are you sure you want to delete this entry?</p>
                        <ButtonContainer>
                            <ButtonNew onClick={() => cancelHandler()}>
                                Cancel
                            </ButtonNew>
                            <ButtonNew
                                onClick={() => confirmSubmit(selectedArrival)}
                                className="confirm"
                            >
                                Confirm
                            </ButtonNew>
                        </ButtonContainer>
                    </ModalContainer>
                </Modal>
            )} */}
            {pagination && pagination.total_pages > 1 && getData.length > 0 && (
                <PaginationContainer>
                    <PaginationText>
                        Showing Milk Status : {pagination.first_item} -{" "}
                        {pagination.last_item} of {pagination.total_items}{" "}
                        Reports
                    </PaginationText>
                    <ReactPaginate
                        previousLabel={"<"}
                        nextLabel={">"}
                        pageCount={pagination.total_pages}
                        onPageChange={handlePageClick}
                        containerClassName={"pagination"}
                        previousLinkClassName={"pagination__link"}
                        nextLinkClassName={"pagination__link"}
                        disabledClassName={"pagination__link--disabled"}
                        activeClassName={"pagination__link--active"}
                    />
                </PaginationContainer>
            )}
        </>
    );
};

export default MilkStatusSociety;

const CoverTop = styled.div``;
const TotalContainer = styled.div`
    margin-top: 30px;
    padding: 0px 15px;
    height: calc(100vh - 180px);
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    overflow-y: scroll;
`;
const Top = styled.div`
    display: flex;
    flex-wrap:wrap;
    justify-content: space-between;
    align-items: center;
    h3 {
        font-size: 28px;
    }
`;
const TopRight = styled.div`
    display: flex;
    gap: 5px;
`;
const ButContainer = styled.div`
    padding: 5px 8px;
    background: #2382d4;
    border-radius: 8px;
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
`;
const TimeSect = styled.div`
    border-radius: 8px;
    /* overflow:hidden; */
    /* height:25px; */
    display: flex;
    gap: 3px;
`;
const SelectButton = styled.div`
    padding: 5px 8px;
    width: 105px;
    border: 0.5px solid #7fadd4;
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
    color: #000;
    &.left {
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
    }
    &.active {
        background: #2382d4;
        color: #fff;
    }
    &.right {
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
    }
`;
const Bottom = styled.div`
    padding-top: 15px;
`;
const TableHead = styled.div`
    height: 70px;
    background: #fff;
    padding: 0px 15px;
    display: flex;
    align-items: center;
`;
const TableBody = styled.div`
    height: calc(100vh - 300px);
    overflow: scroll;
`;
const TableItem = styled.div`
    width: 15%;
    color: #747474;
    font-size: 18px;
    &.milk-index {
        width: 10%;
    }
    /* &.milk-value {
        width: 10%;
    } */
    &.amount {
        /* width: 10%; */
        text-align: right;
    }

    .toggle-button {
        display: flex;
        gap: 7px;
        margin-top: 5px;
    }

    span {
        font-size: 14px;
        font-weight: normal;
        padding: 0 5px;
        border: 0.4px solid #747474;
        border-radius: 3px;
        cursor: pointer;
        &.active {
            background: #2382d4;
            color: #fff;
        }
    }
`;
const TableItems = styled.div`
    width: 15%;
    color: #000000;
    font-size: 18px;
    &.total {
        /* color: #2382d4; */
        /* cursor: pointer; */
        /* width: 10%; */
        text-align: right;
    }
    &.name {
        text-overflow: ellipsis;
        overflow: hidden;
    }
    &.farmer-id {
        text-overflow: ellipsis;
        overflow: hidden;
        margin-right: 5px;
        /* width: 10%; */
    }
    &.milk-index {
        width: 10%;
    }
    /* &.milk-value {
        width: 10%;
    } */
    /* &.action {
        width: 5%;
    } */
`;
const TableContainer = styled.div`
    height: 70px;
    background: #fff;
    padding: 0px 15px;
    display: flex;
    align-items: center;
    margin-top: 15px;
`;
const PaginationContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0 15px;
  margin: 0 20px;
  @media all and (max-width: 1380px) {
    padding: 15px 0px;
    margin-right: 40px;
  }
  @media all and (max-width: 1280px) {
    margin-right: 20px;
    padding: 20px 0px;
  }
  @media all and (max-width: 980px) {
    margin-right: 10px;
  }
  @media all and (max-width: 890px) {
    flex-direction: column;
  }
`;
const PaginationText = styled.p`
  color: #898989;
  font-size: 14px;
  @media all and (max-width: 890px) {
    margin-bottom: 10px;
  }
`;
