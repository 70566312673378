import React, { useContext, useEffect, useState } from "react";
import { styled } from "styled-components";
import List from "../../../../components/List";
import {
  add,
  curve,
  filterIcon,
  left_arrow,
  printerColor,
} from "../../../../components/assets";
import { Link, useNavigate } from "react-router-dom";
import { GetBonusDatas } from "../../../../api/auth";
import { Context } from "../../../../context/Store";
import Nodata from "../../../includes/nodata/Nodata";
import Loader from "../../../../components/loader/Loader";
import api from "../../../../api";
import ButtonLoader from "../../../../components/button-loader/ButtonLoader";
import { getYYMMDD } from "../../../../utils/functions/function";

function BankDetails() {
  const [bankDetails, setBankDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isButtonLoader, setButtonLoader] = useState(false);
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };
  // get accesstoken from store
  const {
    dispatch,
    state: { user_data },
  } = useContext(Context);
  const token = user_data.access_token;
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const startDate = user_data.start_date;
  const endDate = user_data.end_date;
  useEffect(() => {
    dispatch({
      type: "UPDATE_USER_DATA",
      user_data: {
        active_menu: "bonus",
      },
    });
  }, []);
  const saveBonusId = (item) => {
    dispatch({
      type: "UPDATE_USER_DATA",
      user_data: {
        bonus_id: item.id,
      },
    });
  };
  useEffect(() => {
    setButtonLoader(true);
    api
      .get("payments/farmer-bank-details", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          from_date: startDate,
          to_date: endDate,
        },
      })
      .then((res) => {
        if (res.app_data.StatusCode == 6000) {
          setBankDetails(res.app_data.data);
          setButtonLoader(false);
          setIsLoading(false);
        } else {
          setBankDetails([]);
          setButtonLoader(false);
          setIsLoading(false);
        }
      });
  }, []);

  const formData = new FormData();
  formData.append("from_date", startDate);
  formData.append("to_date", endDate);
  const completePayments = () => {
    api
      .post("/payments/society-complete-payments/", formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        if (res.app_data.StatusCode == 6000) {
          navigate("/completed-payment-report");
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      });
  };
   const handleDownload = async () => {
     try {
       let apiUrl =
         "https://api.jeevamilk.com/api/v1/activities/print-bank-details/";
        
        if(startDate && endDate) {
          apiUrl = `https://api.jeevamilk.com/api/v1/activities/print-bank-details/?from_date=${startDate}&to_date=${endDate}`
        }

       const response = await fetch(apiUrl, {
         headers: {
           Authorization: `Bearer ${token}`,
           "Content-Type": "application/json",
         },
       });
       if (!response.ok) {
         throw new Error(`HTTP error! Status: ${response.status}`);
       }

       // Extract the blob from the response
       const blob = await response.blob();

       // Create a temporary URL for the Blob
       const url = URL.createObjectURL(blob);

       // Create a link element
       const link = document.createElement("a");

       // Set the href attribute of the link to the URL of the Blob
       link.href = url;

       // Set the download attribute with the desired file name
       link.download = "milk-report";

       // Append the link to the document
       document.body.appendChild(link);

       // Trigger a click on the link to start the download
       link.click();

       // Remove the link from the document
       document.body.removeChild(link);

       // Revoke the URL to release the resources
       URL.revokeObjectURL(url);
     } catch (error) {
       console.error("Error downloading file:", error);
     }
   };
   return (
     <Container>
       <TopBar>
         <TopCover>
           <Arrow onClick={() => goBack()}>
             <img src={left_arrow} alt="image" />
           </Arrow>
           <Text>
             <TopTitle>Farmer payments Report </TopTitle>
             <RoutePath>
               Step 1 / Step 2 / <span>Step 3 </span>
             </RoutePath>
           </Text>
         </TopCover>
         <Curve>
           <img src={curve} alt="" />
         </Curve>
       </TopBar>
       <div className="parent-scroll">
         <ListContainer className="scroll">
           <HeadRow>
             <HeadColumnData>Sl. no</HeadColumnData>
             <HeadColumnData>Branch</HeadColumnData>
             <HeadColumnData>Farmer</HeadColumnData>
             <HeadColumnData>Bank</HeadColumnData>
             <HeadColumnData>IFSC</HeadColumnData>
             <HeadColumnData>Account No</HeadColumnData>
             <HeadColumnData>Amount</HeadColumnData>
             {/* <HeadColumnData>A/C Type</HeadColumnData> */}
           </HeadRow>
           <BottomContainer>
             {isLoading ? (
               <Loader />
             ) : bankDetails.length == 0 ? (
               <Nodata />
             ) : (
               bankDetails?.map((row, index) => (
                 <Row key={index}>
                   <ColumnData className="bold">{index + 1}</ColumnData>
                   <ColumnData className="bold">{row.branch_name}</ColumnData>
                   <ColumnData>{row.farmer}</ColumnData>
                   <ColumnData>{row.bank_name}</ColumnData>
                   <ColumnData>{row.ifsc_number}</ColumnData>
                   <ColumnData>{row.account_number}</ColumnData>
                   <ColumnData>₹{row.amount}</ColumnData>
                   {/* <ColumnData className="bold">₹{row.total_expense}</ColumnData> */}
                 </Row>
               ))
             )}
           </BottomContainer>
         </ListContainer>
       </div>

       <NavButtons>
         <Button onClick={() => handleDownload()}>
           <img src={printerColor} />
           Export excel
         </Button>
         {isButtonLoader ? (
           <Button className="background">
             <ButtonLoader />
           </Button>
         ) : (
           <Button className="background" onClick={() => completePayments()}>
             Completed
           </Button>
         )}
       </NavButtons>
     </Container>
   );
}

export default BankDetails;

const Container = styled.div`
  padding: 20px;
`;
const NavButtons = styled.div`
  display: flex;
  justify-content: flex-end;
`;
const Button = styled.div`
  width: 260px;
  height: 40px;
  background: #fff;
  color: #2382d4;
  align-items: center;
  display: flex;
  justify-content: center;
  border: 1px solid #2382d4;
  border-radius: 4px;
  font-size: 14px;
  padding: 6px 16px;
  cursor: pointer;

  &.background {
    background-color: #2382d4;
    color: #fff;
    margin-left: 15px;
  }

  img {
    margin-right: 10px;
  }
`;

const BottomContainer = styled.div`
  max-height: calc(100vh - 415px);
  overflow-y: scroll;
`;

const ListContainer = styled.div`
  background-color: #fff;
  /* padding: 0 24px; */
  border: 1px solid #dfe8ed;
  border-radius: 8px;
  height: calc(100vh - 300px);
  margin-bottom: 20px;

  h3 {
    color: #747474;
    font-family: "inter_regular";
    font-size: 22px;
  }
`;

const HeadRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px 20px;
  border-bottom: 1px solid #ccc;
`;
const Row = styled.div`
  display: flex;
  align-items: center;
  text-decoration: none;
  justify-content: space-between;
  padding: 30px 20px;
  border-bottom: 1px solid #ccc;
`;
const RoutePath = styled.h4`
  color: #747474;
  span {
    color: #2382d4;
  }
`;
const Curve = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  img {
    width: 100%;
    display: block;
  }
`;
const HeadColumnData = styled.div`
  color: #747474;
  font-size: 18px;
  font-family: "inter_light";
  width: 15%;
  &:first-child {
    width: 7%;
  }
  &:nth-last-child(3) {
    width: 25%;
  }
  &:nth-last-child(2) {
    width: 10%;
  }
  &:last-child {
    text-align: right;
  }
`;
const ColumnData = styled.div`
  color: #0a0a0a;
  font-size: 18px;
  font-family: "inter_medium";
  width: 15%;
  &:first-child {
    width: 7%;
  }
  &:nth-last-child(3) {
    width: 25%;
  }
  &:nth-last-child(2) {
    width: 10%;
  }
  &:last-child {
    text-align: right;
  }
  &.bold {
    font-family: "inter_regular";
  }
`;
const TopBar = styled.div`
  padding: 20px;
  background-color: #fff;
  position: relative;
  z-index: 1;
  border: 1px solid #dfe8ed;
  margin: 10px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #dfe8ed;
  border-radius: 5px;
`;
const TopCover = styled.div`
  display: flex;
  /* justify-content: space-between; */
  width: 100%;
`;
const Arrow = styled.div`
  border: 1px solid #2382d4;
  display: flex;
  cursor: pointer;
  border-radius: 5px;
  padding: 4px;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
`;
const Text = styled.div``;
const TopTitle = styled.h2`
  text-align: center;
  margin-bottom: 10px;
  &.create {
    width: 70%;
    margin: 0 auto;
    margin-bottom: 10px;
    color: #0a0a0a;
  }
`;
