import React, { useContext, useEffect, useState } from "react";
import { styled } from "styled-components";
import { blue_calendar } from "../../../components/assets";
import { Context } from "../../../context/Store";
import { CreatingSociety, RouteView } from "../../../api/auth";
import ButtonLoader from "../../../components/button-loader/ButtonLoader";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

function CreateSociety({ setAdd, id, type }) {
  //state
  const [societyName, setSocietyName] = useState("");
  const [societyType, setSocietyType] = useState("jeeva_society");
  const [route, setRoute] = useState("");
  const [location, setLocation] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [presidentName, setPresidentName] = useState("");
  const [secretaryName, setSecretaryName] = useState("");
  const [presidentPhone, setPresidentPhone] = useState("");
  const [secretaryPhone, setSecretaryPhone] = useState("");
  const [selectValue, setSelectValue] = useState();
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [accountType, setAccountType] = useState("");
  const [show, setShow] = useState(false);

  const phoneRegExp = /^\d{10}$/;

  const [bankDetails, setBankDetails] = useState({
    accountHolderName: "",
    accNumber: "",
    ifsc: "",
    bankName: "",
    branch: "",
    accountType: "11",
  });

  const [otherDetails, setOtherDetails] = useState({
    transportation: "",
    amAmount: "",
    pmAmount: "",
    bonus: "",
  });

  // get accesstoken from store
  const {
    state: { user_data, route_data },
  } = useContext(Context);
  const token = user_data.access_token;
  const path = window.location.pathname;
  const navigate = useNavigate();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const showSuccessModal = () => {
    Swal.fire({
      position: "center",
      icon: "success",
      title: "Society Created Successfully",
      showConfirmButton: false,
      timer: 1500,
    });
  };

  const route_name = route_data.route_name;
  const route_code = route_data.route_code;
  //payload
  const formData = new FormData();
  formData.append("society_name", societyName);
  formData.append("society_type", societyType);
  if (type == "route") {
    formData.append("rout", route_code);
  } else {
    formData.append("rout", route);
  }
  formData.append("location", location);
  formData.append("phone", phone);
  formData.append("password", password);
  formData.append("president_name", presidentName);
  formData.append("president_phone", presidentPhone);
  formData.append("secretary_name", secretaryName);
  formData.append("secretary_phone", secretaryPhone);
  formData.append("account_holder_name", bankDetails.accountHolderName);
  formData.append("account_number", bankDetails.accNumber);
  formData.append("account_type", bankDetails.accountType);
  formData.append("ifsc_code", bankDetails.ifsc);
  formData.append("bank_name", bankDetails.bankName);
  formData.append("am_amount", otherDetails.amAmount);
  formData.append("pm_amount", otherDetails.pmAmount);
  formData.append("bonus", otherDetails.bonus);
  formData.append("branch", bankDetails.branch);
  formData.append("society_travel_expense_status", otherDetails.transportation);
  //creating route
  const handleSubmit = (e) => {
    e.preventDefault();
    setIsButtonLoading(true);
    if (!societyName) {
      setErrorMessage("Please enter society name");
      setIsButtonLoading(false);
      // } else if (!location) {
      //     setErrorMessage("Please enter location");
      //     setIsButtonLoading(false);
      // } else if (!phone) {
      //     setErrorMessage("Please enter phone number");
      //     setIsButtonLoading(false);
    } else if (!password) {
      setErrorMessage("Please enter password");
      setIsButtonLoading(false);
      // } else if (!presidentName) {
      //     setErrorMessage("Please enter president name");
      //     setIsButtonLoading(false);
      // } else if (!presidentPhone) {
      //     setErrorMessage("Please enter president phone");
      //     setIsButtonLoading(false);
      // } else if (!secretaryName) {
      //     setErrorMessage("Please enter secretary name");
      //     setIsButtonLoading(false);
      // } else if (!secretaryPhone) {
      //     setErrorMessage("Please enter secretary phone");
      //     setIsButtonLoading(false);
    } else {
      CreatingSociety(config, formData).then((res) => {
        if (res.app_data.StatusCode == 6000) {
          showSuccessModal();
          setIsButtonLoading(false);
          if (path !== "/create-society") {
            setAdd(false);
          }
          navigate("/total-societies?society=all");
        } else {
          setIsButtonLoading(false);
        }
      });
    }
  };
  const handleSelectChange = (event) => {
    setSocietyType(event.target.value);
  };
  useEffect(() => {
    RouteView(config).then((res) => {
      if (res.app_data.StatusCode == 6000) {
        setSelectValue(res.app_data.data);
        setRoute(res?.app_data.data[0]?.code);
      }
    });
  }, []);
  return (
    <>
      <Title>Create Society</Title>
      <RouteInfo>
        <CoverInfo>
          {/* <RouteTop>
          <TitleRout>Create Rout</TitleRout>
        </RouteTop> */}
          <Form onSubmit={(e) => handleSubmit(e)}>
            <General>General</General>
            <CoverForm>
              <CoverIn>
                <Label>Society name*</Label>
                <Input
                  type="text"
                  value={societyName}
                  onChange={(e) => setSocietyName(e.target.value)}
                  required
                />
              </CoverIn>
              <CoverIn>
                <Label>Society category*</Label>
                <Select value={societyType} onChange={handleSelectChange}>
                  <option value="jeeva_society">Jeeva Society</option>
                  <option value="private_society">Private Society</option>
                  <option value="farm">Farm</option>
                </Select>
              </CoverIn>
            </CoverForm>
            <CoverForm>
              <CoverIn>
                <Label>Route*</Label>
                {type === "route" ? (
                  <Input type="text" value={route_name} disabled />
                ) : (
                  <Select
                    value={route}
                    onChange={(e) => setRoute(e.target.value)}
                  >
                    {selectValue?.map((item) => (
                      <option value={item.code} key={item.code}>
                        {item.name}
                      </option>
                    ))}
                  </Select>
                )}
              </CoverIn>
              <CoverIn>
                <Label>Location</Label>
                <Input
                  type="text"
                  value={location}
                  onChange={(e) => setLocation(e.target.value)}
                />
              </CoverIn>
            </CoverForm>
            <CoverForm>
              <CoverIn>
                <Label>Phone</Label>
                <Input
                  type="number"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value.slice(0, 10))}
                  // onChange={(e) => {
                  //   const newValue = e.target.value;
                  //   if (phoneRegExp.test(newValue)) {
                  //     setPhone(newValue);
                  //   }
                  // }}
                />
              </CoverIn>
              <CoverIn>
                <Label>Password*</Label>
                <Input
                  type={show ? "text" : "password"}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
                <Icon onClick={() => setShow(!show)}>
                  {show ? (
                    <i className="ri-eye-off-line"></i>
                  ) : (
                    <i className="ri-eye-line"></i>
                  )}
                </Icon>
              </CoverIn>
            </CoverForm>
            <General className="veh">Members details </General>
            <CoverForm>
              <CoverIn>
                <Label>President name</Label>
                <Input
                  type="text"
                  value={presidentName}
                  onChange={(e) => setPresidentName(e.target.value)}
                />
              </CoverIn>
              <CoverIn>
                <Label>Phone no</Label>
                <Input
                  type="number"
                  // className="auto"
                  value={presidentPhone}
                  onChange={(e) => setPresidentPhone(e.target.value.slice(0, 10))}
                  // onChange={(e) => {
                  //   const newValue = e.target.value;
                  //   if (phoneRegExp.test(newValue)) {
                  //     setPresidentPhone(newValue);
                  //   }
                  // }}
                />
              </CoverIn>
            </CoverForm>
            <CoverForm>
              <CoverIn>
                <Label>Secretary name</Label>
                <Input
                  type="text"
                  value={secretaryName}
                  onChange={(e) => setSecretaryName(e.target.value)}
                />
              </CoverIn>
              <CoverIn>
                <Label>Phone no</Label>
                <Input
                  type="number"
                  // className="auto"
                  value={secretaryPhone}
                  onChange={(e) => setSecretaryPhone(e.target.value.slice(0, 10))}
                  // onChange={(e) => {
                  //   const newValue = e.target.value;
                  //   if (phoneRegExp.test(newValue)) {
                  //     setSecretaryPhone(newValue);
                  //   }
                  // }}
                />
              </CoverIn>
            </CoverForm>{" "}
            <General className="bank">Bank details </General>
            <CoverForm>
              <CoverIn>
                <Label>Account Holder Name</Label>
                <Input
                  type="text"
                  value={bankDetails.accountHolderName}
                  onChange={(e) =>
                    setBankDetails({
                      ...bankDetails,
                      accountHolderName: e.target.value,
                    })
                  }
                />
              </CoverIn>
              <CoverIn>
                <Label>Account Number</Label>
                <Input
                  type="number"
                  value={bankDetails.accNumber}
                  onChange={(e) =>
                    setBankDetails({
                      ...bankDetails,
                      accNumber: e.target.value,
                    })
                  }
                />
              </CoverIn>
            </CoverForm>
            <CoverForm>
              <CoverIn>
                <Label>IFSC Code</Label>
                <Input
                  type="text"
                  value={bankDetails.ifsc}
                  onChange={(e) =>
                    setBankDetails({
                      ...bankDetails,
                      ifsc: e.target.value,
                    })
                  }
                />
              </CoverIn>

              <CoverIn>
                <Label>Bank Name</Label>
                <Input
                  type="text"
                  // className="auto"
                  value={bankDetails.bankName}
                  onChange={(e) =>
                    setBankDetails({
                      ...bankDetails,
                      bankName: e.target.value,
                    })
                  }
                />
              </CoverIn>
            </CoverForm>{" "}
            <CoverForm>
              <CoverIn>
                <Label>Account Type</Label>
                <Select
                  value={bankDetails.accountType}
                  onChange={(e) =>
                    setBankDetails({
                      ...bankDetails,
                      accountType: e.target.value,
                    })
                  }
                >
                  <option value="select">--SELECT---</option>
                  <option value="11">Current</option>
                  <option value="10">Savings</option>
                </Select>
              </CoverIn>
              <CoverIn className="add">
                <Label>Branch</Label>
                <Input
                  type="text"
                  value={bankDetails.branch}
                  onChange={(e) =>
                    setBankDetails({
                      ...bankDetails,
                      branch: e.target.value,
                    })
                  }
                />
              </CoverIn>
            </CoverForm>
            {/* <CoverForm>
              
            </CoverForm>{" "} */}
            <General className="other">Other Details </General>
            <CoverForm>
              <CoverIn>
                <Label>Society Transportation</Label>
                <Select
                  value={otherDetails.transportation}
                  onChange={(e) =>
                    setOtherDetails({
                      ...otherDetails,
                      transportation: e.target.value,
                    })
                  }
                >
                  <option value="select">--SELECT---</option>
                  <option value="tc_to_society">tc to society</option>
                  <option value="tc_from_society">tc from society</option>
                </Select>
              </CoverIn>
              <CoverIn>
                <Label>AM Amount</Label>
                <Input
                  type="text"
                  value={otherDetails.amAmount}
                  onChange={(e) =>
                    setOtherDetails({
                      ...otherDetails,
                      amAmount: e.target.value,
                    })
                  }
                />
              </CoverIn>
            </CoverForm>
            <CoverForm>
              <CoverIn>
                <Label>PM Amount</Label>
                <Input
                  type="number"
                  // className="auto"
                  value={otherDetails.pmAmount}
                  onChange={(e) =>
                    setOtherDetails({
                      ...otherDetails,
                      pmAmount: e.target.value,
                    })
                  }
                />
              </CoverIn>
              <CoverIn className="add">
                <Label>Bonus (% / Litre)</Label>
                <Input
                  type="text"
                  value={otherDetails.bonus}
                  onChange={(e) =>
                    setOtherDetails({
                      ...otherDetails,
                      bonus: e.target.value,
                    })
                  }
                />
              </CoverIn>
            </CoverForm>{" "}
            <CoverForm></CoverForm>{" "}
            {/* <ErrorMessage>{errorMessage}</ErrorMessage> */}
            <Buttons>
              <Button className="cancel" onClick={() => setAdd(false)}>
                Cancel
              </Button>
              {isButtonLoading ? (
                <Button>
                  <ButtonLoader />
                </Button>
              ) : (
                <SubmitButton type="submit" value="Create" />
              )}
            </Buttons>
          </Form>
        </CoverInfo>
      </RouteInfo>
    </>
  );
}

export default CreateSociety;

const RouteInfo = styled.div`
  background-color: #fff;
  margin-top: 20px;
  border: 1px solid #dfe8ed;
  border-radius: 8px;
  padding: 30px;
  width: 70%;
  margin: 0 auto;
  @media (max-width: 1080px) {
    width: 100%;
  }
`;
const Title = styled.h2`
  color: #0a0a0a;
  margin-bottom: 20px;
  font-size: 26px;
  width: 70%;
  margin: 0 auto;
  padding-bottom: 10px;
  @media (max-width: 1080px) {
    width: 100%;
  }
`;
const Button = styled.div`
  width: 170px;
  color: #fff;
  cursor: pointer;
  border-radius: 5px;
  background-color: #2382d4;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  &.cancel {
    background-color: #fff;
    border: 1px solid #2382d4;
    color: #2382d4;
    margin-right: 20px;
  }
`;
const SubmitButton = styled.input`
  width: 170px;
  color: #fff;
  cursor: pointer;
  border-radius: 5px;
  background-color: #2382d4;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  &.cancel {
    background-color: #fff;
    border: 1px solid #2382d4;
    color: #2382d4;
    margin-right: 20px;
  }
`;
const Form = styled.form``;
const ErrorMessage = styled.span`
  color: red;
  margin-bottom: 5px;
  text-align: right;
  width: 100%;
  display: inline-block;
  &::first-letter {
    text-transform: uppercase;
  }
`;
const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
`;
const CoverInfo = styled.div``;
const General = styled.h3`
  color: #0a0a0a;
  position: relative;
  overflow: hidden;
  font-size: 22px;
  margin-bottom: 30px;
  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    left: 100px;
    background-color: #ebebeb;
    bottom: 10px;
  }
  &.veh {
    margin-top: 30px;
    &::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 1px;
      left: 260px;
      bottom: 10px;
      background-color: #ebebeb;
    }
  }
  &.bank {
    margin-top: 30px;
    &::after {
      content: "";
      position: absolute;
      height: 1px;
      right: 0px;
      bottom: 10px;
      background-color: #ebebeb;
      left: unset;
      width: 80%;
    }
  }
  &.other {
    margin-top: 30px;
    &::after {
      content: "";
      position: absolute;
      height: 1px;
      right: 0px;
      bottom: 10px;
      background-color: #ebebeb;
      left: unset;
      width: 75%;
    }
  }
  
`;
const CoverForm = styled.div`
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
`;
const CoverIn = styled.div`
  width: 50%;
  margin-right: 20px;
  margin-bottom: 30px;
  &:nth-child(even) {
    margin-right: 0;
  }
  position: relative;
  &.add {
    width: 48%;
    margin-right: 0;
  }
`;
const Label = styled.h3`
  color: #747474;
  font-family: "inter_regular";
  margin-bottom: 10px;
`;
const Input = styled.input`
  width: 100%;
  background-color: #f4f5f8;
  border: 1px solid #dfe8ed;
  height: 60px;
  font-size: 18px;
  padding-left: 20px;
  border-radius: 5px;
  &.auto {
    color: #adadad;
  }
  @media (max-width: 540px) {
    font-size: 14px;
  }
`;
const Select = styled.select`
  width: 100%;
  background-color: #f4f5f8;
  border: 1px solid #dfe8ed;
  height: 60px;
  font-size: 18px;
  padding-left: 20px;
  border-radius: 5px;
  outline: none;
  &.auto {
    color: #adadad;
  }
  @media (max-width: 540px) {
    font-size: 14px;
  }
`;
const Icon = styled.div`
  color: #292c2d;
  position: absolute;
  right: 20px;
  top: 53px;
  cursor: pointer;
  @media all and (max-width: 400px) {
    right: 20px;
    top: 38px;
  }
`;
