import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { Context } from "../../../../context/Store";
import styled from "styled-components";
import { action, curve, left_arrow, plus } from "../../../../components/assets";
import NodataFound from "../../../includes/nodata/NodataFound";
import RenewModal from "./Modal";
import api from "../../../../api";
import Swal from "sweetalert2";
function Cows() {
    const [getData, setData] = useState([]);
    const [isModal, setModal] = useState(false);
    const [render, setRender] = useState(true);
    const [cowData, setCowData] = useState({
      name: "",
      age: "",
      breed: "",
      insured: false,
      is_deleted: false,
    });
    const [ isEdit, setEdit] = useState(false);

    const navigate = useNavigate();

    const { id } = useParams();
    // Accessing user data from context
    const {
        dispatch,
        state: { user_data },
    } = useContext(Context);
    const token = user_data.access_token;

    useEffect(() => {
        // Updating user data in context
        dispatch({
            type: "UPDATE_USER_DATA",
            user_data: {
                active_menu: "farmers",
            },
        });
    }, []);
    //list cows
    useEffect(() => {
        api.get(
            `activities/society-farmer-cow-list/${id}`,

            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        ).then((res) => {
            if (res.app_data.StatusCode === 6000) {
                setData(res.app_data.data);
            } else {
                setData([]);
            }
        });
    }, [isModal, render]);

    const confirmDelete = (item) => {
        setRender(true);
        Swal.fire({
            title: "Are you sure to proceed?",
            showDenyButton: true,
            confirmButtonText: "Yes",
            denyButtonText: `No`,
        }).then((result) => {
            if (result.isConfirmed) {
                handleDelete(item);
            }
        });
    };

    const handleDelete = (item) => {
        api.post(`activities/delete-cows/${item.id}/`, {}).then((res) => {
            if (res.app_data.StatusCode == 6000) {
                setRender(false);
            } else {
            }
        });
    };
    return (
        <Container>
            <TopBar>
                <TopTitle>Cows</TopTitle>
                <Add
                    to=""
                    onClick={() => {
                        setModal(true);
                        setCowData({
                          name: "",
                          age: "",
                          breed: "",
                          insured: false,
                          is_deleted: false,
                        });
                    }}
                >
                    <Icon>
                        <img src={plus} alt="icon" />
                    </Icon>
                    <span>Add Cows</span>
                </Add>
            </TopBar>
            <div className="parent-scroll">
                <Bottom className="scroll">
                    <TableHead>
                        <TableItem>SI No</TableItem>
                        <TableItem>Name</TableItem>
                        <TableItem>Breed</TableItem>
                        <TableItem>Age</TableItem>
                        <TableItem>Insurance</TableItem>
                        <TableItem></TableItem>
                    </TableHead>
                    <CoverContainer>
                        {getData.length > 0 ? (
                            getData.map((item, index) => (
                                <TableContent key={index}>
                                    <TableItem>{index + 1}</TableItem>
                                    <TableItem>{item.name}</TableItem>
                                    <TableItem>{item.breed}</TableItem>
                                    <TableItem>{item.age}</TableItem>
                                    <TableItemInsurance insured={item.insured}>
                                        {item.insured
                                            ? "Insured"
                                            : "not-insured"}
                                    </TableItemInsurance>
                                    <TableItem>
                                        <Icon
                                            className="edit"
                                            onClick={() => {
                                                setModal(true);
                                                setCowData(item);
                                                setEdit(true);
                                            }}
                                        >
                                            <i class="ri-file-edit-fill"></i>
                                        </Icon>
                                        <Icon
                                            className="delete"
                                            onClick={() => confirmDelete(item)}
                                        >
                                            <i class="ri-delete-bin-6-line"></i>
                                        </Icon>
                                    </TableItem>
                                </TableContent>
                            ))
                        ) : (
                            <NodataFound />
                        )}
                    </CoverContainer>
                    <RenewModal
                        isOpen={isModal}
                        onClose={() => {
                          setModal(false);
                          setCowData({});
                        }}
                        cowData={cowData}
                        setCowData={setCowData}
                        isEdit={isEdit}
                        setEdit={setEdit}
                    />
                </Bottom>
            </div>
        </Container>
    );
}

export default Cows;

const Container = styled.div`
    margin-top: 20px;
    padding: 0px 15px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
`;

const Top = styled.div`
    margin: 0px;
    padding: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const Button = styled.div`
    width: 175px;
    height: 40px;
    background: #fff;
    color: #2382d4;
    align-items: center;
    display: flex;
    justify-content: center;
    border: 1px solid #2382d4;
    border-radius: 4px;
    font-size: 14px;
    padding: 6px 16px;
    cursor: pointer;

    &.background {
        background-color: #2382d4;
        color: #fff;
        margin-left: 15px;
    }

    img {
        margin-right: 10px;
    }
`;

const Tabs = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
`;

const Tab = styled(Link)`
    border-top: 1px solid #dfe8ed;
    text-decoration: none;
    border-left: 1px solid #dfe8ed;
    border-right: 1px solid #dfe8ed;
    border-bottom: 1px solid #dfe8ed;
    padding: 14px 20px;
    cursor: pointer;
    margin-right: 30px;
    color: #797d82;
    border-radius: 5px 5px 0 0;

    &.active {
        color: #2382d4;
        background-color: #e0f3ff;
        border-top: 1px solid #2382d4;
        border-left: 1px solid #2382d4;
        border-right: 1px solid #2382d4;
        border-bottom: 3px solid #2382d4;
    }
`;

const Bottom = styled.div`
    padding-top: 15px;
`;
const TableHead = styled.div`
    height: 70px;
    background: #fff;
    padding: 0 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
`;

const TableItems = styled.div`
    width: 10%;
    color: #000;
    font-size: 18px;
`;

const TableContainer = styled.div`
    height: 70px;
    background: #fff;
    padding: 0 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 15px;
`;

const CoverContainer = styled.div`
    max-height: calc(100vh - 250px);
    overflow: auto;
`;

const TableContent = styled.div`
    height: 70px;
    background: #fff;
    padding: 0 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
`;

const EditIcon = styled.div`
    color: #747474;
    font-size: 24px;
    cursor: pointer;
    padding: 0;
    margin-right: 10px;
    border-radius: 5px;
    height: 29px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const DeleteIcon = styled.div`
    color: #747474;
    font-size: 24px;
    cursor: pointer;
    padding: 0;
    margin-right: 10px;
    border-radius: 5px;
    height: 29px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const ViewIcon = styled(Link)`
    color: #747474;
    font-size: 24px;
    cursor: pointer;
    padding: 0;
    margin-right: 10px;
    border-radius: 5px;
    height: 29px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const TableItem = styled.div`
    color: #747474;
    font-size: 15px;
    display: flex;
    font-family: "inter_regular";
    align-items: center;
    width: 10%;
    white-space: nowrap;
    &.delete {
        color: #cd4444;
        font-size: 22px;
        cursor: pointer;
    }
`;
const TableItemInsurance = styled.div`
    font-size: 15px;
    display: flex;
    font-family: "inter_regular";
    align-items: center;
    width: 10%;
    white-space: nowrap;
    color: ${({ insured }) => (insured ? "green" : "red")};
`;

const TopBar = styled.div`
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;
const TopTitle = styled.h2`
    color: #0a0a0a;
    margin-bottom: 5px;
`;
const Add = styled(Link)`
    text-decoration: none;
    background-color: #fff;
    border: 1px solid #0a0a0a;
    color: #0a0a0a;
    /* width: 120px; */
    height: 35px;
    display: flex;
    padding: 20px;
    border-radius: 5px;
    cursor: pointer;
    z-index: 1;
    justify-content: space-between;
    align-items: center;
`;
const Icon = styled.div`
    margin-right: 10px;
    img {
        display: block;
        filter: brightness(0.1);
    }
    &.delete {
        color: #cd4444;
        font-size: 22px;
        cursor: pointer;
    }
    &.edit {
        color: #2382d4;
        font-size: 22px;
        cursor: pointer;
    }
`;
