import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, Outlet, useNavigate } from "react-router-dom";
import { styled } from "styled-components";
import { SearchInput } from "../../../components";
import {
  NotificationIcon,
  ProfileIcon,
  SettingsIcon,
} from "../../../components/assets/icons";
import { money, plus_sign, settings } from "../../../components/assets";
import { Fade } from "react-reveal";
import { Context } from "../../../context/Store";

const Header = () => {
  const [isShow, setShow] = useState(false);
  const navigate = useNavigate();
  const ref = useRef(null);

  const [isCreate, setCreate] = useState(false);
  //----------OutsideClick---------------------------------------------------
  function useOutsideClick(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setCreate(false);
          // setIsProfile(false);
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }
  const wrapperRef = useRef(null);
  useOutsideClick(wrapperRef);

  //logout function
  const handleLogout = () => {
    localStorage.clear();
    window.location.reload();
  };

  const {
    state: { user_data },
  } = useContext(Context);
  const role = user_data.roles;
  const username = user_data.username;

 

  return (
    <>
  
      <Container>
        {/* <SearchInput /> */}
        <Right>
          <CreateButton onClick={() => setCreate(true)}>+ Create</CreateButton>
          <CreateDrop ref={wrapperRef} className={isCreate && "active"}>
            <General>
              <Head>
                <Icon>
                  <img src={settings} alt="image" />
                </Icon>
                <span>General</span>
              </Head>
              {role == "Plant" ? (
                <>
                  <Item to="/create-route">
                    <Icon>
                      <img src={plus_sign} alt="image" />
                    </Icon>
                    <span>Add Route</span>
                  </Item>
                  <Item to="/create-society">
                    <Icon>
                      <img src={plus_sign} alt="image" />
                    </Icon>
                    <span>Add Society</span>
                  </Item>
                  <Item to="/create-farmer">
                    <Icon>
                      <img src={plus_sign} alt="image" />
                    </Icon>
                    <span>Add Farmer</span>
                  </Item>
                  <Item to="/create-user">
                    <Icon>
                      <img src={plus_sign} alt="image" />
                    </Icon>
                    <span>Add User</span>
                  </Item>
                </>
              ) : (
                <Item to="/create-farmer">
                  <Icon>
                    <img src={plus_sign} alt="image" />
                  </Icon>
                  <span>Add Farmer</span>
                </Item>
              )}
            </General>
            <Payment>
              <Head>
                <Icon>
                  <img src={money} alt="image" />
                </Icon>
                <span>Payment</span>
              </Head>
              {/* <Item to="/add-bonus">
                <Icon>
                  <img src={plus_sign} alt="image" />
                </Icon>
                <span>Create Bonus</span>
              </Item> */}
              <Item to="/add-incentive">
                <Icon>
                  <img src={plus_sign} alt="image" />
                </Icon>
                <span>Create Incentive</span>
              </Item>
              <Item to="/add-loan">
                <Icon>
                  <img src={plus_sign} alt="image" />
                </Icon>
                <span>Create Loan</span>
              </Item>
            </Payment>
          </CreateDrop>
          <IconContainer onClick={() => navigate("/notification")}>
            <img src={NotificationIcon} />
          </IconContainer>
          <IconContainer onClick={() => setShow(!isShow)}>
            <img src={SettingsIcon} />
          </IconContainer>
          <IconContainer2>
            <img src={ProfileIcon} />
          </IconContainer2>
          <h4 className="username">{username}</h4>
          {isShow && <Logout onClick={() => handleLogout()}>Logout</Logout>}
        </Right>
      </Container>

      <Outlet />
    </>
  );
};

export default Header;


const Container = styled.div`
  width: 100%;
  padding: 15px 40px;
  background: #fff;
  display: flex;
  position: fixed;
  width: 100%;
  left: 0;
  z-index: 10;
  justify-content: flex-end;

  @media (max-width: 768px) {
    padding: 15px 10px;

    .username {
      font-size: 14px;
    }
  }
  @media (max-width: 490px) {
    padding: 15px 10px;

    .username {
      font-size: 12px;
    }
  }
`;
const Right = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  gap: 20px;
  @media (max-width: 768px) {
    gap: 5px;
  }
`;
const CreateButton = styled.div`
  /* width: 85px;
  height: 35px; */
  padding: 8px 15px;
  cursor: pointer;
  background: #2382d4;
  color: #fff;
  align-items: center;
  display: flex;
  justify-content: center;
  border-radius: 20px;
  font-family: "inter_light";
  @media (max-width: 540px) {
    padding: 7px 9px;
  }
  @media (max-width: 520px) {
    font-size: 12px;
  }
`;
const Logout = styled.div`
  background-color: #2382d4;
  position: absolute;
  cursor: pointer;
  height: 50px;
  /* left: -60px; */
  /* top: 60px; */
  border-radius: 8px;
  color: #fff;
  width: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const IconContainer = styled.div`
  cursor: pointer;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  padding: 8px;
  img {
    width: 100%;
  }
  // height:70px;
  @media (max-width: 640px) {
    width: 40px;
    height: 40px;
  }
  @media (max-width: 520px) {
    width: 35px;
    height: 35px;
  }
`;
const IconContainer2 = styled.div`
  width: 45px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  padding: 12px;
  background: #e0f3ff;
  img {
    width: 100%;
  }
  @media (max-width: 640px) {
    width: 40px;
    height: 40px;
  }
  @media (max-width: 520px) {
    width: 35px;
    height: 35px;
  }
`;
const CreateDrop = styled.div`
  display: flex;
  align-items: flex-start;
  background-color: #fff;
  padding: 20px;
  border: 1px solid #dfe8ed;
  border-radius: 5px;
  position: absolute;
  top: 60px;
  left: -100px;
  /* z-index: 1; */
  opacity: 0;
  transition: 0.4s ease;
  z-index: -10;
  display: none;
  &.active {
    opacity: 1;
    display: flex;
    z-index: 111;
    transition: 0.4s ease;
  }
`;
const General = styled.div`
  margin-right: 20px;
`;
const Head = styled.div`
  display: flex;
  align-items: center;
  span {
    color: #8c9295;
  }
  margin-bottom: 20px;
`;
const Icon = styled.div`
  margin-right: 5px;
`;
const Item = styled(Link)`
  display: flex;
  margin-bottom: 20px;
  text-decoration: none;
  cursor: pointer;
  align-items: center;
  color: #414244;
  font-family: "inter_light";
`;
const Payment = styled.div``;
