import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import ButtonLoader from "../../../../components/button-loader/ButtonLoader";
import api from "../../../../api";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";

const RenewModal = ({
    isOpen,
    onClose,
    cowData,
    setCowData,
    isEdit,
    setEdit,
}) => {
    const [isLoading, setLoading] = useState(false);
    const [isInsured, setInsured] = useState(cowData?.insured);
    const { id } = useParams();
    const [isError, setError] = useState("");
    const formRef = useRef(null);
    console.log(cowData, "cowdata");
    const showSuccessModal = () => {
        Swal.fire({
            position: "center",
            icon: "success",
            title: isEdit
                ? "Cow data edited Successfully"
                : "Cow added Successfully",
            showConfirmButton: false,
            timer: 1500,
        });
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(cowData, "cow data on submit");
        if (!isEdit) {
            const formData = new FormData();
            console.log(
                formData,
                "formData from cow add",
                formRef,
                formRef.current
            );
            formData.append("farmer", id);
            formData.append("name", cowData.name);
            formData.append("breed", cowData.breed);
            formData.append("age", cowData.age);
            formData.append("insured", cowData.insured ? "True" : "False");
            api.post(`activities/add-cow/`, formData, {}).then((res) => {
                if (res.app_data.StatusCode == 6000) {
                    showSuccessModal();
                    setInsured(false);
                    e.target.reset();
                    onClose(false);
                } else {
                    setError(res.app_data.data.message);
                }
            });
        } else {
            const formData = new FormData();
            console.log(
                formData,
                "formData from cow add",
                formRef,
                formRef.current
            );
            formData.append("farmer", id);
            formData.append("name", cowData.name);
            formData.append("breed", cowData.breed);
            formData.append("age", cowData.age);
            formData.append("insured", cowData.insured ? "True" : "False");
            api.post(`activities/edit-cow/${cowData?.id}/`, formData, {}).then(
                (res) => {
                    if (res.app_data.StatusCode == 6000) {
                        showSuccessModal();
                        e.target.reset();
                        onClose(false);
                        setCowData({});
                        setInsured(false);
                        setEdit(false);
                    } else {
                        setError(res.app_data.data.message);
                    }
                }
            );
        }
    };

    return (
        <ModalWrapper isOpen={isOpen}>
            <ModalContent onSubmit={(e) => handleSubmit(e)}>
                <h2>Cow details</h2>
                <CoverButton>
                    <CoverinButton>
                        <Label> Name*</Label>
                        <Input
                            value={cowData?.name}
                            onChange={(event) =>
                                setCowData((prev) => ({
                                    ...prev,
                                    [event.target.name]: event.target.value,
                                }))
                            }
                            type="text"
                            placeholder="Enter Cows's name"
                            name="name"
                            required
                        />
                    </CoverinButton>
                </CoverButton>
                <CoverButton>
                    <CoverinButton>
                        <Label>Breed*</Label>
                        <Input
                            type="text"
                            value={cowData?.breed}
                            onChange={(event) =>
                                setCowData((prev) => ({
                                    ...prev,
                                    [event.target.name]: event.target.value,
                                }))
                            }
                            placeholder="Enter Breed name"
                            name="breed"
                            required
                        />
                    </CoverinButton>
                </CoverButton>
                <CoverButton>
                    <CoverinButton>
                        <Label> Age*</Label>
                        <Input
                            type="text"
                            placeholder="Enter age"
                            name="age"
                            value={cowData?.age}
                            onChange={(event) =>
                                setCowData((prev) => ({
                                    ...prev,
                                    [event.target.name]: event.target.value,
                                }))
                            }
                            required
                        />
                    </CoverinButton>
                </CoverButton>
                <CoverButton>
                    <CoverinButton>
                        <Label> Insured*</Label>
                        <ToggleSwitchWrapper>
                            <HiddenCheckbox
                                type="checkbox"
                                name="insured"
                                checked={cowData.insured}
                                // onChange={() => setInsured(!isInsured)}
                                onChange={(event) => {
                                    setCowData((prev) => ({
                                        ...prev,
                                        [event.target.name]:
                                            event.target.checked,
                                    }));
                                    setInsured(!isInsured);
                                    console.log(
                                        isInsured,
                                        "isnruance",
                                        cowData.insured
                                    );
                                }}
                            />
                            <Switch />
                        </ToggleSwitchWrapper>
                    </CoverinButton>
                </CoverButton>

                <Buttons>
                    {isLoading ? (
                        <Button>
                            <ButtonLoader />
                        </Button>
                    ) : (
                        <>
                            <SubmitButtons
                                onClick={() => {
                                    setCowData({});
                                    onClose(false);
                                    setEdit(false);
                                    setInsured(false);
                                }}
                            >
                                Cancel
                            </SubmitButtons>
                            <SubmitButton type="submit" value="Submit" />
                        </>
                    )}
                </Buttons>
            </ModalContent>
        </ModalWrapper>
    );
};

export default RenewModal;
const ModalWrapper = styled.div`
    display: ${({ isOpen }) => (isOpen ? "block" : "none")};
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
`;

const ModalContent = styled.form`
    position: absolute;
    top: 50%;
    left: 56%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    padding: 42px;
    border-radius: 15px;
    width: 565px;
    padding: 40px;

    h2 {
        color: #0a0a0a;
        font-family: "inter_regular";
        margin-bottom: 30px;
    }

    button {
        display: flex;
        width: 160px;
        padding: 6px 20px;
        justify-content: center;
        align-items: center;
        gap: 6px;
        align-self: stretch;
        border-radius: 4px;
        background: #2382d4;
    }
`;

const CoverButton = styled.div``;
const CoverinButton = styled.div`
    width: 92%;
    margin-right: 20px;
    margin-bottom: 30px;
    &:nth-child(even) {
        margin-right: 0;
    }
    position: relative;
    &.add {
        width: 48%;
        margin-right: 0;
    }
`;

const Label = styled.h3`
    color: #747474;
    font-family: "inter_regular";
    margin-bottom: 10px;
`;
const Input = styled.input`
    background-color: #f4f5f8;
    border: 1px solid #dfe8ed;
    font-size: 18px;
    padding-left: 20px;
    border-radius: 4px;
    width: 108%;
    height: 66px;
    padding: 18px 23px;
    align-items: center;
    gap: 10px;
    &.auto {
        color: #adadad;
    }
`;
const Button = styled.div`
    width: 170px;
    color: #fff;
    cursor: pointer;
    border-radius: 5px;
    background-color: #2382d4;
    height: 50px;
    width: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 35px;
    &.cancel {
        background-color: #fff;
        border: 1px solid #2382d4;
        color: #2382d4;
        margin-right: 20px;
    }
`;
const SubmitButtons = styled.div`
    background-color: #fff;
    border: 1px solid #2382d4;
    color: #2382d4;
    margin-right: 20px;
    width: 170px;
    height: 50px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
`;

const SubmitButton = styled.input`
    width: 170px;
    color: #fff;
    cursor: pointer;
    border-radius: 5px;
    background-color: #2382d4;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    &.cancel {
        background-color: #fff;
        border: 1px solid #2382d4;
        color: #2382d4;
        margin-right: 20px;
    }
`;

const Buttons = styled.div`
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
`;
const Emi = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 50px;
    h6 {
        color: #747474;
        font-family: "inter_regular";
        font-size: 18px;
        margin-right: 16px;
    }
`;

const ToggleSwitchWrapper = styled.label`
    position: relative;
    display: inline-block;
    width: 50px;
    height: 25px;
`;

const HiddenCheckbox = styled.input`
    opacity: 0;
`;

const Switch = styled.span`
    position: absolute;
    cursor: pointer;
    background-color: #fff;
    border-radius: 25px;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transition: background-color 0.2s ease;
    border: 1px solid #000;

    &:before {
        content: "";
        position: absolute;
        left: 1px;
        top: 1px;
        width: 21px;
        height: 21px;
        background-color: #000;
        border-radius: 50%;
        transition: transform 0.3s ease;
    }

    ${HiddenCheckbox}:checked + &::before {
        transform: translateX(25px);
        background-color: #000;
    }

    ${HiddenCheckbox}:checked + & {
        background-color: #fff;
    }
`;
