import React, { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import api from "../../../api";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import { getDDMMYY, getDateStr, getYYMMDD } from "../../../utils/functions/function";
import { Context } from "../../../context/Store";
import Loader from "../../../components/loader/Loader";
import NodataFound from "../../includes/nodata/NodataFound";

const MilkReport = () => {
  const [datas, setDatas] = useState([]);
  const [filterModal, setFilterModal] = useState(false);
  const [filter, setFilter] = useState("");
  const [time, setTime] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEnddate] = useState("");
  const [showDateInput, setShowDateInput] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isPrintLoading, setIsPrintLoading] = useState(false);
  const currentDate = new Date();

  const {
    dispatch,
    state: { user_data },
  } = useContext(Context);
  useEffect(() => {
    dispatch({
      type: "UPDATE_USER_DATA",
      user_data: {
        active_menu: "milk-report",
      },
    });
  }, []);
  const token = user_data.access_token;
  useEffect(() => {
    setIsLoading(true);
    if (filter || filter !== "") {
      api
        .get(`activities/society-milk-report/?filtered_by=${filter}`)
        .then((res) => {
          setDatas(res.app_data.data);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
        })
    } else {
      api
        .get(
          `activities/society-milk-report/?time=${time}&start_date=${getYYMMDD(
            startDate
          )}&end_date=${getYYMMDD(endDate)}`
        )
        .then((res) => {
          setDatas(res.app_data.data);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
        })
    }
  }, [filter, time, startDate, endDate]);
  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };
  const toggleDateInput = () => {
    setShowDateInput(!showDateInput);
  };
  const customStyles = {
    width: "100px",
    height: "30px",
    borderRadis: "8px",
    // Add any other custom styles here if needed
  };
  const CustomInput = ({ value, onClick }) => (
    <input
      type="text"
      value={value}
      onClick={onClick}
      style={{ width: "100px", height: "30px" }}
    />
  );
  const handleDownload = async () => {
    setIsPrintLoading(true);
    try {
      let apiUrl =
        "https://api.jeevamilk.com/api/v1/activities/print-milk-report/";

      if(startDate && endDate) {
        apiUrl = `https://api.jeevamilk.com/api/v1/activities/print-milk-report/?from_date=${getYYMMDD(startDate)}&to_date=${getYYMMDD(endDate)}`
      }

      const response = await fetch(apiUrl, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      setIsPrintLoading(false);
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      // Extract the blob from the response
      const blob = await response.blob();

      // Create a temporary URL for the Blob
      const url = URL.createObjectURL(blob);

      // Create a link element
      const link = document.createElement("a");

      // Set the href attribute of the link to the URL of the Blob
      link.href = url;

      // Set the download attribute with the desired file name
      link.download = "milk-report";

      // Append the link to the document
      document.body.appendChild(link);

      // Trigger a click on the link to start the download
      link.click();

      // Remove the link from the document
      document.body.removeChild(link);

      // Revoke the URL to release the resources
      URL.revokeObjectURL(url);
    } catch (error) {
      setIsPrintLoading(false);
      console.error("Error downloading file:", error);
    }
  };
  return (
    <TotalContainer>
      <Top>
        <h3>Milk Report</h3>
        <TopRight>
          {
            !startDate && !endDate &&
            <DateContainer>{getDateStr(currentDate)}</DateContainer>
          }
          <ButContainer>From</ButContainer>
          {/* <CalenderDiv onClick={toggleDateInput}>
            08 Jan, 2023
          </CalenderDiv> */}
          <DatePicker
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            required
            customInput={<CustomInput />}
            style={customStyles}
            dateFormat="dd/MM/yyyy"
          />
          {/* {showDateInput && <input type="date" />} */}
          <ButContainer>To</ButContainer>
          <DatePicker
            selected={endDate}
            onChange={(date) => setEnddate(date)}
            required
            customInput={<CustomInput />}
            dateFormat="dd/MM/yyyy"
            style={customStyles}
          />
          <TimeSect>
            <SelectButton
              className={`left ${time === "" && "active"}`}
              onClick={() => setTime("")}
            >
              All
            </SelectButton>
            <SelectButton
              className={time === "am" && "active"}
              onClick={() => setTime("am")}
            >
              AM
            </SelectButton>
            <SelectButton
              className={`right ${time === "pm" && "active"}`}
              onClick={() => setTime("pm")}
            >
              PM
            </SelectButton>
          </TimeSect>
          <FilterButton onClick={() => handleDownload()}>
            {
              isPrintLoading ?
              "Loading..." 
              :
              "Print"
            }
          </FilterButton>
        </TopRight>
      </Top>
      <div className="parent-scroll">
        <Bottom className="scroll">
          <TableHead>
            <TableItem>SI No.</TableItem>
            <TableItem>Date</TableItem>
            <TableItem>Name</TableItem>
            <TableItem>Time</TableItem>
            <TableItem>Qty/L</TableItem>
            <TableItem>CLR</TableItem>
            <TableItem>FAT</TableItem>
            <TableItem>SNF</TableItem>
            <TableItem>Bonus</TableItem>
            <TableItem>Price/Ltr</TableItem>
            <TableItem>Amount</TableItem>
            <TableItem>Damage</TableItem>
          </TableHead>
          {
          isLoading ?
          <Loader />
          :
          datas?.length > 0 ?
            filter ?
              datas?.map((item, index) => (
                <>
                  <FilterHead>
                    <h5>
                      {filter} Name: {item.society}
                    </h5>
                  </FilterHead>
                  {item?.data?.map((soc, i) => (
                    <TableBottom>
                      <TableBottomItem>{i + 1}</TableBottomItem>
                      <TableBottomItem>{soc.date}</TableBottomItem>
                      <TableBottomItem title={soc.farmer}>{soc.farmer}</TableBottomItem>
                      <TableBottomItem className="time">
                        {soc.time_status}
                      </TableBottomItem>
                      <TableBottomItem>{soc?.quantity}</TableBottomItem>
                      <TableBottomItem>{soc.clr}</TableBottomItem>
                      <TableBottomItem>{soc.fat}</TableBottomItem>
                      <TableBottomItem>{soc.snf}</TableBottomItem>
                      <TableBottomItem>{soc.bonus}</TableBottomItem>
                      <TableBottomItem>{soc.net_rate}</TableBottomItem>
                      <TableBottomItem>{soc.amount}</TableBottomItem>
                      <TableBottomItem>
                        {soc.damaged_milk_quantity}
                      </TableBottomItem>
                    </TableBottom>
                  ))}
                </>
              ))
            :
            datas?.map((soc, i) => (
              <TableBottom>
                <TableBottomItem>{i + 1}</TableBottomItem>
                <TableBottomItem>{soc.date}</TableBottomItem>
                <TableBottomItem title={soc.farmer}>{soc.farmer}</TableBottomItem>
                <TableBottomItem className="time">
                  {soc.time_status}
                </TableBottomItem>
                <TableBottomItem>{soc.quantity?.toFixed(2)}</TableBottomItem>
                <TableBottomItem>{soc.clr}</TableBottomItem>
                <TableBottomItem>{soc.fat}</TableBottomItem>
                <TableBottomItem>{soc.snf}</TableBottomItem>
                <TableBottomItem>{soc.bonus}</TableBottomItem>
                <TableBottomItem>{soc.net_rate}</TableBottomItem>
                <TableBottomItem>{soc.amount}</TableBottomItem>
                <TableBottomItem>{soc.damaged_milk_quantity}</TableBottomItem>
              </TableBottom>
            ))
            :
            <TableBottomNoData>
              <NodataFound />
            </TableBottomNoData>
          }
          {/* {!filter &&
            datas?.map((soc, i) => (
              <TableBottom>
                <TableBottomItem>{i + 1}</TableBottomItem>
                <TableBottomItem>{soc.date}</TableBottomItem>
                <TableBottomItem title={soc.farmer}>{soc.farmer}</TableBottomItem>
                <TableBottomItem className="time">
                  {soc.time_status}
                </TableBottomItem>
                <TableBottomItem>{soc.quantity?.toFixed(2)}</TableBottomItem>
                <TableBottomItem>{soc.clr}</TableBottomItem>
                <TableBottomItem>{soc.fat}</TableBottomItem>
                <TableBottomItem>{soc.snf}</TableBottomItem>
                <TableBottomItem>{soc.bonus}</TableBottomItem>
                <TableBottomItem>{soc.net_rate}</TableBottomItem>
                <TableBottomItem>{soc.amount}</TableBottomItem>
                <TableBottomItem>{soc.damaged_milk_quantity}</TableBottomItem>
              </TableBottom>
            ))} */}
        </Bottom>
      </div>
    </TotalContainer>
  );
};

export default MilkReport;

const TotalContainer = styled.div`
  margin-top: 30px;
  padding: 0px 15px;
`;
const Top = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 13px;
  h3 {
    font-size: 28px;
  }
`;
const TopRight = styled.div`
  display: flex;
  gap: 5px;
  justify-content: flex-end;
  align-items: center;
  @media (max-width: 1080px) {
    min-width: 610px;
    overflow: scroll;
  }
`;
const DateContainer = styled.div`
  color: #747474;
  font-family: "inter_regular";
  width: 100%;
  text-align: end;
`;
const ButContainer = styled.div`
  padding: 5px 8px;
  background: #2382d4;
  border-radius: 8px;
  color: #fff;
  font-size: 14px;
  font-weight: 400;
`;
const CalenderDiv = styled.div`
  background: #fff;
  padding: 5px 8px;
  border-radius: 8px;
  position: relative;
  input {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
  }
`;
const TimeSect = styled.div`
  border-radius: 8px;
  /* overflow:hidden; */
  /* height:25px; */
  display: flex;
  gap: 3px;
`;
const SelectButton = styled.div`
  padding: 5px 8px;
  /* background:#fff; */
  border: 0.5px solid #7fadd4;
  /* background:#2382D4; */
  /* height:40px; */
  /* border-radius:8px; */
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
  color: #000;
  &.left {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }
  &.active {
    background: #2382d4;
    color: #fff;
  }
  &.right {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }
`;
const FilterButton = styled.button`
  border: none;
  cursor: pointer;
  outline: none;
  border: 1px solid #2382d4;
  padding: 5px 12px;
  border-radius: 8px;
  position: relative;
`;
const Bottom = styled.div`
  margin-top: 20px;
  background: #fff;
  /* padding:20px; */
`;
const TableHead = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px;
`;
const TableItem = styled.div`
  width: 6%;
  text-align: center;
  color: #747474;
  font-weight: 400;
  /* background:green; */
  &.num {
    /* width:5%; */
    /* width:8%; */
  }
  &.time {
    /* width:5%; */
  }
`;
const TableBottom = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px;
  /* background:red; */
`;
const TableBottomNoData = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px;
  /* background:red; */
  height: calc(100vh - 250px);
  overflow: scroll;
`;
const TableBottomItem = styled.div`
  width: 6%;
  text-align: center;
  font-size: 15px;
  text-overflow: ellipsis;
  overflow: hidden;
  &.time {
    text-transform: uppercase;
  }
  /* background:blue; */
`;
const FilterHead = styled.div`
  width: 100%;
  height: 80px;
  background: #00000033;
  padding: 20px;
  display: flex;
  align-items: center;
  h3: {
    font-size: 18px;
  }
`;
const FilterContainer = styled.div`
  position: absolute;
  width: 300px;
  /* height:500px; */
  background: #fff;
  z-index: 10;
  right: 50px;
  top: 150px;
  padding: 20px;
  transform: ${({ filterModal }) => (filterModal ? "scale(1)" : "scale(0)")};
  transition: 0.4s ease;
  transform-origin: top right;
`;
const FilterTop = styled.div`
  font-size: 33px;
  color: #2382d4;
  text-align: center;
`;
const FilterBottom = styled.div``;
const FilterItem = styled.div`
  display: flex;
  gap: 5px;
  padding: 10px 0px;
`;
const FilterButtons = styled.div`
  display: flex;
  justify-content: space-between;
`;
const FilterApply = styled.button`
  background: #2382d4;
  border: none;
  outline: none;
  width: 120px;
  height: 40px;
  radius: 4px;
  color: #fff;
`;
