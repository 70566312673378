import React, { useState } from "react";
import { styled } from "styled-components";
import CreateCard from "../../../../components/create/CreateCard";

function AddLoan() {
  return (
    <Container>
      <CreateCard
        category="loan"
        title="Add Loan"
        head_tag="Loan / Add Loan"
        info_title="Loan Info"
        same_rate_tag="Loan Amount"
        notifyCheckBox
      />
    </Container>
  );
}

export default AddLoan;

const Container = styled.div`
  max-height: calc(100vh - 100px);
  border-radius: 8px;
  padding: 20px;
  width: 70%;
  margin: 0 auto;

  @media (max-width: 820px) {
    width: 99%;
  }
`;
