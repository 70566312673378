import React from "react";
import nocart from "../../../../components/assets/images/shopping2.jpg";
import styled from "styled-components";

export default function NoCart() {
  return (
    <Container>
      <img src={nocart} alt="" />
    </Container>
  );
}

const Container = styled.div`
  width: 50%;
  height: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  img {
    display: block;
    width: 100%;
  }
`;
