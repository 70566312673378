import React, { createContext, useReducer } from "react";
import Reducer from "./Reducer";

const initialState = {
  user_data: {
    isVerified: false,
    access_token: "",
    refresh_token: "",
    active_menu: "",
    active_sub_menu: "",
    loan_id: "",
    roles: "",
    username: "",
    price_per_litre: "",
    privileges: [],
    bonus_id: "",
    incentive_id: "",
    start_date: "",
    end_date: "",
  },
  route_data: {
    route_name: "",
    route_code: "",
  },
};
const Store = ({ children }) => {
  const [state, dispatch] = useReducer(Reducer, initialState);
  return (
    <Context.Provider value={{ state, dispatch }}>{children}</Context.Provider>
  );
};

export const Context = createContext(initialState);

export default Store;
