import React, { useContext, useEffect, useState } from "react";
import { styled } from "styled-components";
import { CanDetailEntry } from "../../../api/society";
import { Context } from "../../../context/Store";
import Loader from "../../../components/loader/Loader";
import CanList from "./CanList";
import { curveWhite, milk } from "../../../components/assets";
import ButtonLoader from "../../../components/button-loader/ButtonLoader";

function CanDetails() {
  const [calculate, setCalculate] = useState(false);
  const [getCanDetails, setCanDetails] = useState();
  const [quantity, setQuantity] = useState();
  const [totalCanNumbers, setTotalCanNumbers] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isAdd, setAdd] = useState(false);
  const [timeType, setTimeType] = useState("am");

  const {
    dispatch,
    state: { user_data },
  } = useContext(Context);
  const token = user_data.access_token;
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  useEffect(() => {
    dispatch({
      type: "UPDATE_USER_DATA",
      user_data: {
        active_menu: "can-details",
      },
    });
    setTimeout(() => setIsLoading(false), 1100);
  }, []);
  const handleSelectChange = (event) => {
    setTimeType(event.target.value);
  };

  //payload;
  const formData = new FormData();
  formData.append("quantity", quantity);
  formData.append("total_number_can", totalCanNumbers);
  formData.append("time_status", timeType);

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsButtonLoading(true);
    if (!totalCanNumbers) {
      setErrorMessage("Please enter total number of cans");
      setIsButtonLoading(false);
    } else if (!quantity) {
      setErrorMessage("Please enter milk quantity");
      setIsButtonLoading(false);
    } else {
      CanDetailEntry(config, formData).then((res) => {
        if (res.app_data.StatusCode == 6000) {
          setCanDetails(res.app_data.data);
          setCalculate(true);
          setIsButtonLoading(false);
          setErrorMessage("");
          submitClearFunction();
          setAdd(false);
        } else {
          setIsButtonLoading(false);
          setErrorMessage("");
        }
      });
    }
  };
  const submitClearFunction = (e) => {
    setQuantity("");
    setCanDetails("");
    setTotalCanNumbers("");
    setCalculate(false);
  };
  return isLoading ? (
    <Loader />
  ) : !isAdd ? (
    <CanList setAdd={setAdd} />
  ) : (
    <Container>
      <SocietyBox>
        <Label>AM/PM</Label>
        <Select value={timeType} onChange={handleSelectChange}>
          <option value="am">AM</option>
          <option value="pm">PM</option>
        </Select>
      </SocietyBox>
      <TopBox>
        <Heading>Can details</Heading>
      </TopBox>
      <BottomBox>
        <GeneralBox
          onSubmit={(e) => {
            handleSubmit(e);
          }}
        >
          <SingleBox>
            <Label>Total number of cans</Label>
            <InputBox>
              <input
                type="text"
                placeholder="00"
                value={totalCanNumbers}
                onChange={(e) => setTotalCanNumbers(e.target.value)}
                required
              />
            </InputBox>
          </SingleBox>
          <TitleBox>
            <Title>Quantity</Title>
            <HrLine></HrLine>
          </TitleBox>

          <InfoBox>
            <BoxContainer>
              <HeadContainer>
                <Icon>
                  <img src={milk} alt="icon" />
                </Icon>
                <InfoTitle>Total Milk</InfoTitle>
              </HeadContainer>
              <Cover>
                <input
                  type="number"
                  step="any"
                  placeholder="00"
                  value={quantity}
                  onChange={(e) => setQuantity(e.target.value)}
                  required
                />
                <h3>Litres </h3>
              </Cover>
              <Curve>
                <img src={curveWhite} alt="image" />
              </Curve>
            </BoxContainer>
          </InfoBox>

          <ButtonBox>
            {/* <ErrorMessage>{errorMessage}</ErrorMessage> */}

            {isButtonLoading ? (
              <Submit>
                <ButtonLoader />
              </Submit>
            ) : (
              <SubmitButton type="submit" value="Submit" />
            )}
            <Cancel onClick={() => setAdd(false)}>Cancel</Cancel>
          </ButtonBox>
        </GeneralBox>
      </BottomBox>
    </Container>
  );
}

export default CanDetails;

const Container = styled.div`
  width: 60%;
  margin: 20px auto;
  height: calc(100vh - 110px);
  overflow-y: scroll;
  background-color: #fff;
  padding: 40px;

  @media (max-width:768px) {
    width: 90%;
  }
`;

const TopBox = styled.div`
  margin-bottom: 20px;
`;

const Heading = styled.h2`
  color: #0a0a0a;
  font-size: 26px;
`;

const BottomBox = styled.div`
  border-radius: 8px;
`;

const TitleBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 26px;
`;

const Cancel = styled.div`
  color: #2382d4;
  cursor: pointer;
  border: 1px solid #2382d4;
  width: 300px;
  height: 50px;
  float: right;
  border-radius: 5px;
  display: flex;
  margin-left: 10px;
  justify-content: center;
  align-items: center;
`;
const Title = styled.h2`
  font-size: 18px;
  margin-right: 10px;
`;
const HrLine = styled.div`
  width: 100%;
  height: 1px;
  background-color: #eee;
`;
const GeneralBox = styled.form`
  width: 100%;
  margin-bottom: 26px;
`;

const Select = styled.select`
  padding: 18px 24px;
  border: 1px solid #dfe8ed;
  border-radius: 4px;
  width: 100%;
  margin: 10px 0;
  outline: none;
  background-color: unset;
  font-size: 18px;
  font-family: "inter_light";
`;
const SocietyBox = styled.div`
  width: 30%;
  display: inline-block; /* Display inline to have them in the same row */
  margin-right: 10px; /* Add some margin for spacing */
  position: relative;
  margin-bottom: 20px;
  input,
  select {
    padding: 18px 24px;
    border: 1px solid #dfe8ed;
    border-radius: 4px;
    width: 100%;
    margin: 10px 0;
    font-size: 18px;
    font-family: "inter_light";
    &.id {
      text-transform: capitalize;
    }
  }
`;
const SingleBox = styled.div`
  width: 100%;
  margin-bottom: 30px;

  input {
    background-color: transparent;
    width: 100%;
    font-size: 18px;
  }
`;

const InputBox = styled.div`
  background-color: #f4f5f8;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 18px 24px;
  margin: 10px 0;
  border-radius: 4px;
  border: 1px solid #dfe8ed;

  input {
    font-family: "inter_light";
  }
`;

const Label = styled.h4`
  color: #747474;
  font-family: "inter_light";
  font-weight: unset;
  font-size: 18px;
`;

const InfoBox = styled.div`
  width: 100%;
`;

const BoxContainer = styled.div`
  position: relative;
  background-color: #f4f5f8;
  padding: 24px;
  width: 100%;
  margin-right: 20px;
  margin-bottom: 20px;
  border: 1px solid #dfe8ed;
  border-radius: 8px;
  &:last-child {
    margin-right: 0;
  }
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }
  input {
    color: #0a0a0a;
    font-size: 40px;
    margin-right: 10px;
    width: 100%;
    padding: 10px 0;
    border-bottom: 1px solid #dfe8ed;
    background-color: #f4f5f8;
  }
  h3 {
    color: #747474;
    font-family: "inter_regular";
    font-size: 22px;
  }
`;
const HeadContainer = styled.div`
  display: flex;
  align-items: center;
`;
const Cover = styled.div`
  display: flex;
  align-items: flex-end;
  margin-top: 10px;
  margin-bottom: 20px;
`;
const Icon = styled.div`
  /* width: 55px; */
  margin-right: 10px;
  img {
    width: 100%;
    display: block;
  }
`;
const InfoTitle = styled.h4`
  color: #747474;
  font-family: "inter_light";
  font-weight: unset;
  font-size: 20px;
`;
const Curve = styled.div`
  position: absolute;
  top: 0;
  right: 0;

  img {
    width: 100%;
    display: block;
  }
`;

const ButtonBox = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
`;

const ErrorMessage = styled.span`
  color: red;
  /* position: absolute;
  bottom: 50px;
  right: 0;
  width: 300px;
  text-align: right; */
`;

const CoverTable = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const TableLeft = styled.div`
  display: flex;
  align-items: center;
`;
const Column = styled.div`
  width: 100px;
  margin-right: 10px;
  border-radius: 8px;
`;
const Top = styled.div`
  border-radius: 8px 8px 0 0;
  border: 1px solid #d1d9dd;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Bottom = styled.div`
  /* border-radius: 8px; */
  background-color: #f4f5f8;
  border: 1px solid #d1d9dd;
  height: 50px;
  color: #2382d4;
  display: flex;
  justify-content: center;
  align-items: center;
  input {
    text-align: center;
  }
`;
const TabRight = styled.div`
  width: 30%;
`;

const Values = styled.div``;
const TotalCover = styled.div`
  width: 100%;
`;
const CoverBottom = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;
const Value = styled.h4`
  color: #747474;
  font-family: "inter_regular";
`;
const Count = styled.h3`
  color: #2382d4;
`;
const Total = styled.div`
  display: flex;
  justify-content: space-between;
  align-self: center;
  margin-bottom: 60px;
  position: relative;
`;
const TotalValue = styled.h3`
  color: #2382d4;
`;
const Submit = styled.div`
  background-color: ${({ type }) => (type ? "#2382d4" : "#21272d")};
  color: #fff;
  width: 300px;
  height: 50px;
  float: right;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const SubmitButton = styled.input`
  background-color: #2382d4;
  color: #fff;
  cursor: pointer;
  width: 300px;
  height: 50px;
  float: right;
  border-radius: 5px;
  text-align: center;
`;
