import React, { useContext } from "react";
import {
  black_calendar,
  curve,
  download,
  left_arrow,
  printer,
} from "../../../../components/assets";
import { styled } from "styled-components";
import { local_sale, paymentlist } from "../../../../utils/arrays";
import { useNavigate } from "react-router-dom";
import { Context } from "../../../../context/Store";

function FarmerPaymentList() {
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };
  const {
    dispatch,
    state: { user_data },
  } = useContext(Context);

  const accessToken = user_data.access_token;

  return (
    <Container>
      <TopBar>
        <TopCover>
          <Arrow onClick={goBack}>
            <img src={left_arrow} alt="image" />
          </Arrow>
          <Text>
            <TopTitle>Payment history </TopTitle>
            <RoutePath>
              Routes / Route no : 0001 / Societies / Society 1 / Payment history
            </RoutePath>
          </Text>
        </TopCover>
        <RightCover>
          <Print>
            <IConPr>
              <img src={printer} alt="image" />
            </IConPr>
            <span>Print Invoice</span>
          </Print>
          <Invoice>
            <IConPr>
              <img src={download} alt="image" />
            </IConPr>
            <span>Download Invoice</span>
          </Invoice>
        </RightCover>
        <Curve>
          <img src={curve} alt="" />
        </Curve>
      </TopBar>
      <Table>
        <LabelItems>
          <Label>Sl No</Label>
          <Label>Amount</Label>
          <Label>Transaction ID</Label>
          <Label>Date</Label>
          <Label>Transaction mode</Label>
          <Label>Action</Label>
        </LabelItems>
        {paymentlist.map((item, index) => (
          <CardLabel key={index}>
            <Item>{item.id} </Item>
            <Item>{item.amount}</Item>
            <Item className="blue">{item.transaction_id} </Item>
            <Item>{item.date}</Item>
            <Item>{item.mode}</Item>
            <Item>
              <Icon>
                <img src={download} alt="image" />
                <span>Download</span>
              </Icon>
            </Item>
          </CardLabel>
        ))}
      </Table>
    </Container>
  );
}

export default FarmerPaymentList;

const Container = styled.div`
  padding: 20px;
`;
const TopBar = styled.div`
  padding: 20px;
  background-color: #fff;
  position: relative;
  border: 1px solid #dfe8ed;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #dfe8ed;
  border-radius: 5px;
`;
const TopCover = styled.div`
  display: flex;
`;
const Arrow = styled.div`
  border: 1px solid #2382d4;
  display: flex;
  cursor: pointer;
  border-radius: 5px;
  padding: 4px;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
`;
const Text = styled.div``;
const RightCover = styled.div`
  z-index: 1;
  display: flex;
`;
const Print = styled.div`
  background-color: #2382d4;
  padding: 10px;
  display: flex;
  align-items: center;
  border-radius: 5px;
  color: #fff;
  margin-right: 10px;
  cursor: pointer;
`;
const IConPr = styled.div`
  margin-right: 10px;
  img {
    display: block;
    /* width: 100%; */
  }
`;
const Invoice = styled.div`
  color: #2382d4;
  border: 1px solid #2382d4;
  cursor: pointer;
  display: flex;
  align-items: center;
  background-color: #fff;
  padding: 10px;
  border-radius: 5px;
`;
const TopTitle = styled.h2`
  color: #2382d4;
  margin-bottom: 5px;
`;
const RoutePath = styled.h4`
  color: #747474;
`;
const Curve = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  img {
    width: 100%;
    display: block;
  }
`;
const Table = styled.div`
  background-color: #fff;
`;
const LabelItems = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 30px;
  align-items: center;
  border-bottom: 1px solid #dfe8ed;
`;
const CardLabel = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #dfe8ed;
  align-items: center;
  padding: 30px;
`;
const Item = styled.div`
  width: 20%;
  font-family: "inter_medium";
  color: #2e3032;
  &.blue {
    color: #2382d4;
  }
`;
const Icon = styled.div`
  display: flex;
  span {
    font-size: 14px;
  }
  img {
    margin-right: 5px;
  }
`;
const Label = styled.div`
  width: 20%;
  color: #797d82;
  font-family: "inter_regular";
  font-size: 18px;
`;
