import React, { useContext, useEffect, useState } from "react";
import { styled } from "styled-components";
import { Card, LineChartGraph } from "../../../components";
import { dropdown, farmers, totalicon } from "../../../components/assets";
import BarChartGraph from "../../../components/chart/BarChartGrapfh";
import {
  TotalCounts,
  plantQualityGraphData,
  plantQuantityGraphData,
} from "../../../api/auth";
import { Context } from "../../../context/Store";
import Loader from "../../../components/loader/Loader";

const Statics = () => {
  const [fetchTotalData, setTotalData] = useState();
  const [graphData, setGraphData] = useState({});
  const [qualityGraphData, setQualityGraphdata] = useState();
  const [isDropDown, setDropDown] = useState("");
  const [filterData, setFilterData] = useState("This Week");
  const [qulaityFilter, setQualityFilter] = useState("This Week");
  const [filterValues, setFilterValues] = useState("");
  const [QualityfilterValues, setQulaityFilterValues] = useState("");

  const [isLoading, setIsLoading] = useState(true);
  const {
    dispatch,
    state: { user_data },
  } = useContext(Context);
  const token = user_data.access_token;
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  useEffect(() => {
    TotalCounts(config).then((res) => {
      if (res.app_data.StatusCode == 6000) {
        setIsLoading(false);
        setTotalData(res.app_data.data);
      }
    });
  }, []);
  useEffect(() => {
    dispatch({
      type: "UPDATE_USER_DATA",
      user_data: {
        active_menu: "dashboard",
      },
    });
  }, []);
  const quantity = "quantity";
  const quality = "quality";
  useEffect(() => {
    plantQuantityGraphData(config, filterValues).then((res) => {
      setGraphData(res.app_data.data);
    });
    plantQualityGraphData(config, QualityfilterValues).then((res) => {
      setQualityGraphdata(res.app_data.data);
    });
  }, [filterValues, QualityfilterValues]);
  const filter_keys = [
    {
      id: 1,
      title: "This Month",
      value: "30",
    },
    {
      id: 2,
      title: "This Week",
      value: "10",
    },
  ];
  const handleFiter = (item) => {
    setFilterData(item.title);
    setDropDown(null);
  };
  const handleQualityFiter = (item) => {
    setQualityFilter(item.title);
    setDropDown(null);
  };
  const handleDropDown = (item) => {
    if (isDropDown) {
      setDropDown("");
    } else {
      setDropDown(item);
    }
  };
  return isLoading ? (
    <Loader />
  ) : (
    <Container>
      <Title>Dashboard</Title>
      <Cards>
        <Card
          title="Total jeeva societies"
          icon={totalicon}
          number={fetchTotalData?.jeeva_society_count}
          label="Societies"
          path="total-societies/?society=jeeva_society"
        />
        <Card
          title="Total private societies"
          icon={totalicon}
          number={fetchTotalData?.private_society_count}
          path="total-societies/?society=private_society"
          label="Societies"
        />
        <Card
          title="Total Farms"
          icon={totalicon}
          number={fetchTotalData?.farmes}
          path="total-societies/?society=farm"
          label="Farms"
        />
        <Card
          title="Total farmers"
          icon={farmers}
          number={fetchTotalData?.farmers}
          path="plant-farmers"
          label="Farmers"
        />
      </Cards>
      <GraphContainer>
        <Cover>
          <TitleCover>
            <TitleGraph>Quantity Analysis</TitleGraph>
            <Drop onClick={() => handleDropDown(quantity)}>
              {filterData}
              <Icon>
                <img src={dropdown} alt="icon" />
              </Icon>
              {isDropDown == "quantity" && (
                <DropDown>
                  {filter_keys.map((item, index) => (
                    <Fil
                      key={index}
                      onClick={() => {
                        handleFiter(item);
                        setFilterValues(item.value);
                      }}
                    >
                      {item.title}
                    </Fil>
                  ))}
                </DropDown>
              )}
            </Drop>
          </TitleCover>
          <LineChartGraph graphData={graphData} />
        </Cover>
        <Cover>
          <TitleCover>
            <TitleGraph>Quality Analysis</TitleGraph>
            <Drop onClick={() => handleDropDown(quality)}>
              {qulaityFilter}
              <Icon>
                <img src={dropdown} alt="icon" />
              </Icon>
              {isDropDown == "quality" && (
                <DropDown>
                  {filter_keys.map((item, index) => (
                    <Fil
                      key={index}
                      onClick={() => {
                        handleQualityFiter(item);
                        setQulaityFilterValues(item.value);
                      }}
                    >
                      {item.title}
                    </Fil>
                  ))}
                </DropDown>
              )}
            </Drop>
          </TitleCover>
          <BarChartGraph qualityGraphData={qualityGraphData} />
        </Cover>
      </GraphContainer>
    </Container>
  );
};

export default Statics;

const TitleCover = styled.h3`
  color: #0a0a0a;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  box-sizing: border-box;
`;
const TitleGraph = styled.div``;
const DropDown = styled.div`
  background-color: #e0f3ff;
  border-radius: 5px;
  position: absolute;
  width: 120px;
  top: 30px;
`;
const Fil = styled.div`
  padding: 10px 20px;
  border-bottom: 1px solid #c4c4c4;
  cursor: pointer;
  font-size: 13px;
  &:last-child {
    border-bottom: 1px solid transparent;
  }
  transition: 0.4s ease;
  &:hover {
    background-color: #2382d4;
    color: #fff;
    transition: 0.4s ease;
  }
`;
const Icon = styled.div`
  margin-left: 5px;
`;
const Drop = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #0a0a0a;
  font-family: "inter_regular";
  font-size: 14px;
  position: relative;
`;
const Container = styled.div`
  padding: 20px;
  max-height: calc(100vh - 100px);
  overflow-y: scroll;
`;
const Title = styled.h2`
  color: #0a0a0a;
  margin-bottom: 20px;
  font-size: 26px;
`;
const Cards = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (min-width: 768px)and (max-width:1080px) {
    display:grid;
    grid-template-columns: repeat(2, 1fr);
    gap:20px;
  }
  @media  (max-width:767px) {
    display:grid;
    grid-template-columns: repeat(1, 1fr);
    gap:20px;
  }
`;
const GraphContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 30px 0;
  @media (max-width:980px) {
    flex-direction:column;
    gap: 40px;
    height:100%;
  }
`;
const Cover = styled.div`
  background-color: #fff;
  margin-right: 20px;
  padding: 20px;
  width: 49%;

  &:last-child {
    margin-right: 0;
  }
  @media (max-width:980px) {
    width: 99%;
    height:100%;
    margin-right: 8px;
  }
`;
