import React, { useContext, useEffect, useState } from "react";
import { styled } from "styled-components";
import List from "../../../../components/List";
import { add, filterIcon } from "../../../../components/assets";
import { Link, useNavigate } from "react-router-dom";
import { GetBonusDatas } from "../../../../api/auth";
import { Context } from "../../../../context/Store";
import Nodata from "../../../includes/nodata/Nodata";
import SectionLoader from "../../../../components/sectionloader/SectionLoader";
import { getSocietyBonus } from "../../../../api/society";
import Loader from "../../../../components/loader/Loader";

function Bonus() {
  const [bonusdata, setBonuSData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  // get accesstoken from store
  const {
    dispatch,
    state: { user_data },
  } = useContext(Context);
  const token = user_data.access_token;
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  useEffect(() => {
    dispatch({
      type: "UPDATE_USER_DATA",
      user_data: {
        active_menu: "bonus",
      },
    });
  }, []);
  useEffect(() => {
    getSocietyBonus(config).then((res) => {
      if (res.app_data.StatusCode == 6000) {
        setBonuSData(res.app_data.data);
      }
    });
    setTimeout(() => setIsLoading(false), 1100);
  }, []);

  const getBonusId = (row) => {
    dispatch({
      type: "UPDATE_USER_DATA",
      user_data: {
        bonus_id: row.id,
      },
    });
  };
  return (
    <Container>
      <TopBox>
        <Title>Bonus</Title>
        <NavButtons>
          <Button onClick={() => navigate("/add-bonus")}>
            <img src={add} />
            Create bonus
          </Button>
          <Button className="background">
            <img src={filterIcon} />
            Filter
          </Button>
        </NavButtons>
      </TopBox>
      <ListContainer>
        <HeadRow>
          <HeadColumnData>Sl. no</HeadColumnData>
          <HeadColumnData>Particulars</HeadColumnData>
          <HeadColumnData>Time period</HeadColumnData>
          <HeadColumnData>Rate</HeadColumnData>
          <HeadColumnData>Total expense</HeadColumnData>
        </HeadRow>
        <BottomContainer>
          {isLoading ? (
            <Loader />
          ) : bonusdata?.length == 0 ? (
            <Nodata />
          ) : (
            bonusdata?.map((row, index) => (
              <Row
                key={index}
                to="/view-edit-bonus"
                onClick={() => getBonusId(row)}
              >
                <ColumnData className="bold">{index + 1}</ColumnData>
                <ColumnData className="bold">
                  {row.bonus_description}
                </ColumnData>
                <ColumnData>{(row.start_date, row.end_date)}</ColumnData>
                <ColumnData>₹{row.bonus_amount} per litre</ColumnData>
                <ColumnData className="bold">₹{row.total_expense}</ColumnData>
              </Row>
            ))
          )}
        </BottomContainer>
      </ListContainer>
      {/* <List 
                rowTitle={rowTitle}
                rowValue={rowValue}
            /> */}
    </Container>
  );
}

export default Bonus;

const Container = styled.div`
  padding: 20px;
`;
const TopBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;
const Title = styled.h2`
  color: #0a0a0a;
  /* margin-bottom: 20px; */
  font-size: 26px;
`;

const NavButtons = styled.div`
  display: flex;
  justify-content: space-between;
`;
const Button = styled.div`
  /* width:48px;
    height:26px; */
  background: #fff;
  color: #2382d4;
  align-items: center;
  display: flex;
  justify-content: center;
  border: 1px solid #2382d4;
  border-radius: 4px;
  font-size: 14px;
  padding: 6px 16px;
  cursor: pointer;

  &.background {
    background-color: #2382d4;
    color: #fff;
    margin-left: 15px;
  }

  img {
    margin-right: 10px;
  }
`;

const BottomContainer = styled.div`
  max-height: calc(100vh - 250px);
  overflow-y: scroll;
`;

const ListContainer = styled.div`
  background-color: #fff;
  /* padding: 0 24px; */
  border: 1px solid #dfe8ed;
  border-radius: 8px;

  h3 {
    color: #747474;
    font-family: "inter_regular";
    font-size: 22px;
  }
`;

const HeadRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px 20px;
  border-bottom: 1px solid #ccc;
`;
const Row = styled(Link)`
  display: flex;
  text-decoration: none;
  align-items: center;
  justify-content: space-between;
  padding: 30px 20px;
  border-bottom: 1px solid #ccc;
`;

const HeadColumnData = styled.div`
  color: #747474;
  font-size: 18px;
  font-family: "inter_light";
  width: 15%;
  &:first-child {
    width: 7%;
  }
  &:nth-child(3) {
    width: 25%;
  }
  &:last-child {
    text-align: right;
  }
`;
const ColumnData = styled.div`
  color: #0a0a0a;
  font-size: 18px;
  font-family: "inter_medium";
  width: 15%;
  &:first-child {
    width: 7%;
  }
  &:nth-child(3) {
    width: 25%;
  }
  &:last-child {
    text-align: right;
  }
  &.bold {
    font-family: "inter_regular";
  }
`;
