import React, { useState } from "react";
import { styled } from "styled-components";
import { clr } from "../../../../components/assets";
import CreateCard from "../../../../components/create/CreateCard";

function AddIncentive() {
  return (
    <Container>
      <CreateCard
        category="incentive"
        title="Add Incentive"
        head_tag="Incentive / Create Incentive"
        info_title="Incentive Info"
        same_rate_tag="Incentive Amount"
        notifyCheckBox
      />
    </Container>
  );
}

export default AddIncentive;

const Container = styled.div`
  max-height: calc(100vh - 100px);
  border-radius: 8px;
  padding: 20px;
  width: 70%;
  margin: 0 auto;
  @media (max-width: 1080px) {
    width: 90%;
    overflow-x: hidden;
    margin-top: 10px;
  }
`;
const TopBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* width: 50%; */
`;
const Title = styled.h2`
  color: #0a0a0a;
  /* margin-bottom: 20px; */
  font-size: 26px;
`;
const SubmitButton = styled.div`
  /* width:48px;
    height:26px; */
  background: #2382d4;
  color: #fff;
  align-items: center;
  display: flex;
  justify-content: center;
  border-radius: 8px;
  font-size: 14px;
  padding: 6px 20px;
  /* font-family:'DM Sans'; */
`;
const BottomBox = styled.div`
  display: flex;
  /* justify-content: space-between; */
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  background-color: #fff;
  border: 1px solid #dfe8ed;
  border-radius: 8px;
  padding: 40px;
`;

const QuantityContainer = styled.div`
  width: 100%;
  margin-bottom: 30px;
`;
const NameBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;
const SocietyBox = styled.div`
  width: 49%;
  input {
    padding: 18px 24px;
    border: 1px solid #dfe8ed;
    border-radius: 4px;
    width: 100%;
    margin: 10px 0;
    font-size: 18px;
  }
`;

const TitleBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const TitleQuantity = styled.div`
  margin-right: 10px;
`;
const HrLine = styled.div`
  width: 100%;
  height: 1px;
  background-color: #eee;
`;

const CoverCard = styled.div`
  display: flex;
`;
const CalculationContainer = styled.div`
  margin-left: auto;
  width: 50%;
`;

const ValueBox = styled.div`
  display: flex;
  align-items: flex-end;
  flex-direction: column;
`;

const SNFBox = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  margin-bottom: 14px;
`;

const Label = styled.h4`
  color: #747474;
  font-family: "inter_regular";
  font-weight: unset;
  font-size: 18px;
`;

const LabelValue = styled.span`
  width: 30%;
  text-align: right;
  font-size: 22px;
`;

const PerLiterBox = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  margin-bottom: 14px;
`;

const PriceBox = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 14px 0;
`;

const TotalPrice = styled.h4`
  color: #747474;
  font-family: "inter_regular";
  font-weight: unset;
  font-size: 20px;
`;
const TotalPriceValue = styled.span`
  font-size: 26px;
  width: 30%;
  text-align: right;
  color: #2382d4;
`;
