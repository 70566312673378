import React, { useState } from "react";
import { styled } from "styled-components";
import { clr } from "../../../../components/assets";
import CreateCard from "../../../../components/create/CreateCard";

function AddBonus() {
  return (
    <Container>
      <CreateCard
        category="bonus"
        title="Add Bonus"
        head_tag="Bonus / add bonus"
        info_title="Bonus Info"
        same_rate_tag="Bonus Amount"
        notifyCheckBox
      />
    </Container>
  );
}

export default AddBonus;

const Container = styled.div`
  max-height: calc(100vh - 100px);
  overflow-y: scroll;
  border-radius: 8px;
  width: 70%;
  margin: 0 auto;
  @media (max-width: 1080px) {
    width: 90%;
    overflow-x: hidden;
    margin-top: 10px;
  }
`;