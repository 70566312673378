import React, { useRef, useState } from "react";
import styled from "styled-components";
import ButtonLoader from "../../../components/button-loader/ButtonLoader";
import Swal from "sweetalert2";
import api from "../../../api";
const NotificationModal = ({ isOpen, onClose }) => {
  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const formRef = useRef(null);
  const showSuccessModal = () => {
    Swal.fire({
      position: "center",
      icon: "success",
      title: "Notification sent Successfully",
      showConfirmButton: false,
      timer: 1500,
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData(formRef.current);

    api
      .post("activities/create-society-notification/", formData, {})
      .then((res) => {
        if (res.app_data.StatusCode == 6000) {
          showSuccessModal();
          e.target.reset();
          onClose(false);
          setIsButtonLoading(false);
        } else {
          setIsButtonLoading(false);
        }
      });
  };

  return (
    <ModalWrapper isOpen={isOpen}>
      <ModalContent onSubmit={(e) => handleSubmit(e)} ref={formRef}>
        <h2>Notification</h2>
        <CoverButton>
          <Label>Heading</Label>
          <Input type="text" name="heading" required />
        </CoverButton>
        <CoverButton>
          <Label>Message</Label>
          <Textarea name="message" required />
        </CoverButton>
        <Buttons>
          {isButtonLoading ? (
            <Button>
              <ButtonLoader />
            </Button>
          ) : (
            <>
              <SubmitButtons onClick={() => onClose(false)}>
                Cancel
              </SubmitButtons>
              <SubmitButton type="submit" value="Send" />
            </>
          )}
        </Buttons>
      </ModalContent>
    </ModalWrapper>
  );
};

export default NotificationModal;
const ModalWrapper = styled.div`
  display: ${({ isOpen }) => (isOpen ? "block" : "none")};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
`;

const ModalContent = styled.form`
  position: absolute;
  top: 56%;
  left: 56%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 42px;
  border-radius: 15px;
  width: 506px;
  height: 580px;
  padding: 40px;

  h2 {
    color: #0a0a0a;
    font-family: "inter_regular";
    margin-bottom: 30px;
  }
  button {
    display: flex;
    width: 160px;
    padding: 6px 20px;
    justify-content: center;
    align-items: center;
    gap: 6px;
    align-self: stretch;
    border-radius: 4px;
    background: #2382d4;
  }
  @media (max-width: 1080px) {
    left: 53%;
    width: 81%;
  }
`;

const CoverButton = styled.div`
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
`;
const CoverinButton = styled.div`
  width: 92%;
  margin-right: 20px;
  margin-bottom: 30px;
  &:nth-child(even) {
    margin-right: 0;
  }
  position: relative;
  &.add {
    width: 48%;
    margin-right: 0;
  }
`;
const Label = styled.h3`
  color: #747474;
  font-family: "inter_regular";
  margin-bottom: 10px;
`;
const Input = styled.input`
  background-color: #f4f5f8;
  border: 1px solid #dfe8ed;
  font-size: 18px;
  padding-left: 20px;
  border-radius: 4px;
  width: 100%;
  height: 66px;
  padding: 18px 23px;
  align-items: center;
  gap: 10px;
  &.auto {
    color: #adadad;
  }
`;
const Textarea = styled.textarea`
  background-color: #f4f5f8;
  border: 1px solid #dfe8ed;
  font-size: 18px;
  padding-left: 20px;
  outline: none;
  border-radius: 4px;
  width: 100%;
  height: 180px;
  padding: 18px 23px;
  align-items: center;
  gap: 10px;
  &.auto {
    color: #adadad;
  }
`;
const Buttons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 55px;
`;
const Button = styled.div`
  width: 170px;
  color: #fff;
  cursor: pointer;
  border-radius: 5px;
  background-color: #2382d4;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 35px;
  &.cancel {
    background-color: #fff;
    border: 1px solid #2382d4;
    color: #2382d4;
    margin-right: 20px;
  }
`;
const SubmitButton = styled.input`
  width: 221px;
  color: #fff;
  cursor: pointer;
  border-radius: 5px;
  background-color: #2382d4;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const SubmitButtons = styled.div`
  width: 221px;
  cursor: pointer;
  border-radius: 5px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #2382d4;
  margin-right: 20px;
  background-color: #fff;
  border: 1px solid #2382d4;
  color: #2382d4;
  margin-right: 20px;
  &.cancel {
    background-color: #fff;
    border: 1px solid #2382d4;
    color: #2382d4;
    margin-right: 20px;
  }
`;
