import React, { useEffect } from "react";
import { Provider } from "react-redux";
import store from "./redux/Store";
import { BrowserRouter } from "react-router-dom";
import MainRouter from "./routes/router/MainRouter";
import "./components/assets/css/style.css";
import Store from "./context/Store";
import "remixicon/fonts/remixicon.css";
import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";
import firebaseConfig from "./utils/FirebaseConfigs";
import "sweetalert2/src/sweetalert2.scss";
import "react-datepicker/dist/react-datepicker.css";
  import "react-toastify/dist/ReactToastify.css";

// import swDev from "./swDev";

function App() {
  useEffect(() => {
    const app = initializeApp(firebaseConfig);
    const messaging = getMessaging(app);

    // if ("serviceWorker" in navigator) {
    //   navigator.serviceWorker
    //     .register("/firebase-messaging-sw.js")
    //     .then((registration) => {
    //       console.log(
    //         "Service Worker registered with scope:",
    //         registration.scope
    //       );
    //     })
    //     .catch((error) => {
    //       console.error("Service Worker registration failed:", error);
    //     });
    // }

    const getTokenFromFirebase = async () => {
      try {
        const currentToken = await getToken(messaging, {
          vapidKey:
            "BIPFA3_D_yISGFXIJ8vob5hH6EGYhO5nrx9lXeWc-5DrvocZIoQkeUaBMvTIRkFCD9qCws_HYHVRzpL9st4cqyM",
        });

        if (currentToken) {
          // Send the token to your server and update the UI if necessary
          // ...
        } else {
          // Show permission request UI
          await messaging.requestPermission();
          const token = await messaging.getToken();
        }
      } catch (error) {}
    };

    getTokenFromFirebase();
  }, []);
  // swDev();
  return (
    <Store>
      <BrowserRouter>
        <Provider store={store}>
          <MainRouter />
        </Provider>
      </BrowserRouter>
    </Store>
  );
}

export default App;
