import React, { useContext, useEffect, useState } from "react";
import { styled } from "styled-components";
import { add, filterIcon } from "../../../../components/assets";
import { Link, useNavigate } from "react-router-dom";
import { Context } from "../../../../context/Store";
import { GetIncentives } from "../../../../api/auth";
import Nodata from "../../../includes/nodata/Nodata";
import Loader from "../../../../components/loader/Loader";
import Swal from "sweetalert2";
import api from "../../../../api";

function Incentives() {
    const [getIncentives, setGetIncentives] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();
    // get accesstoken from store
    const {
        dispatch,
        state: { user_data },
    } = useContext(Context);
    const token = user_data.access_token;
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };

    const handleIncentiveData = () => {
      GetIncentives(config)
          .then((res) => {
              if (res.app_data.StatusCode == 6000) {
                  setGetIncentives(res.app_data.data);
                  setIsLoading(false);
              } else {
                  setGetIncentives([]);
              }
          })
          .catch((err) => {
              setIsLoading(false);
          });
    }
    useEffect(() => {
      handleIncentiveData();
        setTimeout(() => setIsLoading(false), 1100);
    }, []);
    useEffect(() => {
        dispatch({
            type: "UPDATE_USER_DATA",
            user_data: {
                active_menu: "incentives",
            },
        });
    }, []);
    const getIncentiveId = (row) => {
        dispatch({
            type: "UPDATE_USER_DATA",
            user_data: {
                incentive_id: row.id,
            },
        });
    };

    const confirmDelete = (e, item) => {
      // setRender(true);
      Swal.fire({
        title: "Are you sure to proceed?",
        showDenyButton: true,
        confirmButtonText: "Yes",
        denyButtonText: `No`,
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          handleDelete(e, item);
        }
      });
    };
    const handleDelete = (e, item) => {
      e.preventDefault();
  
      api
        .post(`activities/delete-society-incentives/${item.id}/`, {})
        .then((res) => {
          if (res.app_data.StatusCode == 6000) {
            // setRender(false);
            handleIncentiveData();
          } else {
          }
        });
    };
    return (
        <Container>
            <TopBox>
                <Title>Incentives</Title>
                <NavButtons>
                    <Button onClick={() => navigate("/add-incentive")}>
                        <img src={add} />
                        Create incentive
                    </Button>
                    {/* <Button className="background">
            <img src={filterIcon} />
            Filter
          </Button> */}
                </NavButtons>
            </TopBox>
            <CoverLi>
                <ListContainer>
                    <HeadRow>
                        <HeadColumnData>Sl. no</HeadColumnData>
                        <HeadColumnData>Particulars</HeadColumnData>
                        <HeadColumnData>From</HeadColumnData>
                        <HeadColumnData>To</HeadColumnData>
                        <HeadColumnData>Jeeva societies</HeadColumnData>
                        <HeadColumnData>Private societies</HeadColumnData>
                        <HeadColumnData>Farm</HeadColumnData>
                        <HeadColumnData>Total expense</HeadColumnData>
                        <HeadColumnData>Action</HeadColumnData>
                    </HeadRow>
                    <BottomContainer>
                        {isLoading ? (
                            <Loader />
                        ) : getIncentives.length == 0 ? (
                            <Nodata />
                        ) : (
                            getIncentives?.map((row, index) => (
                                <Row
                                    key={index}
                                    onClick={() => getIncentiveId(row)}
                                >
                                    <ColumnData className="bold">
                                        {index + 1}
                                    </ColumnData>
                                    <ColumnData className="bold color"
                                    >
                                        <span
                                        onClick={() => navigate("/view-edit-incentive")}
                                        >
                                            {row.incentive_description}
                                            </span>
                                    </ColumnData>
                                    <ColumnData>{row.start_date}</ColumnData>
                                    <ColumnData>{row.end_date}</ColumnData>
                                    <ColumnData>
                                        ₹{row.jeeva_incentive_amount}
                                    </ColumnData>
                                    <ColumnData>
                                        ₹{row.private_incentive_amount}
                                    </ColumnData>
                                    <ColumnData>
                                        ₹{row.farm_incentive_amount}
                                    </ColumnData>
                                    <ColumnData className="bold">
                                        ₹{row.total_expense}{" "}
                                    </ColumnData>
                                    <ColumnData className="bold">
                                        <DeleteIcon
                                            onClick={(e) => {
                                                confirmDelete(e, row);
                                            }}
                                        >
                                            <i class="ri-delete-bin-6-line"></i>
                                        </DeleteIcon>
                                    </ColumnData>
                                </Row>
                            ))
                        )}
                    </BottomContainer>
                </ListContainer>
            </CoverLi>
            {/* <List 
                rowTitle={rowTitle}
                rowValue={rowValue}
            /> */}
        </Container>
    );
}

export default Incentives;

const Container = styled.div`
    padding: 20px;
`;
const TopBox = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    flex-wrap: wrap;
    gap: 10px;
`;
const Title = styled.h2`
    color: #0a0a0a;
    /* margin-bottom: 20px; */
    font-size: 26px;
`;

const NavButtons = styled.div`
    display: flex;
    justify-content: space-between;
`;
const Button = styled.div`
    width: 175px;
    height: 40px;
    background: #fff;
    color: #2382d4;
    align-items: center;
    display: flex;
    justify-content: center;
    border: 1px solid #2382d4;
    border-radius: 4px;
    font-size: 14px;
    padding: 6px 16px;
    cursor: pointer;

    &.background {
        background-color: #2382d4;
        color: #fff;
        margin-left: 15px;
    }

    img {
        margin-right: 10px;
    }
`;

const BottomContainer = styled.div`
    max-height: calc(100vh - 250px);
    overflow-y: scroll;
`;

const ListContainer = styled.div`
    background-color: #fff;
    /* padding: 0 24px; */
    border: 1px solid #dfe8ed;
    border-radius: 8px;
    @media (max-width: 1080px) {
        min-width: 1280px;
        overflow-x: scroll;
    }
    h3 {
        color: #747474;
        font-family: "inter_regular";
        font-size: 22px;
    }
`;

const HeadRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 30px 20px;
    border-bottom: 1px solid #ccc;
`;
const Row = styled.div`
    display: flex;
    text-decoration: none;
    align-items: center;
    justify-content: space-between;
    padding: 30px 20px;
    border-bottom: 1px solid #ccc;
`;

const HeadColumnData = styled.div`
    color: #747474;
    font-size: 18px;
    font-family: "inter_light";
    width: 15%;
    &:first-child {
        width: 7%;
    }
    &:last-child {
        text-align: right;
    }
`;
const ColumnData = styled.div`
    color: #0a0a0a;
    font-size: 18px;
    font-family: "inter_light";
    width: 15%;
    &.bold {
        font-family: "inter_regular";
    }
    &:first-child {
        width: 7%;
    }
    &:last-child {
        text-align: right;
    }
    &.color{
        span{
            color: #2382d4;
            cursor: pointer;
        }
    }
`;

const DeleteIcon = styled.div`
    color: #ff0000;
    font-size: 24px;
    cursor: pointer;
    padding: 0;
    margin-right: 10px;
    border-radius: 5px;
    height: 29px;
`;
const CoverLi = styled.div`
    @media (max-width: 1080px) {
        overflow-x: scroll;
    }
`;
