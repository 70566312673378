import React, { useEffect, useState } from "react";
import { styled } from "styled-components";
import { curve } from "../assets";

function List(props) {
  const rows = [
    {
      serialNumber: 1,
      column1: "Value 1",
      column2: "Value 2",
      column3: "Value 3",
      column4: "Value 4",
      column5: "Value 5",
      column6: "Value 6",
    },
    {
      serialNumber: 2,
      column1: "Value 8",
      column2: "Value 9",
      column3: "Value 10",
      column4: "Value 11",
      column5: "Value 12",
      column6: "Value 13",
    },
    // Add more rows as needed
  ];

  // useEffect(() => {
  //     console.log(props)
  // }, [])

  return (
    <Container>
      <Table>
        <thead>
          <tr>
            {props?.rowTitle?.map((title) => (
              <Th>{title.key}</Th>
            ))}
          </tr>
        </thead>
        <tbody>
          {rows.map((row, index) => (
            <tr key={index}>
              <Td>{row.serialNumber}</Td>
              <Td>{row.column1}</Td>
              <Td>{row.column2}</Td>
              <Td>{row.column3}</Td>
              <Td>{row.column4}</Td>
              <Td>{row.column5}</Td>
              <Td>{row.column6}</Td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  );
}

export default List;

const Container = styled.div`
  position: relative;
  background-color: #fff;
  padding: 24px;
  width: 100%;
  /* height: 250px; */
  margin-right: 20px;
  border: 1px solid #dfe8ed;
  border-radius: 8px;
  &:last-child {
    margin-right: 0;
  }
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }
  input {
    color: #0a0a0a;
    font-size: 40px;
    margin-right: 10px;
    width: 100%;
    padding: 10px 0;
    border-bottom: 1px solid #dfe8ed;
  }
  h3 {
    color: #747474;
    font-family: "inter_regular";
    font-size: 22px;
  }
`;
const Table = styled.table`
  table-layout: auto;
  width: 100%;
  thead {
    line-height: 1em;
    border-bottom: 1px solid #dfe8ed;
  }
  tbody {
    line-height: 1em;
    border: 1px solid #dfe8ed;
  }
`;

const Th = styled.th`
  width: auto;
  color: #747474;
  font-size: 18px;
  font-family: "inter_light";
  text-align: left;
`;

const Td = styled.td`
  width: auto;
`;
