import React, { useContext, useEffect, useState } from "react";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { Context } from "../../../context/Store";
import { getArrivals, submitArrivals } from "../../../api/activities";
import NodataFound from "../../includes/nodata/NodataFound";
import api from "../../../api";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import { getYYMMDD } from "../../../utils/functions/function";
import Nodata from "../../includes/nodata/Nodata";

const ProductList = () => {
  const [modal, setModal] = useState(false);
  const [getData, setData] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEnddate] = useState("");

  const navigate = useNavigate();
  const { id } = useParams();
  const {
    dispatch,
    state: { user_data },
  } = useContext(Context);
  const token = user_data.access_token;
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const customStyles = {
    width: "100px",
    height: "30px",
    borderRadis: "8px",
    // Add any other custom styles here if needed
  };
  useEffect(() => {
    dispatch({
      type: "UPDATE_USER_DATA",
      user_data: {
        active_menu: "orders",
      },
    });
  }, []);
  useEffect(() => {
    api
      .get(`product/order/`, {
        params: {
          start_date: getYYMMDD(startDate),
          end_date: getYYMMDD(endDate),
        },
      })
      .then((res) => {
        if (res.app_data.StatusCode === 6000) {
          setData(res.app_data.data);
        } else {
          setData([]);
        }
      });
  }, [endDate]);
  const CustomInput = ({ value, onClick }) => (
    <input
      type="text"
      value={value}
      onClick={onClick}
      style={{ width: "100px", height: "30px" }}
    />
  );
  return (
    <>
      <TotalContainer>
        <CoverTop>
          <Top>
            <CoverOrder>
              <h3>Orders</h3>
              <Buttons className="background" to="/add-order">
                Add Order
              </Buttons>
            </CoverOrder>
            <TopRight>
              <ButContainer>From</ButContainer>
              <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                required
                dateFormat="dd-MM-yy"
                customInput={<CustomInput />}
                style={customStyles}
              />
              <ButContainer>To</ButContainer>
              <DatePicker
                selected={endDate}
                onChange={(date) => setEnddate(date)}
                dateFormat="dd-MM-yy"
                required
                minDate={startDate}
                customInput={<CustomInput />}
                style={customStyles}
              />
            </TopRight>
          </Top>
          <CoverB>
            <Bottom>
              <TableHead>
                <TableItem>Sl No.</TableItem>
                <TableItem>Purchase ID</TableItem>
                <TableItem>Date</TableItem>
                <TableItem>Society</TableItem>
                <TableItem>Products</TableItem>
                <TableItem>Total Amount</TableItem>
              </TableHead>
              {getData.length === 0 ? (
                <Nodata />
              ) : (
                getData?.map((item, index) => (
                  <TableContainer key={index}>
                    <TableItems>{index + 1} </TableItems>
                    <TableItems>{item.purchase_id ?item.purchase_id:"--"}</TableItems>
                    <TableItems>{item.date}</TableItems>
                    <TableItems>{item.profile}</TableItems>
                    <TableItems>{item.product.map((single,index)=>(
                      (index+1) == item.product.length ?
                      <span key={index}>{single.name} </span>
                      :
                      <span key={index}>{single.name}, {" "}</span>
                    ))}</TableItems>
                    <TableItems>{item.price}</TableItems>
                  </TableContainer>
                ))
              )}
            </Bottom>
          </CoverB>
        </CoverTop>
      </TotalContainer>
    </>
  );
};

export default ProductList;

const CoverTop = styled.div``;
const CoverOrder = styled.div`
  display: flex;
  gap: 10px;
`;
const Buttons = styled(Link)`
  width: 160px;
  height: 40px;
  background: #fff;
  color: #2382d4;
  text-decoration: none;
  align-items: center;
  display: flex;
  justify-content: center;
  border: 1px solid #2382d4;
  border-radius: 4px;
  font-size: 14px;
  padding: 6px 16px;
  cursor: pointer;

  &.background {
    background-color: #2382d4;
    color: #fff;
    margin-left: 15px;
  }

  img {
    margin-right: 10px;
  }
`;
const CoverB = styled.div`
  overflow: scroll;
`;
const TotalContainer = styled.div`
  margin-top: 30px;
  padding: 0px 15px;
  height: calc(100vh - 110px);
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;
const Top = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  h3 {
    font-size: 28px;
  }
  @media (max-width:680px) {
    flex-wrap: wrap;
    gap: 10px;
  }
`;
const Button = styled(Link)`
  background: #2382d4;
  width: 150px;
  height: 40px;
  border-radius: 8px;
  cursor: pointer;
  text-decoration: none;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Bottom = styled.div`
  padding-top: 15px;
  @media (max-width: 1080px) {
    min-width: 1280px;
    overflow-x: scroll;
  }
`;
const TableHead = styled.div`
  height: 70px;
  background: #fff;
  padding: 0px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const Icon = styled.div`
  color: #ff0000;
  font-size: 24px;
  cursor: pointer;
  &.edit {
    color: #2382d4;
  }
`;
const TableItem = styled.div`
  width: 10%;
  color: #747474;
  font-size: 18px;
`;
const TableItems = styled.div`
  width: 10%;
  color: #000000;
  font-size: 18px;
  &.total {
    color: #2382d4;
    cursor: pointer;
  }
`;
const TableContainer = styled.div`
  height: 70px;
  background: #fff;
  padding: 0px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;
`;
const Modal = styled.div`
  width: 100%;
  height: 100vh;
  background: #00000070;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const ModalContainer = styled.div`
  width: 45%;
  padding: 60px;
  background: #fff;
  border-radius: 10px;
  text-align: center;
  p {
    margin-bottom: 20px;
    font-weight: 400;
    color: #0a0a0a;
  }
`;
const ButtonNew = styled.button`
  background: #2382d4;
  width: 150px;
  height: 40px;
  border-radius: 8px;
  color: #fff;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  margin: 0 auto;
`;
const TopRight = styled.div`
  display: flex;
  gap: 5px;
`;
const ButContainer = styled.div`
  padding: 5px 8px;
  background: #2382d4;
  border-radius: 8px;
  color: #fff;
  font-size: 14px;
  font-weight: 400;
`;
const CalenderDiv = styled.div`
  background: #fff;
  padding: 5px 8px;
  border-radius: 8px;
  position: relative;
  input {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
  }
`;
const TimeSect = styled.div`
  border-radius: 8px;
  /* overflow:hidden; */
  /* height:25px; */
  display: flex;
  gap: 3px;
`;
const SelectButton = styled.div`
  padding: 5px 8px;
  /* background:#fff; */
  border: 0.5px solid #7fadd4;
  /* background:#2382D4; */
  /* height:40px; */
  /* border-radius:8px; */
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
  color: #000;
  &.left {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }
  &.active {
    background: #2382d4;
    color: #fff;
  }
  &.right {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }
`;
const FilterButton = styled.button`
  border: none;
  cursor: pointer;
  outline: none;
  border: 1px solid #2382d4;
  padding: 5px 12px;
  border-radius: 8px;
`;
