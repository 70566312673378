import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { Calender, curve, left_arrow } from "../../../../components/assets";
import { Context } from "../../../../context/Store";
import DatePicker from "react-datepicker";
import { getDDMMYY, getYYMMDD } from "../../../../utils/functions/function";
import { Link, useNavigate } from "react-router-dom";
import api from "../../../../api";

export default function ProcessedPayment() {
  const [startDate, setStartdate] = useState();
  const [endDate, setEndDate] = useState();

  const {
    dispatch,
    state: { user_data },
  } = useContext(Context);

  useEffect(() => {
    dispatch({
      type: "UPDATE_USER_DATA",
      user_data: {
        active_sub_menu: "processed",
        active_menu: "payments",
      },
    });
  }, []);
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };
  const cancelFunction = () => {
    setStartdate("");
    setEndDate("");
  };
  const dispatchDate = () => {
    dispatch({
      type: "UPDATE_USER_DATA",
      user_data: {
        start_date: getYYMMDD(startDate),
        end_date: getYYMMDD(endDate),
      },
    });
    navigate("/payment-reports");
  };

  return (
    <Container>
      <TopBar>
        <TopCover>
          {/* <Arrow onClick={() => goBack()}>
            <img src={left_arrow} alt="image" />
          </Arrow> */}
          <Text>
            <TopTitle>Farmes payments Report </TopTitle>
            <RoutePath>
              <span>Step 1</span> / Step 2 / Step 3
            </RoutePath>
          </Text>
        </TopCover>
        <Curve>
          <img src={curve} alt="" />
        </Curve>
      </TopBar>
      <ReportDate>
        <CoverReport>
          <Title>Proceed payment</Title>
          <Sub>Payments / Proceed payment</Sub>
          <Box>
            <Labels>Choose time period</Labels>
            <CoverInput>
              <CoverLabel>
                <Label>From</Label>
                <Input>
                  <DatePicker
                    value={
                      startDate === "" ? "DD/MM/YYYY" : getDDMMYY(startDate)
                    }
                    onChange={(date) => setStartdate(date)}
                    className="date-pick-payment"
                    required
                    dateFormat="dd-MM-yyyy"
                  />
                  <ImageContainer>
                    <img src={Calender} alt="" />
                  </ImageContainer>
                </Input>
              </CoverLabel>
              <CoverLabel>
                <Label>To </Label>
                <Input>
                  <DatePicker
                    value={endDate === "" ? "DD/MM/YYYY" : getDDMMYY(endDate)}
                    onChange={(date) => setEndDate(date)}
                    className="date-pick-payment"
                    dateFormat="dd-MM-yyyy"
                    minDate={startDate}
                    required
                  />
                  <ImageContainer>
                    <img src={Calender} alt="" />
                  </ImageContainer>
                </Input>
              </CoverLabel>
            </CoverInput>
            <ButtonCard>
              <Button className="cancel" onClick={() => cancelFunction()}>
                Cancel
              </Button>
              <Button onClick={() => dispatchDate()}>Continue</Button>
            </ButtonCard>
          </Box>
        </CoverReport>
      </ReportDate>
    </Container>
  );
}
const Container = styled.div``;
const TopBar = styled.div`
  padding: 20px;
  background-color: #fff;
  position: relative;
  z-index: 1;
  border: 1px solid #dfe8ed;
  margin: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #dfe8ed;
  border-radius: 5px;
`;
const TopCover = styled.div`
  display: flex;
`;
const Arrow = styled.div`
  border: 1px solid #2382d4;
  display: flex;
  cursor: pointer;
  border-radius: 5px;
  padding: 4px;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
`;
const Text = styled.div``;
const TopTitle = styled.h2`
  color: #2382d4;
  text-transform: capitalize;
  margin-bottom: 5px;
`;
const RoutePath = styled.h4`
  color: #747474;
  span {
    color: #2382d4;
  }
`;
const Curve = styled.div`
  position: absolute;
  bottom: 0;
  z-index: -1;
  right: 0;
  img {
    width: 100%;
    display: block;
  }
`;
const CoverInput = styled.div`
  display: flex;
  align-items: center;
  margin-top: 30px;
`;
const CoverLabel = styled.div`
  width: 50%;
  margin-right: 10px;
  &:last-child {
    margin-right: 0;
  }
`;
const Label = styled.h4`
  color: #747474;
  font-family: "inter_light";
  font-weight: unset;
  font-size: 18px;
`;
const Input = styled.div`
  width: 100%;
  position: relative;
  background-color: #f4f5f8;
  padding: 18px 24px;
  margin: 10px 0;
  border-radius: 4px;
  border: 1px solid #dfe8ed;
`;
const ImageContainer = styled.div`
  margin-left: 5px;
  width: 18px;
  position: absolute;
  right: 10px;
  top: 20px;
  img {
    display: block;
    width: 100%;
  }
`;
const Labels = styled.div`
  color: #0a0a0a;
  position: relative;
  overflow: hidden;
  font-size: 22px;
  margin-bottom: 30px;
  &::after {
    content: "";
    position: absolute;
    width: 80%;
    height: 1px;
    background-color: #ebebeb;
    bottom: 10px;
  }
`;

const ReportDate = styled.div`
  margin-top: 100px;
`;
const CoverReport = styled.div`
  width: 50%;
  margin: 0 auto;
  @media (max-width: 1080px) {
    width: 82%;
  }
`;
const Title = styled.h2`
  margin-bottom: 5px;
`;
const Sub = styled.h5`
  color: #747474;
  margin-bottom: 20px;
`;
const Box = styled.div`
  background-color: #fff;
  padding: 40px;
`;
const ButtonCard = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 20px;
  margin-top: 20px;
`;
const Button = styled.div`
  height: 40px;
  text-decoration: none;
  width: 150px;
  font-size: 14px;
  background-color: #2382d4;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &.cancel {
    border: 1px solid #2382d4;
    color: #2382d4;
    background-color: transparent;
  }
`;
