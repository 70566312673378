import React, { useState } from "react";
import styled from "styled-components";
import ButtonLoader from "../../../components/button-loader/ButtonLoader";
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import api from "../../../api";

const RenewModal = ({ isOpen, onClose, data }) => {
	const [currentNumber, setCurrentNumber] = useState("");
	const [retagNumber, setRetagNumber] = useState("");
	const [isButtonLoading, setIsButtonLoading] = useState(false);
	const [isEmiEnabled, setIsEmiEnabled] = useState(false);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isToggled, setIsToggled] = useState(false);
	const [emiCount, setEmiCount] = useState(0);
	const [errorMessage, setErrorMessage] = useState("");
	const [numberOfEMI, setNumberOfEMI] = useState(1);

	const handleEmiToggle = () => {
		setIsEmiEnabled(!isEmiEnabled);
	};
	const onToggle = () => {
		setIsToggled(!isToggled);
		if (!isToggled) {
			setIsModalOpen(true);
		}
	};
	const ModalTabClick = () => {
		setIsModalOpen(true);
	};

	const closeModal = () => {
		setIsModalOpen(false);
	};

	const handleToggle = () => {
		setIsEmiEnabled(!isEmiEnabled);
		if (!isToggled) {
			setIsModalOpen(true);
		}
	};

	const handleWrapperClick = (e) => {
		if (e.target === e.currentTarget) {
			handleClear();
		}
	};

	function preventNonNumericalInput(event) {
        // if ((event.which < 48 || event.which > 57) && event.which !== 13) {
        //     event.preventDefault();
        // }

        // Get the key that was pressed
        const key = event.key;

        // Allow the Enter key (keycode 13)
        if (key === "Enter") {
            return;
        }

        // Allow digits (0-9)
        if (/\d/.test(key)) {
            return;
        }

        // Prevent any other input
        event.preventDefault();
    }

	const handleClear = () => {
		onClose();
		setCurrentNumber("");
		setRetagNumber("");
		setNumberOfEMI(1);
		setErrorMessage("")
	}

	const handleApi = () => {
		api
			.post(
			`activities/create-insurance-for-farmer/`,{
				plant_insurance_id: data.id,
				claim_amount: parseInt(data.claim_amount),
				is_emi: isEmiEnabled.toString().charAt(0).toUpperCase() + isEmiEnabled.toString().slice(1).toLowerCase(),
				...(isEmiEnabled && { no_of_emi: parseInt(numberOfEMI) }),
			})
			.then((res) => {
				// setData(res.app_data.data);
				// setPagination(res.app_data.pagination_data);
				if(res.app_data.StatusCode === 6000){
					setIsButtonLoading(false);
					handleClear();
				}else{
					setErrorMessage(res.app_data.data.message);
					setIsButtonLoading(false);
				}
			})
			.catch((err) => {
				onClose();
				setIsButtonLoading(false);
				setNumberOfEMI("")
				if(numberOfEMI > 0){
					setErrorMessage("Please enter a valid EMI Count");
				}
			});
	}

	const handleConfirm = () => {
    setIsButtonLoading(true);
    setErrorMessage("");
    handleApi();
  };

	return (
		<ModalWrapper isOpen={isOpen} onClick={handleWrapperClick}>
			<ModalContent>
				<h2>Insurance Payment</h2>
				<CoverButton>
					<CoverinButton>
					<Label>
					<CurrencyRupeeIcon style={{ marginRight: '10px' }} />
					
					</Label>
					<Input
					type="text"
					placeholder="2000"
					value={data?.claim_amount}
					disabled
					// onChange={(e) => setCurrentNumber(e.target.value)}
					/>
				</CoverinButton>
				</CoverButton>
				<CoverButtonEmi>
					<Emi>
						<h6>Enable EMI Option</h6>
						<ToggleSwitchWrapper>
							<HiddenCheckbox
								checked={isEmiEnabled}
								onChange={handleEmiToggle}
								onClick={ModalTabClick}
							/>
							<Switch />
						</ToggleSwitchWrapper>
					</Emi>
				</CoverButtonEmi>
				<EmiEnabled enabled={isEmiEnabled}>
					<InputLabelemi>No of EMI</InputLabelemi>
					<Input
						type="text"
						value={numberOfEMI}
						onKeyPress={(e) => preventNonNumericalInput(e)}
						onChange={(e) => {
							setNumberOfEMI(e.target.value);
							setErrorMessage("");
						}}
					/>
				</EmiEnabled>
				{
				errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>

				}
				<Buttons>
					{isButtonLoading ? (
						<Button>
							<ButtonLoader />
						</Button>
					) : (
						<SubmitButton
							type="submit"
							value="Submit"
							onClick={handleConfirm}
						/>
					)}
				</Buttons>
			</ModalContent>
		</ModalWrapper>
	);
};

export default RenewModal;

const ModalWrapper = styled.div`
	display: ${({ isOpen }) => (isOpen ? "block" : "none")};
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 1000;
`;

const ModalContent = styled.div`
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background-color: #fff;
	border-radius: 15px;
	width: 681px;
	height: ${({ isEmiEnabled }) => (isEmiEnabled ? "400px" : "520px")};
	padding:98px;

	h2 {
		color: #0a0a0a;
		font-family: "inter_regular";
		margin-bottom: 30px;
	}

	button {
		display: flex;
		width: 160px;
		padding: 6px 20px;
		justify-content: center;
		align-items: center;
		gap: 6px;
		align-self: stretch;
		border-radius: 4px;
		background: #2382d4;
	}
`;

const CoverButton = styled.div`
position: relative;`;
const CoverinButton = styled.div`
	position: relative;	
	width: 92%;
	margin-right: 20px;
	margin-bottom: 0px;
	&:nth-child(even) {
		margin-right: 0;
	}
	position: relative;
	&.add {
		width: 48%;
		margin-right: 0;
	}
`;
const Label = styled.h3`
	color: #747474;
	font-family: "inter_regular";
	margin-bottom: 35px;
	position: absolute;
	top: 50%;
	left: 10px;
	transform: translateY(-50%);
	color: #000;
	display: flex;
	align-items: center;
`;
const Input = styled.input`
	background-color: #f4f5f8;
	border: 1px solid #dfe8ed;
	font-size: 18px;
	padding-left: 20px;
    width: 110%;
	height: 56px;
	border-radius: 13px;
	padding: 18px 23px;
	padding-left: 45px;

	&.auto {
		color: #adadad;
	}
`;
const Button = styled.div`
	width: 170px;
	color: #fff;
	cursor: pointer;
	border-radius: 5px;
	background-color: #2382d4;
	height: 50px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 35px;
	&.cancel {
		background-color: #fff;
		border: 1px solid #2382d4;
		color: #2382d4;
		margin-right: 20px;
	}
`;
const SubmitButton = styled.input`
	width: 170px;
	color: #fff;
	cursor: pointer;
	border-radius: 5px;
	background-color: #2382d4;
	height: 50px;
	display: flex;
	justify-content: center;
	align-items: center;
	&.cancel {
		background-color: #fff;
		border: 1px solid #2382d4;
		color: #2382d4;
		margin-right: 20px;
	}
`;

const CoverButtonEmi = styled.div`
	display: flex;
	align-items: center; /* Align items vertically */
	gap: 168px;
	margin-top: 39px;
`;

const ErrorMessage = styled.p`
	color: red;
	text-align: end;
	margin-bottom: 5px;
`

const Buttons = styled.div`
	display: flex;
	align-items: flex-end;
	justify-content: flex-end;
`;

const EmiEnabled = styled.div`
	display: ${({ enabled }) => (enabled ? "flex" : "none")};
	align-items: center;
	margin-bottom: 30px; /* Adjust as needed */
	gap: 30px;
`;

const InputLabelemi = styled.h3`
	color: #747474;
	font-family: "inter_regular";
	margin-bottom: 10px;
	white-space: nowrap;
`;

const Emi = styled.div`
	display: flex;
	align-items: center;
	margin-bottom: 50px;
	h6 {
		color: #747474;
		font-family: "inter_regular";
		font-size: 18px;
		margin-right: 16px;
	}
`;

const ToggleSwitchWrapper = styled.label`
	position: relative;
	display: inline-block;
	width: 50px;
	height: 25px;
`;

const HiddenCheckbox = styled.input.attrs({ type: "checkbox" })`
	display: none;
`;

const Switch = styled.span`
	position: absolute;
	cursor: pointer;
	background-color: #fff;
	border-radius: 25px;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	transition: background-color 0.2s ease;
	border: 1px solid #000;

	&:before {
		content: "";
		position: absolute;
		left: 1px;
		top: 1px;
		width: 21px;
		height: 21px;
		background-color: #000;
		border-radius: 50%;
		transition: transform 0.3s ease;
	}

	${HiddenCheckbox}:checked + &::before {
		transform: translateX(25px);
		background-color: #000;
	}

	${HiddenCheckbox}:checked + & {
		background-color: #fff;
	}
`;
