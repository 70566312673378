import React, { useContext, useEffect, useState } from "react";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { Context } from "../../../context/Store";
import { deleteProduct, getProductList } from "../../../api/product";
import Swal from "sweetalert2";
import Loader from "../../../components/loader/Loader";
import NodataFound from "../../includes/nodata/NodataFound";

const ProductList = () => {
  const [modal, setModal] = useState(false);
  const [getData, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [render, setRender] = useState(false);

  const navigate = useNavigate();

  const {
    dispatch,
    state: { user_data },
  } = useContext(Context);
  const token = user_data.access_token;
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const formData = new FormData();
  formData.append("user_id", "13150934-9b57-49d0-8440-6aad266c5fd4");

  const confirmDelete = (item) => {
    Swal.fire({
      title: "Do you want to delete this product?",
      showDenyButton: true,
      confirmButtonText: "Yes",
      denyButtonText: `No`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        handleDeleteProduct(item);
      }
    });
  };
  const handleDeleteProduct = (cartId) => {
    setRender(true);
    deleteProduct(cartId, formData, config).then((res) => {
      if (res.app_data.StatusCode === 6000) {
        setRender(false);
      } else {
        setRender(false);
      }
    });
  };
  useEffect(() => {
    dispatch({
      type: "UPDATE_USER_DATA",
      user_data: {
        active_menu: "products",
      },
    });
  }, []);
  useEffect(() => {
    getProductList(config).then((res) => {
      if (res.app_data.StatusCode === 6000) {
        setData(res.app_data.data);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        setData([]);
      }
    });
  }, [render]);

  return (
    <>
      <TotalContainer>
        <CoverTop>
          <Top>
            <h3>Products</h3>
            <Button to="/add-product">Add Products</Button>
          </Top>
          <CoverB>
            <Bottom>
              <TableHead>
                <TableItem>Sl No.</TableItem>
                <TableItem>Product name</TableItem>
                <TableItem>Image</TableItem>
                <TableItem>Pricing</TableItem>
                <TableItem>Action</TableItem>
              </TableHead>
              <CoverContainer>
                {isLoading ? (
                  <Loader />
                ) : getData.length > 0 ? (
                  getData.map((item, index) => (
                    <TableContainer>
                      <TableItems>{index + 1} </TableItems>
                      <TableItems>{item.name}</TableItems>
                      <TableItems className="image-container">
                        <img src={item.image} alt={item.name} />
                      </TableItems>
                      <TableItems>{item.price}</TableItems>
                      <TableItems>
                        <CoverItems>
                          <Icon
                            className="edit"
                            onClick={() =>
                              navigate("/edit-product", {
                                state: {
                                  product_id: item.id,
                                },
                              })
                            }
                          >
                            <i class="ri-file-edit-fill"></i>
                          </Icon>
                          <Icon
                            onClick={() => {
                              confirmDelete(item.id);
                            }}
                          >
                            <i class="ri-delete-bin-line"></i>
                          </Icon>
                        </CoverItems>
                      </TableItems>
                    </TableContainer>
                  ))
                ) : (
                  <NodataFound />
                )}
              </CoverContainer>
            </Bottom>
          </CoverB>
        </CoverTop>
      </TotalContainer>
    </>
  );
};

export default ProductList;

const CoverTop = styled.div``;
const CoverItems = styled.div`
  display: flex;
  gap: 20px;
`;
const CoverButton = styled.div`
  margin-bottom: 20px;
`;
const TotalContainer = styled.div`
  margin-top: 30px;
  padding: 0px 15px;
  height: calc(100vh - 110px);
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;
const Top = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  h3 {
    font-size: 28px;
  }
`;
const Button = styled(Link)`
  background: #2382d4;
  width: 150px;
  height: 40px;
  border-radius: 8px;
  cursor: pointer;
  text-decoration: none;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Bottom = styled.div`
  padding-top: 15px;
  @media (max-width: 1080px) {
    min-width: 1280px;
    overflow-x: scroll;
  }
`;
const CoverB = styled.div`
  overflow: scroll;
`;
const TableHead = styled.div`
    height: 70px;
    background: #fff;
    padding: 0px 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;
const Icon = styled.div`
    color: #ff0000;
    font-size: 24px;
    cursor: pointer;
    &.edit {
        color: #2382d4;
    }
`;
const TableItem = styled.div`
    width: 10%;
    color: #747474;
    font-size: 18px;
    &:nth-child(2) {
        width: 20%;
    }
`;
const TableItems = styled.div`
  width: 10%;
  color: #000000;
  font-size: 18px;
  &.image-container {
    height: 100%;
    padding: 10px 0;
  }
  img {
    /* width: 100%; */
    height: 100%;
    object-fit: contain;
  }
  &:nth-child(2) {
    width: 20%;
  }
  &.total {
    color: #2382d4;
    cursor: pointer;
  }
`;
const TableContainer = styled.div`
    height: 70px;
    background: #fff;
    padding: 0px 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 15px;
`;
const CoverContainer = styled.div`
    max-height: calc(100vh - 250px);
    overflow: scroll;
`;
const ModalContainer = styled.div`
    width: 45%;
    padding: 60px;
    background: #fff;
    border-radius: 10px;
    text-align: center;
    p {
        margin-bottom: 20px;
        font-weight: 400;
        color: #0a0a0a;
    }
`;
const ButtonNew = styled.button`
    background: #2382d4;
    width: 150px;
    height: 40px;
    border-radius: 8px;
    color: #fff;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    outline: none;
    margin: 0 auto;
`;
