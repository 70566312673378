import React, { useContext, useEffect, useState } from "react";
import { styled } from "styled-components";
import {
  build,
  user,
  plus,
  curve,
  filter_white,
} from "../../../components/assets";
import { Link, useParams } from "react-router-dom";
import { Context } from "../../../context/Store";
import { RouteView } from "../../../api/auth";
import Nodata from "../../includes/nodata/Nodata";
import Loader from "../../../components/loader/Loader";
import { SearchInput } from "../../../components";

function Routs() {
  const [isCreate, setCreate] = useState(false);
  const [fetchData, setFetchData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchedItems, setSearched] = useState("");
  const {
    dispatch,
    state: { user_data },
  } = useContext(Context);
  const token = user_data.access_token;
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  useEffect(() => {
    RouteView(config, searchedItems)
      .then((res) => {
        if (res.app_data.StatusCode == 6000) {
          setFetchData(res.app_data.data);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        setIsLoading(false);
      });
  }, [isCreate, searchedItems]);
  useEffect(() => {
    dispatch({
      type: "UPDATE_USER_DATA",
      user_data: {
        active_menu: "routes",
      },
    });
  }, []);
  const getRouteDetails = (item) => {
    dispatch({
      type: "UPDATE_ROUTE_DATA",
      route_data: {
        route_name: item.name,
        route_code: item.code,
      },
    });
  };
  return isLoading ? (
    <Loader />
  ) : (
    <Container>
      <TopBar>
        <Title>Routes</Title>
        <FilterSection>
          <SearchInput state={searchedItems} setState={setSearched} />
          <Create to="/create-route">
            <Icon>
              <img src={plus} alt="icon" />
            </Icon>
            <span>Create</span>
          </Create>
          {/* <Create>
                <Icon>
                  <img src={filter_white} alt="icon" />
                </Icon>
                <span className="filter">Filter</span>
              </Create> */}
        </FilterSection>
      </TopBar>
      <Cards>
        {fetchData.length == 0 ? (
          <Nodata />
        ) : (
          fetchData?.map((item, index) => (
            <Card
              key={index}
              to={`/routes/${item.id}`}
              onClick={() => getRouteDetails(item)}
            >
              <CoverTop>
                <IdIcon>{index > 8 ? index + 1 : "0" + (index + 1)}</IdIcon>
                <TitleCover>
                  <TitleId>{item.name} </TitleId>
                  <span>Route no : {item.code}</span>
                </TitleCover>
              </CoverTop>
              <Bottom>
                <CardTitle>Average milk quantity</CardTitle>
                <Count>
                  {item.average_milk}
                  <span>Liters</span>
                </Count>
                <Total>
                  <TotalCover>
                    <Icon>
                      <img src={build} alt="image" />
                    </Icon>
                    <Span>Total Societies</Span>
                    <span>{item.total_societies_count} </span>
                  </TotalCover>
                  <TotalCover>
                    <Icon>
                      <img src={user} alt="image" />
                    </Icon>
                    <Span>Total Farmers</Span>
                    <span>{item.total_farmers_count} </span>
                  </TotalCover>
                </Total>
              </Bottom>
              <Curve>
                <img src={curve} alt="image" />
              </Curve>
            </Card>
          ))
        )}
      </Cards>
    </Container>
  );
}

export default Routs;

const Container = styled.div`
  padding: 20px;
  z-index: 1;
  height: calc(100vh - 100px);
  overflow-y: scroll;
`;
const Curve = styled.div`
  position: absolute;
  bottom: 0;
  z-index: -1;
  right: 0;
  img {
    width: 100%;
    display: block;
  }
`;
const TopBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  /* flex-wrap: wrap; */
  gap:10px;
`;
const TitleId = styled.h3`
  color: #0a0a0a;
  font-size: 22px;
`;
const Title = styled.h2`
  color: #0a0a0a;
  font-size: 26px;
  width: 100%;
`;
const FilterSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 30px;
`;
const Create = styled(Link)`
  display: flex;
  text-decoration: none;
  border-radius: 6px;
  justify-content: space-between;
  align-items: center;
  padding: 10px 40px;
  border: 1px solid #7fadd4;
  margin-left: 10px;
  cursor: pointer;
  background-color: #fff;
  /* &:last-child {
    background-color: #2382d4;
    margin-right: 0;
  } */
  span {
    color: #2382d4;
    &.filter {
      color: #fff !important;
    }
  }
`;
const Icon = styled.div`
  margin-right: 5px;
  width: 20px;
  img {
    display: block;
    width: 100%;
  }
`;
const Cards = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
  @media (max-width:780px) {
    display:grid;
    grid-template-columns: repeat(1, 1fr);
    gap:20px;
    
  }
  @media (min-width: 781px)and (max-width:1080px) {
    display:grid;
    grid-template-columns: repeat(2, 1fr);
    gap:20px;
    
  }
`;
const Card = styled(Link)`
  width: 32%;
  text-decoration: none;
  cursor: pointer;
  position: relative;
  background-color: #fff;
  padding: 30px;
  z-index: 1;
  border-radius: 8px;
  border: 1px solid #eeeeee;
  @media (max-width:1080px) {
    width: 100%;

  }
`;
const CoverTop = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 20px;
`;
const IdIcon = styled.div`
  background: #e0f3ff;
  color: #2382d4;
  font-family: "inter_regular";
  border: 1px solid #2382d4;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const TitleCover = styled.div`
  margin-left: 10px;
  /* display: flex; */
  span {
    color: #747474;
    font-family: "inter_regular";
    font-size: 18px;
    margin-top: 5px;
    display: block;
  }
`;
const Bottom = styled.div`
  margin-top: 35px;
`;
const CardTitle = styled.div`
  color: #747474;
  font-family: "inter_regular";
  margin-bottom: 10px;
`;
const Count = styled.h1`
  color: #2382d4;
  margin-bottom: 20px;
  span {
    font-size: 22px;
    margin-left: 10px;
  }
`;
const Total = styled.div`
  display: flex;
  align-items: center;
`;
const TotalCover = styled.div`
  display: flex;
  margin-right: 20px;
  span {
    color: #0a0a0a;
  }
`;
const Span = styled.div`
  color: #747474;
  margin-right: 10px;
`;
