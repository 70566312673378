import React, { useContext, useEffect, useState } from "react";
import { styled } from "styled-components";
import { r_build } from "../../../components/assets";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Context } from "../../../context/Store";
import { EditFarmer, RouteFarmerSingle, deleteFarmer } from "../../../api/auth";
import Swal from "sweetalert2";

function FarmerEdit({ isInputDisabled, setInputDisabled, getFarmerData }) {
  // edit api states
  const [farmerName, setfarmerName] = useState("");
  const [joinDate, setjoinDate] = useState("");
  const [society, setsociety] = useState("");
  const [societyId, setsocietyId] = useState("");
  const [address, setaddress] = useState("");
  const [phoneNumber, setphoneNumber] = useState("");
  const [render, setRender] = useState(false);
  const [bankDetails, setbankDetails] = useState({
    cows: "",
    bonus: "",
    account_number: "",
    ifsc_code: "",
    branch: "",
    password: "",
  });

  const handleToggleInput = () => {
    setInputDisabled(!isInputDisabled);
  };
  const { id } = useParams();
  const navigate = useNavigate();
  const {
    dispatch,
    state: { user_data },
  } = useContext(Context);
  const token = user_data.access_token;
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  useEffect(() => {
    setRender(true);
    RouteFarmerSingle(config, id).then((res) => {
      const data = res.app_data.data;
      if (res.app_data.StatusCode == 6000) {
        setfarmerName(res.app_data.data.farmer);
        setjoinDate(res.app_data.data.join_date);
        setsocietyId(res.app_data.data.society_id);
        setaddress(res.app_data.data.address);
        setsociety(res.app_data.data.society);
        setphoneNumber(res.app_data.data.phone);
        setbankDetails({
          cows: data.number_of_cows,
          bonus: data.bonus_amount,
          account_number: data.account_number,
          ifsc_code: data.ifsc_code,
          branch: data.branch,
          password: data.password,
          bank_name: data.bank_name,
        });
        setRender(false);
      }
    });
  }, [isInputDisabled]);
  //payload
  const formData = new FormData();
  formData.append("name", farmerName);
  formData.append("society", society);
  formData.append("phone_no", phoneNumber);
  formData.append("address", address);

  // formData.append("number_of_cows", bankDetails.cows);
  formData.append("account_number", bankDetails.account_number);
  formData.append("ifsc_code", bankDetails.ifsc_code);
  formData.append("branch", bankDetails.branch);
  formData.append("password", bankDetails.password);
  formData.append("bonus_amount", bankDetails.bonus);
  formData.append("bank_name", bankDetails.bank_name);

  if (joinDate) {
    formData.append("joining_date", joinDate);
  }
  formData.append("society_id", societyId);
  const handleSubmit = () => {
    EditFarmer(config, formData, id).then((res) => {
      if (res.app_data.StatusCode == 6000) {
        window.location.reload();
      }
    });
  };
  const confirmDelete = () => {
    Swal.fire({
      title: "Do you want to delete this farmer?",
      showDenyButton: true,
      confirmButtonText: "Yes",
      denyButtonText: `No`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        deleteFarmerFunction();
      }
    });
  };
  const deleteFarmerFunction = () => {
    deleteFarmer(id, config).then((res) => {
      if (res.app_data.StatusCode == 6000) {
        navigate("/farmers");
      } else {
      }
    });
  };
  return (
    <CoverInfo>
      <RouteTop>
        <TitleSection>
          <IconSection>
            <img src={r_build} alt="image" />
          </IconSection>
          <CoverTitleSec>
            <TitleRout>{getFarmerData?.farmer}</TitleRout>
            <Sub>
              Farmer ID: <span>{getFarmerData?.username}</span>
            </Sub>
          </CoverTitleSec>
        </TitleSection>
        <CoverButton>
          <Button
            onClick={() => {
              handleToggleInput();
              if (!isInputDisabled) {
                handleSubmit();
              }
            }}
          >
            {isInputDisabled ? "Edit" : "Submit"}
          </Button>
          <More onClick={() => confirmDelete()}>
            <i class="ri-delete-bin-line"></i>
          </More>
        </CoverButton>
      </RouteTop>
      <Form>
        <CoverForm>
          <CoverIn>
            <Label>Name*</Label>
            <Input
              type="text"
              disabled={isInputDisabled}
              value={farmerName}
              className={!isInputDisabled && "active"}
              onChange={(e) => setfarmerName(e.target.value)}
            />
          </CoverIn>
          <CoverIn>
            <Label>Join date</Label>
            <Input
              type="date"
              className={!isInputDisabled && "active"}
              disabled={isInputDisabled}
              value={joinDate}
              onChange={(e) => setjoinDate(e.target.value)}
            />
          </CoverIn>
        </CoverForm>
        <CoverForm>
          <CoverIn>
            <Label>Society</Label>
            <Input
              type="text"
              // className={!isInputDisabled && "active"}
              value={society}
              disabled
              onChange={(e) => setsociety(e.target.value)}
            />
          </CoverIn>
          <CoverIn>
            <Label>Society ID*</Label>
            <Input
              type="text"
              // className={!isInputDisabled && "active"}
              value={societyId}
              disabled
              onChange={(e) => setsocietyId(e.target.value)}
              // disabled={isInputDisabled}
            />
          </CoverIn>
        </CoverForm>
        <CoverForm>
          <CoverIn>
            <Label>Address</Label>
            <Input
              type="text"
              className={!isInputDisabled && "active"}
              disabled={isInputDisabled}
              value={address}
              onChange={(e) => setaddress(e.target.value)}
            />
          </CoverIn>
          <CoverIn>
            <Label>Phone No*</Label>
            <Input
              type="number"
              className={!isInputDisabled && "active"}
              disabled={isInputDisabled}
              value={phoneNumber}
              onChange={(e) => setphoneNumber(e.target.value.slice(0,10))}
            />
          </CoverIn>
        </CoverForm>
        {/* <CoverIn>
          <Label>No.of Cows*</Label>
          <Input
            type="number"
            disabled={isInputDisabled}
            value={bankDetails.cows}
            className={!isInputDisabled && "active"}
            onChange={(e) =>
              setbankDetails({
                ...bankDetails,
                cows: e.target.value,
              })
            }
          />
        </CoverIn> */}
        <CoverForm>
          <CoverIn>
            <Label>Bank name*</Label>
            <Input
              type="text"
              disabled={isInputDisabled}
              value={bankDetails.bank_name}
              className={!isInputDisabled && "active"}
              onChange={(e) =>
                setbankDetails({
                  ...bankDetails,
                  bank_name: e.target.value,
                })
              }
            />
          </CoverIn>
          <CoverIn>
            <Label>Bonus Amount</Label>
            <Input
              type="number"
              disabled={isInputDisabled}
              value={bankDetails.bonus_amount}
              className={!isInputDisabled && "active"}
              onChange={(e) =>
                setbankDetails({
                  ...bankDetails,
                  bonus_amount: e.target.value,
                })
              }
            />
          </CoverIn>
        </CoverForm>
        <CoverForm>
          <CoverIn>
            <Label>Account number*</Label>
            <Input
              type="number"
              disabled={isInputDisabled}
              value={bankDetails.account_number}
              className={!isInputDisabled && "active"}
              onChange={(e) =>
                setbankDetails({
                  ...bankDetails,
                  account_number: e.target.value,
                })
              }
            />
          </CoverIn>
          <CoverIn>
            <Label>IFSC code*</Label>
            <Input
              type="text"
              disabled={isInputDisabled}
              value={bankDetails.ifsc_code}
              className={!isInputDisabled && "active"}
              onChange={(e) =>
                setbankDetails({
                  ...bankDetails,
                  ifsc_code: e.target.value,
                })
              }
            />
          </CoverIn>
        </CoverForm>
        <CoverForm>
          <CoverIn>
            <Label>Branch*</Label>
            <Input
              type="text"
              disabled={isInputDisabled}
              value={bankDetails.branch}
              className={!isInputDisabled && "active"}
              onChange={(e) =>
                setbankDetails({
                  ...bankDetails,
                  branch: e.target.value,
                })
              }
            />
          </CoverIn>
          <CoverIn>
            <Label>Password*</Label>
            <Input
              type="text"
              disabled={isInputDisabled}
              value={bankDetails.password}
              className={!isInputDisabled && "active"}
              onChange={(e) =>
                setbankDetails({
                  ...bankDetails,
                  password: e.target.value,
                })
              }
            />
          </CoverIn>
        </CoverForm>
      </Form>
    </CoverInfo>
  );
}

export default FarmerEdit;

const View = styled(Link)`
  color: #2382d4;
  display: flex;
  text-decoration: none;
  cursor: pointer;
  align-items: center;
  position: relative;
  display: inline-block;
  font-size: 20px;
  img {
    margin-left: 5px;
  }
  &::before {
    position: absolute;
    bottom: 0;
    content: "";
    background: #2382d4;
    width: 100%;
    height: 1px;
  }
`;
const RouteTop = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
  flex-wrap: wrap;
  gap: 14px;
`;
const TitleRout = styled.h2`
  font-size: 22px;
  color: #0a0a0a;
`;
const Button = styled.div`
  width: 120px;
  color: #fff;
  cursor: pointer;
  border-radius: 5px;
  background-color: #2382d4;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Form = styled.form``;
const CoverForm = styled.div`
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
`;
const CoverIn = styled.div`
  width: 50%;
  margin-right: 20px;
  margin-bottom: 30px;
  &:nth-child(even) {
    margin-right: 0;
  }
`;
const Label = styled.h3`
  color: #747474;
  font-family: "inter_regular";
  margin-bottom: 10px;
`;
const Input = styled.input`
  width: 100%;
  background-color: #f4f5f8;
  border: 1px solid #dfe8ed;
  height: 60px;
  font-size: 18px;
  padding-left: 20px;
  border-radius: 5px;
  &.active {
    border: 1px solid #00a4ff;
  }
`;
const CoverInfo = styled.div`
  /* width: 65%; */
`;
const TitleSection = styled.div`
  display: flex;
  align-items: center;
`;
const IconSection = styled.div`
  margin-right: 10px;
`;
const CoverTitleSec = styled.div`
  /* display: flex;
  align-items: center; */
`;
const Sub = styled.div`
  color: #747474;
  margin-top: 5px;
  font-family: "inter_regular";
  font-size: 16px;
  span {
    font-family: "inter_medium";
    color: #2382d4;
  }
`;
const CoverButton = styled.div`
  display: flex;
  align-items: center;
`;
const More = styled.div`
  font-size: 28px;
  margin-left: 10px;
  color: #dc3737;
  cursor: pointer;
`;
