import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { getCanDetails, getTotalMilkStatus } from "../../../api/society";
import { Context } from "../../../context/Store";
import Nodata from "../../includes/nodata/Nodata";
import { useParams } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import { getYYMMDD } from "../../../utils/functions/function";
import { plus } from "../../../components/assets";

function CanList({ setAdd }) {
  const [currentDate, setCurrentDate] = useState("");
  const [totalStatus, setTotalStatus] = useState([]);
  const [endDate, setEndDate] = useState("");
  const [startDate, setStartDate] = useState("");
  const [getCanDetailsArray, setCanDetails] = useState([]);
  const {
    dispatch,
    state: { user_data },
  } = useContext(Context);
  const token = user_data.access_token;
  const society_id = user_data.society_id;

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  //get total milk status
  useEffect(() => {
    getCanDetails(config, society_id).then((res) => {
      const data = res.app_data.data;
      if (res.app_data.StatusCode == 6000) {
        setCanDetails(data);
      } else {
        setCanDetails([]);
      }
    });
  }, []);
  return (
    <Container>
      <TopBar>
        <Title>Can details</Title>
        <FilterSection>
          <Dates>{currentDate}</Dates>
          {/* <Time>
            <CoverTime>
              <TimeTab className="date">From</TimeTab>
              <ShowDate>
                <DatePicker
                  value={startDate === "" ? "DD/MM/YYYY" : getYYMMDD(startDate)}
                  //   maxDate={new Date()}
                  onChange={(date) => setStartDate(date)}
                />
              </ShowDate>
            </CoverTime>
            <CoverTime>
              <TimeTab className="date">To</TimeTab>
              <ShowDate>
                <DatePicker
                  value={endDate === "" ? "DD/MM/YYYY" : getYYMMDD(endDate)}
                  //   maxDate={new Date()}
                  onChange={(date) => setEndDate(date)}
                />
              </ShowDate>
            </CoverTime>
            
          </Time> */}
          {/* <Create onClick={() => setAdd(true)}>
            <span>Add Can</span>
          </Create> */}
        </FilterSection>
      </TopBar>
      <div className="parent-scroll">
        <TableSection className="scroll">
          <TopHead>
            <Date>Date</Date>
            <Line></Line>
            <TotalAm className="head">Total Milk Status AM</TotalAm>
            <Line></Line>

            <TotalPm className="head">Total Milk Status PM</TotalPm>
            <Line></Line>

            <TotalMilkStatus className="head">
              Total Milk Status
            </TotalMilkStatus>
            {/* <Line></Line> */}

            {/* <Status className="head">Status</Status> */}
          </TopHead>
          <TopBlackHead>
            <Date></Date>
            <Line></Line>

            <TotalAm>
              <Amount>No.of Cans</Amount>
              <Amount className="sec">Milk Quantity</Amount>
            </TotalAm>
            <Line></Line>

            <TotalPm>
              <Amount>No.of Cans</Amount>
              <Amount className="sec">Milk Quantity</Amount>
            </TotalPm>
            <Line></Line>

            <TotalMilkStatus>
              <Quantity>Total No.of cans</Quantity>
              <Quantity>Total Milk Quantity</Quantity>
            </TotalMilkStatus>
            {/* <Line></Line> */}

            {/* <Status></Status> */}
          </TopBlackHead>
          <CoverScroll>
            {getCanDetailsArray?.length == 0 ? (
              <Nodata />
            ) : (
              getCanDetailsArray.map((a) => (
                <TableValues>
                  <Date>{a.created_at}</Date>
                  <Line></Line>

                  <TotalAm>
                    <Amount>{a.can_status_am.total_number_of_cans}</Amount>
                    <Amount className="sec">
                      {a.can_status_am.total_milk}
                    </Amount>
                  </TotalAm>
                  <Line></Line>

                  <TotalPm>
                    <Amount>{a.can_status_pm.total_number_of_cans}</Amount>
                    <Amount className="sec">
                      {a.can_status_pm.total_milk}
                    </Amount>
                  </TotalPm>
                  <Line></Line>

                  <TotalMilkStatus>
                    <Quantity>{a.total_cans} </Quantity>
                    <Quantity className="er">{a.total_milk}L</Quantity>
                  </TotalMilkStatus>
                  {/* <Line></Line> */}

                  {/* <Status className="value">Closed</Status> */}
                </TableValues>
              ))
            )}
          </CoverScroll>
        </TableSection>
      </div>
    </Container>
  );
}

export default CanList;
const CoverScroll = styled.div`
  max-height: calc(100vh - 334px);
  overflow: scroll;
`;
const Container = styled.div`
  padding: 20px;
`;
const TopBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
`;
const Title = styled.h2`
  color: #0a0a0a;
  /* margin-bottom: 20px; */
  font-size: 26px;
`;
const FilterSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const Dates = styled.div`
  color: #747474;
  font-family: "inter_regular";
  margin-right: 20px;
`;
const Time = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const TimeTab = styled.div`
  color: #747474;
  cursor: pointer;
  border: 1px solid #7fadd4;
  padding: 6px 16px;
  margin-right: 3px;
  &.active {
    background: #2382d4;
    color: #fff;
  }
  &:first-child {
    border-radius: 8px 0 0 8px;
  }
  &:last-child {
    border-radius: 0 8px 8px 0;
    margin-right: 20px;
  }
  &.date {
    border-radius: 8px;
    background: #2382d4;
    color: #fff;
  }
`;

const TableSection = styled.div`
  background-color: #fff;
  margin-top: 30px;
  border-radius: 5px;
`;
const TopHead = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 75px;
  padding: 0 20px;
`;
const Date = styled.div`
  width: 100px;
`;
const TotalAm = styled.div`
  display: flex;
  justify-content: space-between;
  width: 20%;
  &.head {
    justify-content: center;
  }
`;
const TotalPm = styled.div`
  display: flex;
  width: 20%;
  justify-content: space-between;
  &.head {
    justify-content: center;
  }
`;
const TotalMilkStatus = styled.div`
  display: flex;
  justify-content: space-between;
  width: 25%;
  &.head {
    justify-content: center;
  }
`;
const Line = styled.div`
  height: 100px;
  width: 1px;
  background-color: #dcecec;
`;
const Status = styled.div`
  width: 10%;
  display: flex;
  justify-content: flex-end;
  &.value {
    color: #74a45c;
  }
`;
const TopBlackHead = styled.div`
  background-color: #080c24;
  display: flex;
  justify-content: space-between;
  color: #fff;
  height: 50px;
  align-items: center;
  padding: 0 20px;
`;
const Fat = styled.div`
  width: 13.33%;
`;
const DamagedMilk = styled.div`
  width: 26%;
`;
const Amount = styled.div`
  width: 50%;
  font-family: "inter_bold";
  &.sec {
    text-align: right;
  }
`;
const Quantity = styled.div`
  &.er {
    font-family: "inter_bold";
  }
`;
const TableValues = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid #dcecec;
  height: 80px;
  padding: 0 20px;
  justify-content: space-between;
`;
const CoverTime = styled.div`
  display: flex;
  align-items: center;
  margin-right: 10px;
  &:last-child {
    margin-right: 0;
  }
`;
const ShowDate = styled.div`
  background-color: #fff;
  padding: 6px 16px;
  border-radius: 8px;
  margin-left: 5px;
`;
const Create = styled.div`
  display: flex;
  border-radius: 6px;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  padding: 10px 40px;
  border: 1px solid #7fadd4;
  margin-right: 10px;
  cursor: pointer;
  background-color: #fff;
  &:last-child {
    background-color: #2382d4;
    margin-right: 0;
  }
  span {
    color: #fff !important;
    &.filter {
    }
  }
`;
const Icon = styled.div`
  margin-right: 5px;
  width: 20px;
  img {
    display: block;
    width: 100%;
  }
`;
