import React, { useContext, useEffect, useState } from "react";
import { add } from "../../../components/assets";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Context } from "../../../context/Store";
import styled from "styled-components";
import Loader from "../../../components/loader/Loader";
import NodataFound from "../../includes/nodata/NodataFound";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import api from "../../../api";
import RenewModal from "./Modal";

function Payment() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const type = queryParams.get("id");
  const [isRenewalModalOpen, setIsRenewalModalOpen] = useState(false); // State to manage modal visibility
  const [getData, setData] = useState("");
  const [selectedInsurance, setSelectedInsurance] = useState("");

  const navigate = useNavigate();
  const handlePaymentTermsClick = (id) => {
    navigate(`/payment-terms/${id}`);
  };
  const [isLoading, setIsLoading] = useState(true);
  const [pagination, setPagination] = useState();
  const [currentPage, setCurrentPage] = useState(1);

  const [active, setActive] = useState("");
  const [getInsuranceID, setInsuranceID] = useState("");
  
  // Accessing user data from context
  const {
    dispatch,
    state: { user_data },
  } = useContext(Context);

  const openRenewalModal = (item) => {
    setSelectedInsurance(item);
    setIsRenewalModalOpen(true);
  };

  const handleInsurance = () => {
    api
      .get(
        `activities/list-insurance/`)
      .then((res) => {
        setData(res.app_data.data);
        setPagination(res.app_data.pagination_data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
    // }
  };

  useEffect(() => {
    handleInsurance();
    // Updating user data in context
    dispatch({
      type: "UPDATE_USER_DATA",
      user_data: {
        active_sub_menu: "payment",
      },
    });
  }, []);

  return (
    <Container>
    <Top>
      <h3>Insurance Payments</h3>
    </Top>
    <Bottom>
      <TableHead>
        <TableItem>Farmer Id</TableItem>
        <TableItem>Insurance ID</TableItem>
        <TableItem>Farmer Name</TableItem>
        <TableItem>Tag No</TableItem>
        <TableItem>Amount</TableItem>
        <TableItem>Action</TableItem>
      </TableHead>
      <CoverContainer>
        {
          isLoading ?
          <Loader />
          :
          getData.length > 0 ? (
            getData.map((item, index) => (
              <TableContent key={index}>
                <TableItem>{item.farmer_id}</TableItem>
                <TableItem>{item.code}</TableItem>
                <TableItem>{item.farmer_name}</TableItem>
                <TableItem>{item.tag_no}</TableItem>
                <TableItem>{item.claim_amount}</TableItem>
                <TableItem>
                  <PaymentTermsButton onClick={() => openRenewalModal(item)}>Payment Terms</PaymentTermsButton>
                </TableItem>
              </TableContent>
            ))
          ) : (
            <NodataFound />
          )
        }
      </CoverContainer>
    </Bottom>
    <RenewModal
      data={selectedInsurance}
      isOpen={isRenewalModalOpen}
      onClose={() => setIsRenewalModalOpen(false)}
    />
  </Container>
  );
}

export default Payment;


const Container = styled.div`
  margin-top: 20px;
  padding: 0px 15px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;

const Top = styled.div`
  margin: 0px;
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Button = styled.div`
  width: 175px;
  height: 40px;
  background: #fff;
  color: #2382d4;
  align-items: center;
  display: flex;
  justify-content: center;
  border: 1px solid #2382d4;
  border-radius: 4px;
  font-size: 14px;
  padding: 6px 16px;
  cursor: pointer;

  &.background {
    background-color: #2382d4;
    color: #fff;
    margin-left: 15px;
  }

  img {
    margin-right: 10px;
  }
`;

const Tabs = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const Tab = styled(Link)`
  border-top: 1px solid #dfe8ed;
  text-decoration: none;
  border-left: 1px solid #dfe8ed;
  border-right: 1px solid #dfe8ed;
  border-bottom: 1px solid #dfe8ed;
  padding: 14px 20px;
  cursor: pointer;
  margin-right: 30px;
  color: #797d82;
  border-radius: 5px 5px 0 0;

  &.active {
    color: #2382d4;
    background-color: #e0f3ff;
    border-top: 1px solid #2382d4;
    border-left: 1px solid #2382d4;
    border-right: 1px solid #2382d4;
    border-bottom: 3px solid #2382d4;
  }
`;

const Bottom = styled.div`
  padding-top: 15px;
`;
const TableHead = styled.div`
  height: 70px;
  background: #fff;
  padding: 0 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const TableItems = styled.div`
  width: 10%;
  color: #000;
  font-size: 18px;
`;

const TableContainer = styled.div`
  height: 70px;
  background: #fff;
  padding: 0 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;
`;

const CoverContainer = styled.div`
  max-height: calc(100vh - 250px);
  overflow: auto;
`;

const TableContent = styled.div`
  height: 70px;
  background: #fff;
  padding: 0 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
`;

const EditIcon = styled.div`
  color: #747474;
  font-size: 24px;
  cursor: pointer;
  padding: 0;
  margin-right: 10px;
  border-radius: 5px;
  height: 29px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const DeleteIcon = styled.div`
  color: #747474;
  font-size: 24px;
  cursor: pointer;
  padding: 0;
  margin-right: 10px;
  border-radius: 5px;
  height: 29px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ViewIcon = styled(Link)`
  color: #747474;
  font-size: 24px;
  cursor: pointer;
  padding: 0;
  margin-right: 10px;
  border-radius: 5px;
  height: 29px;
  display: flex;
  align-items: pxenter;
  justify-content: center;
`;

const TableItem = styled.div`
  color: #747474;
  font-size: 15px;
  display: flex;
  font-family: "inter_regular";
  align-items: center;
  width: 10%;
  white-space: nowrap;
`;

const PaymentTermsButton = styled.button`
  background-color: #2382d4;
  color: #fff;
  border: none;
  padding: 8px 12px;
  cursor: pointer;
  font-size: 14px;
  border-radius:12px;
`;

