import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { add } from "../../../../components/assets";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Loader from "../../../../components/loader/Loader";
import NodataFound from "../../../includes/nodata/NodataFound";
import RenewModal from "./Modal";
import { Context } from "../../../../context/Store";
import api from "../../../../api";
import Swal from "sweetalert2";

function Renewal() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const type = queryParams.get("renewal");
  const navigate = useNavigate();
  const [getData, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [render, setRender] = useState(true);

  const [active, setActive] = useState(type || "all");
  const [isRenewalModalOpen, setIsRenewalModalOpen] = useState(false);
  const openRenewalModal = () => setIsRenewalModalOpen(true);
  const closeRenewalModal = () => setIsRenewalModalOpen(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [renewalId, setRenewalId] = useState("");
  const [renewType, setRenewType] = useState("");
  const {
    dispatch,
    state: { user_data },
  } = useContext(Context);

  // get accesstoken from store
  const token = user_data.access_token;

  useEffect(() => {
    dispatch({
      type: "UPDATE_USER_DATA",
      user_data: {
        active_menu: "Renewal",
      },
    });
  }, []);
  const ModalTabClick = () => {
    // Open the modal when the "New Tag" tab is clicked
    setIsModalOpen(true);
  };

  const handleTabClick = (tab) => {
    setActive(tab);
    switch (tab) {
      case "all":
        break;
      case "cancelled":
        break;
      default:
        break;
    }
  };

  const confirmCancel = (item) => {
    setRender(true);
    Swal.fire({
      title: "Are you sure to proceed?",
      showDenyButton: true,
      confirmButtonText: "Yes",
      denyButtonText: `No`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        cancelInsurance(item);
      }
    });
  };
  //cancel insrance
  const cancelInsurance = (item) => {
    api
      .post(`activities/cancel-plant-insurance/${item.id}/`, {})
      .then((res) => {
        if (res.app_data.StatusCode == 6000) {
          setRender(false);
          handleRenewal();
        } else {
        }
      });
  };

  const handleRenewal = () => {
    api
    .get(
      `activities/to-be-renew-plant-insurances/`,
      {
        params: {
          renewed_type: active == "renewed" ? "renewed" : "",
        },
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((res) => {
      if (res.app_data.StatusCode === 6000) {
        setData(res.app_data.data);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        setData([]);
      }
    });
  }
  //renewal insurance listing
  useEffect(() => {
    handleRenewal();
  }, [active]);

  return (
    <Container>
      <Top>
        <h3>Renewal</h3>
        {/* <Button onClick={() => navigate("/add-insurance")}>
          <img src={add} alt="renewal" />
          Add Insurance
        </Button> */}
      </Top>

      <Tabs>
        <Tab
          to=""
          onClick={() => handleTabClick("all")}
          className={active === "all" && "active"}
        >
          All
        </Tab>
        <Tab
          to=""
          onClick={() => handleTabClick("renewed")}
          className={active === "renewed" && "active"}
        >
          Renewed
        </Tab>
      </Tabs>
      <div className="parent-scroll">
        <Bottom className="scroll">
          <TableHead>
            <TableItem>Sl No.</TableItem>
            <TableItem>Insurance ID</TableItem>
            <TableItem>Farmer name</TableItem>
            <TableItem>Tag No</TableItem>
            <TableItem>Society Name</TableItem>
            <TableItem>Date of Insurance</TableItem>
            <TableItem>Action</TableItem>
          </TableHead>
          <CoverContainer>
            {isLoading ? (
              <Loader />
            ) : getData.length > 0 ? (
              getData.map((item, index) => (
                <TableContent key={index}>
                  <TableItem>{index + 1}</TableItem>
                  <TableItem>{item.code ? item.code : "--"}</TableItem>
                  <TableItem>{item.farmer_name}</TableItem>
                  <TableItem>{item.tag_no}</TableItem>
                  <TableItem>{item.society_name}</TableItem>
                  <TableItem>{item.date_of_insurance}</TableItem>
                  <TableItem>
                    <ViewIcon onClick={() => confirmCancel(item)}>
                      <button>Cancel</button>
                    </ViewIcon>
                    <EditIcon
                      onClick={() =>
                        navigate({
                          state: {
                            insurance_id: item.insuranceId,
                          },
                        })
                      }
                    >
                      <button
                        onClick={() => {
                          ModalTabClick();
                          setRenewalId(item.id);
                        }}
                      >
                        Renewal
                      </button>
                    </EditIcon>
                    {
                      renewalId === item.id &&
                      <RenewModal
                        isOpen={isModalOpen}
                        onClose={() => setIsModalOpen(false)}
                        renewalId={renewalId}
                        itemData={item}
                      />
                    }
                  </TableItem>
                </TableContent>
              ))
            ) : (
              <NodataFound />
            )}
          </CoverContainer>
        </Bottom>
      </div>
    </Container>
  );
}

export default Renewal;

const Container = styled.div`
  margin-top: 20px;
  padding: 0px 15px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;

const Top = styled.div`
  margin: 0px;
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Button = styled.div`
  width: 175px;
  height: 40px;
  background: #fff;
  color: #2382d4;
  align-items: center;
  display: flex;
  justify-content: center;
  border: 1px solid #2382d4;
  border-radius: 4px;
  font-size: 14px;
  padding: 6px 16px;
  cursor: pointer;

  &.background {
    background-color: #2382d4;
    color: #fff;
    margin-left: 15px;
  }

  img {
    margin-right: 10px;
  }
`;

const Tabs = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top:20px;

`;

const Tab = styled(Link)`
  border-top: 1px solid #dfe8ed;
  text-decoration: none;
  border-left: 1px solid #dfe8ed;
  border-right: 1px solid #dfe8ed;
  border-bottom: 1px solid #dfe8ed;
  padding: 14px 20px;
  cursor: pointer;
  margin-right: 30px;
  color: #797d82;
  border-radius: 5px 5px 0 0;

  &.active {
    color: #2382d4;
    background-color: #e0f3ff;
    border-top: 1px solid #2382d4;
    border-left: 1px solid #2382d4;
    border-right: 1px solid #2382d4;
    border-bottom: 3px solid #2382d4;
  }
`;

const Bottom = styled.div`
  padding-top: 15px;
`;
const TableHead = styled.div`
  height: 70px;
  background: #fff;
  padding: 0 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const TableItems = styled.div`
  width: 10%;
  color: #000;
  font-size: 18px;
`;

const TableContainer = styled.div`
  height: 70px;
  background: #fff;
  padding: 0 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;
`;

const CoverContainer = styled.div`
  max-height: calc(100vh - 250px);
  overflow: auto;
`;

const TableContent = styled.div`
  height: 70px;
  background: #fff;
  padding: 0 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
`;

const EditIcon = styled.div`
  color: #747474;
  font-size: 24px;
  cursor: pointer;
  padding: 0;
  margin-right: 10px;
  border-radius: 5px;
  height: 29px;
  display: flex;
  align-items: center;
  justify-content: center;
  button {
    width: 63px;
    height: 29px;
    flex-shrink: 0;
    border-radius: 5px;
    border: 1px solid #bfbfbf;
    background: #fff;
    color: #747474;
  }
`;

const ViewIcon = styled.div`
  color: #747474;
  font-size: 24px;
  cursor: pointer;
  padding: 0;
  margin-right: 10px;
  border-radius: 5px;
  height: 29px;
  display: flex;
  align-items: center;
  justify-content: center;
  button {
    width: 63px;
    height: 29px;
    flex-shrink: 0;
    border-radius: 5px;
    cursor: pointer;
    border: 1px solid #bfbfbf;
    background: #fff;
    color: #747474;
  }
`;

const TableItem = styled.div`
	color: #747474;
	font-size: 15px;
	display: flex;
	align-items: center;
	width: 10%;
	white-space: nowrap;
`;
const RenewalModal = styled.div``;
