import React, { useContext, useEffect, useState } from "react";
import { styled } from "styled-components";
import { milk, white_curved } from "../../../components/assets";
import { CreateLocalSale, PricePerLitreLocalSale } from "../../../api/society";
import { Context } from "../../../context/Store";
import ButtonLoader from "../../../components/button-loader/ButtonLoader";
import { editPricePerLitre, getPricePerLitre } from "../../../api/activities";

function AddLocalSale() {
  const [receivedMilk, setReceivedMilk] = useState();
  const [isEdit, setEdit] = useState(false);
  const [switchButton, setSwitchButton] = useState(false);
  const [totalPrice, setTotalPrice] = useState();
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [price, setPrice] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [getPrice, setPricePerLitre] = useState("");
  const [getPriceId, setPriceId] = useState("");
  const [username, setName] = useState("");
  const [number, setNumber] = useState("");

  const {
    dispatch,
    state: { user_data },
  } = useContext(Context);
  const token = user_data.access_token;

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  //price per litre stored in Store
  const storedPrice = user_data.price_per_litre;

  const formData = new FormData();
  formData.append("recieved_milk", receivedMilk);
  formData.append("price_per_litre", storedPrice);
  formData.append("name", username);
  formData.append("phone", number);

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsButtonLoading(true);
    if (!receivedMilk) {
      setErrorMessage("Please enter milk quantity");
      setIsButtonLoading(false);
    } else {
      CreateLocalSale(config, formData).then((res) => {
        if (res.app_data.StatusCode == 6000) {
          setIsButtonLoading(false);
          setSwitchButton(true);
          setTotalPrice(res.app_data.data.total_price);
          setErrorMessage("");
        } else {
          setIsButtonLoading(false);
          setSwitchButton(false);
          setErrorMessage("");
        }
      });
    }
  };

  //clear Form
  const clearFormData = (e) => {
    e.preventDefault();
    setReceivedMilk("");
    setTotalPrice("");
    setName("");
    setNumber("");
    setSwitchButton(false);
  };

  //create price per litre
  const pricePerMilkFormData = new FormData();
  pricePerMilkFormData.append("price_per_liter", getPrice);
  const handlePriceperLitre = () => {
    PricePerLitreLocalSale(config, pricePerMilkFormData).then((res) => {
      if (res.app_data.StatusCode == 6000) {
        const fetched_price = res.app_data.data.price_per_liter;
        dispatch({
          type: "UPDATE_USER_DATA",
          user_data: {
            price_per_litre: fetched_price,
          },
        });
        setPrice("");
        setEdit(true);
      } else {
      }
    });
  };

  //get price per litre
  useEffect(() => {
    !isEdit &&
      getPricePerLitre(config)
        .then((res) => {
          const data = res.app_data.data;
          if (res.app_data.StatusCode == 6000) {
            setPricePerLitre(data.price_per_liter);
            setPriceId(data.price_per_id);
            setEdit(false);
          } else {
            setEdit(true);
          }
        })
        .catch((err) => {});
  }, [getPrice]);

  //edit price per litre
  const priceFormData = new FormData();
  priceFormData.append("price_per_litre", parseInt(getPrice));

  const handleEditPrice = (e) => {
    e.preventDefault();
    isEdit &&
      editPricePerLitre(getPriceId, priceFormData, config).then((res) => {
        if (res.app_data.StatusCode == 6000) {
          setEdit(false);
        } else {
        }
      });
  };
  return (
    <MainContainer>
      <CoverSale>
        <Title>Local Sale</Title>
        <Container
          onSubmit={(e) => {
            !switchButton && handleSubmit(e);
            switchButton && clearFormData(e);
          }}
        >
          <Card>
            <TopCard>
              <Icon>
                <img src={milk} alt="" />
              </Icon>
              <Text>Milk quantity</Text>
            </TopCard>
            <CoverInput>
              <Inputs
                type="number"
                placeholder="00"
                value={receivedMilk}
                onChange={(e) => setReceivedMilk(e.target.value)}
                required
              />
              <span>Litres</span>
            </CoverInput>
            <Curve>
              <img src={white_curved} alt="img" />
            </Curve>
          </Card>
          <CoverBottomPlan>
            <SocietyBox>
              <Label>Name*</Label>
              <input
                type="text"
                placeholder="Name"
                value={username}
                required
                onChange={(e) => setName(e.target.value)}
              />
            </SocietyBox>
            <SocietyBox>
              <Label>Phone*</Label>
              <input
                type="number"
                placeholder="Phone"
                required
                value={number}
                onChange={(e) => setNumber(e.target.value.slice(0,10))}
              />
            </SocietyBox>
          </CoverBottomPlan>
          <Bottom>
            <TotalCover>
              <Values>
                <CoverBottom>
                  <Value>Quantity</Value>
                  <Count>{receivedMilk ? receivedMilk : "0"}</Count>
                </CoverBottom>
                <CoverBottom>
                  <Value>Price per liters</Value>
                  <CountInput
                    type="number"
                    value={getPrice}
                    // onChange={(e) => setPriceperMilk(e.target.value)}
                    disabled
                  />
                </CoverBottom>
              </Values>
              <Total>
                <Value>Total price</Value>
                <TotalValue>{totalPrice ? totalPrice : 0}</TotalValue>
              </Total>
              {/* <ErrorMessage>{errorMessage}</ErrorMessage> */}
              {isButtonLoading ? (
                <Submits>
                  <ButtonLoader />
                </Submits>
              ) : (
                <SubmitButton
                  type="Submit"
                  value={switchButton ? "Submit" : "Calculate"}
                />
              )}
            </TotalCover>
          </Bottom>
        </Container>
      </CoverSale>
    </MainContainer>
  );
}

export default AddLocalSale;
const MainContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10px;
  gap: 10px;
  height: calc(100vh - 130px);
  overflow: scroll;
  /* align-items: center; */
  @media (max-width: 1080px) {
    margin-left: 15px;
  }
`;
const Label = styled.h3`
  color: #747474;
  font-family: "inter_regular";
  font-size: 14px;
`;
const SocietyBox = styled.div`
  margin-top: 20px;
  input {
    padding: 18px 24px;
    border: 1px solid #dfe8ed;
    border-radius: 4px;
    width: 100%;
    margin: 10px 0;
    font-size: 18px;
    font-family: "inter_light";
    &.id {
      text-transform: capitalize;
    }
  }
`;
const CoverSale = styled.div`
  margin-right: 20px;
  &.milk {
    margin-right: 0;
    width: 30%;
  }
`;
const IconEdit = styled.div`
  color: #2382d4;
  width: 36px;
  cursor: pointer;
  margin-left: 20px;
  font-size: 26px;
`;
const Container = styled.form`
  background-color: #fff;
  padding: 60px;
  border-radius: 5px;
  border: 1px solid #dfe8ed;
`;
const Title = styled.h2`
  margin-bottom: 10px;
`;
const Card = styled.div`
  position: relative;
  background-color: #f4f5f8;
  border: 1px solid #dfe8ed;
  padding: 20px;
  border-radius: 5px;
  z-index: 1;
  span {
    color: #747474;
    font-family: "inter_regular";
  }
`;
const Curve = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
  img {
    display: block;
    width: 100%;
  }
`;
const TopCard = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 30px;
`;
const CoverInput = styled.div`
  display: flex;
  align-items: baseline;
`;
const Icon = styled.div`
  width: 40px;
  margin-right: 10px;
  img {
    display: block;
    width: 100%;
  }
`;
const Text = styled.h4`
  color: #747474;
  font-family: "inter_regular";
`;
const CountInput = styled.input`
  width: 70px;
  height: 40px;
  font-size: 22px;
  text-align: right;
  border-bottom: 1px solid grey;
`;
const Inputs = styled.input`
  border-bottom: 1px solid #dfe8ed;
  color: #0a0a0a;

  background-color: #f4f5f8;
  font-size: 30px;
  width: 100%;
  &::placeholder {
    font-size: 16px;
    font-family: "inter_light";
  }
`;
const Inputse = styled.input`
  border-bottom: 1px solid #2382d4;
  color: #0a0a0a;

  background-color: #f4f5f8;
  font-size: 30px;
  width: 100%;
  &::placeholder {
    font-size: 16px;
    font-family: "inter_light";
  }
`;
const Bottom = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: column;
  margin-top: 30px;
`;
const Values = styled.div``;
const TotalCover = styled.div`
  position: relative;
`;
const CoverBottom = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;
const Value = styled.h4`
  color: #747474;
  font-family: "inter_regular";
`;
const Count = styled.h3`
  color: #0a0a0a;
`;
const Total = styled.div`
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
  align-self: center;
  margin-bottom: 60px;
  position: relative;

  &::before {
    position: absolute;
    content: "";
    height: 1px;
    width: 550px;
    background-color: #eeeeee;
    top: -20px;
    right: 0;
  }
`;
const CoverBottomPlan = styled.div`
  display: flex;
  align-items: center;
  gap: 50px;
  @media (max-width: 768px) {
    gap: 0;
    flex-direction: column;
    align-items: flex-start;
  }
`;
const TotalValue = styled.h3`
  color: #2382d4;
`;
const Submits = styled.div`
  background-color: #2382d4;
  color: #fff;
  width: 300px;
  height: 50px;
  float: right;
  cursor: pointer;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Submit = styled.input`
  background-color: ${({ type }) => (type ? " #2382d4" : " #393939")};
  color: #fff;
  width: 300px;
  height: 50px;
  float: right;
  cursor: pointer;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  &.submit {
    background-color: #2382d4;
    margin-top: 30px;
    width: 150px;
    float: none;
    height: 35px;
    font-size: 14px;
  }
`;
const SubmitButton = styled.input`
  background-color: ${({ type }) => (type ? " #2382d4" : " #393939")};
  color: #fff;
  width: 300px;
  height: 50px;
  float: right;
  cursor: pointer;
  border-radius: 5px;
  text-align: center;
  &.submit {
    background-color: #2382d4;
    margin-top: 30px;
    width: 150px;
    float: none;
    height: 35px;
    font-size: 14px;
  }
`;

const ErrorMessage = styled.span`
  color: red;
  position: absolute;
  bottom: 50px;
  right: 0;
  width: 300px;
  text-align: right;
`;
