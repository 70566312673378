import React, { useState } from "react";
import { styled } from "styled-components";
import RectangleButton from "../../../components/Buttons/RectangleButton";
import RectangleColorButton from "../../../components/Buttons/RectangleColorButton";
import { RoundPlus } from "../../../components/assets";
import { DropDown } from "../../../components/assets/icons";
import { useNavigate } from "react-router-dom";

function UserCreated() {
    const [isChecked, setIsChecked] = useState(false);
    const navigate = useNavigate();
  
    const handleClick = () => {
      setIsChecked(!isChecked);
    };

    return (
        <Container>
            <GeneralBox>
                <TopBox>
                    <Heading>User ID Created Successfully</Heading>
                </TopBox>
                <SingleBox>
                    <Label>User Name</Label>
                    <InputBox>
                        <input type="text" placeholder="Place Value" />
                    </InputBox>
                </SingleBox>
                <SingleBox>
                    <Label>Password Generated</Label>
                    <InputBox>
                        <input type="text" placeholder="Place Value" />
                    </InputBox>
                </SingleBox>
                <SubmitButton onClick={() => navigate('/sub-user')}>Next</SubmitButton>
            </GeneralBox>
        </Container>
    );
}
export default UserCreated;

const Container = styled.div`
    width: 60%;
    margin: 70px auto;
`;

const GeneralBox = styled.div`
    background-color: #fff;
    width: 100%;
    padding: 60px 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const TopBox = styled.div`
    margin-bottom: 20px;
`;

const Heading = styled.h2`
    color: #0a0a0a;
    font-size: 28px;
`;

const SingleBox = styled.div`
    width: 100%;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;

    input {
        background-color: transparent;
        width: 100%;
        font-size: 18px;
    }
`;

const InputBox = styled.div`
    background-color: #fafafa;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 4px 24px;
    margin: 10px 0;
    border-radius: 4px;
    width: 375px;

    input{
        font-family: 'inter_light';
        font-size: 20px;
        text-align: center;
    }
`;

const Label = styled.h4`
    color: #747474;
    font-family: "inter_light";
    font-weight: unset;
    font-size: 18px;
    `;

const MiniBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    `;

const TagName = styled.h6`
    font-size: 12px;
    font-family: "inter_light";
    padding: 4px 12px;
    margin-right: 10px;
`;

const TagButton = styled.span`
    font-size: 12px;  
    background-color: #2382D4;
    color: #fff;
    display: inline-flex;
    align-items: center;
    padding: 4px 12px;
    border-radius: 8px;
    height: 34px;
    margin-right: 10px;
    font-family: "inter_light";

    &:last-child{
      margin-right: 0px;
    }
`;

const SubmitButton = styled.div`
    height: 38px;
    width: 160px;
    margin-top: 32px;
    padding: 6px 20px;
    border-radius: 4px;
    background-color: #2382D4;
    color: #fff;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "inter_light";
`;