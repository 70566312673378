import React, { useContext, useEffect, useState } from "react";
import { styled } from "styled-components";
import { Card, LineChartGraph } from "../../../components";
import {
  clr,
  curve,
  dropdown,
  fat,
  filter,
  milk,
  print,
  snf,
} from "../../../components/assets";
import BarChartGraph from "../../../components/chart/BarChartGrapfh";
import { Outlet } from "react-router-dom";
import {
  DashboardCount,
  SocietyQualityGraphData,
  SocietyQuantityGraphData,
} from "../../../api/society";
import { Context } from "../../../context/Store";
import Loader from "../../../components/loader/Loader";

function SocietyStatics() {
  const [totalCount, setTotalCount] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [graphData, setGraphData] = useState({});
  const [qualityGraphData, setQualityGraphdata] = useState();

  const [isDropDown, setDropDown] = useState("");
  const [filterData, setFilterData] = useState("This Week");
  const [qualityFilter, setQualityFilter] = useState("This Week");
  const [filterValues, setFilterValues] = useState("");
  const [QualityFilterValues, setQualityFilterValues] = useState("");

  const {
    dispatch,
    state: { user_data },
  } = useContext(Context);
  const token = user_data.access_token;
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const quantity = "quantity";
  const quality = "quality";
  const filter_keys = [
    {
      id: 1,
      title: "This Month",
      value: "30",
    },
    {
      id: 2,
      title: "This Week",
      value: "10",
    },
  ];
  const handleFiter = (item) => {
    setFilterData(item.title);
    setDropDown(null);
  };
  const handleQualityFiter = (item) => {
    setQualityFilter(item.title);
    setDropDown(null);
  };
  const handleDropDown = (item) => {
    if (isDropDown) {
      setDropDown("");
    } else {
      setDropDown(item);
    }
  };
  useEffect(() => {
    DashboardCount(config)
      .then((res) => {
        if (res.app_data.StatusCode == 6000) {
          setTotalCount(res.app_data.data);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        setIsLoading(false);
      });
    setTimeout(() => setIsLoading(false), 1100);
  }, []);
  useEffect(() => {
    dispatch({
      type: "UPDATE_USER_DATA",
      user_data: {
        active_menu: "dashboard",
      },
    });
  }, []);
  // ------------graph datas--------------------
  useEffect(() => {
    SocietyQuantityGraphData(config, filterValues).then((res) => {
      setGraphData(res.data);
    });
    SocietyQualityGraphData(config, QualityFilterValues).then((res) => {
      setQualityGraphdata(res.data);
    });
  }, [filterValues, QualityFilterValues]);
  return isLoading ? (
    <Loader />
  ) : (
    <div>
      <Container>
        <TopBar>
          <Title>Dashboard</Title>
        </TopBar>
        <BottomBar>
          <CoverCard>
            <Card
              title="Average milk quantity"
              icon={milk}
              number={totalCount?.average_milk_quantity.toFixed(2)}
              label="Litres"
              path=""
            />
            <Card
              title="Average local sales"
              icon={milk}
              number={totalCount?.average_local_sale.toFixed(2)}
              label="Litres"
              path="local-sales"
            />
            <Card
              title="Total farmers"
              icon={milk}
              number={totalCount?.total_farmers_count}
              label="Farmers"
              path="farmers"
            />
          </CoverCard>
          <TotalValue>
            <TotalCard>
              <Icon>
                <img src={clr} alt="image" />
              </Icon>
              <Span>Total CLR value</Span>
              <Count>{totalCount?.average_clr_value.toFixed(2)}</Count>
              <Curve>
                <img src={curve} alt="image" />
              </Curve>
            </TotalCard>
            <TotalCard>
              <Icon>
                <img src={fat} alt="image" />
              </Icon>
              <Span>Total FAT value</Span>
              <Count>{totalCount?.average_fat_value.toFixed(2)}</Count>
              <Curve>
                <img src={curve} alt="image" />
              </Curve>
            </TotalCard>
            <TotalCard>
              <Icon>
                <img src={snf} alt="image" />
              </Icon>
              <Span>Total SNF value</Span>
              <Count>{totalCount?.average_snf_value.toFixed(2)}</Count>
              <Curve>
                <img src={curve} alt="image" />
              </Curve>
            </TotalCard>
          </TotalValue>
        </BottomBar>
        <GraphContainer>
          <Cover>
            <TitleCover>
              <TitleGraph>Quantity Analysis</TitleGraph>
              <Drop onClick={() => handleDropDown(quantity)}>
                {filterData}
                <Icon>
                  <img src={dropdown} alt="icon" />
                </Icon>
                {isDropDown == "quantity" && (
                  <DropDown>
                    {filter_keys.map((item, index) => (
                      <Fil
                        key={index}
                        onClick={() => {
                          handleFiter(item);
                          setFilterValues(item.value);
                        }}
                      >
                        {item.title}
                      </Fil>
                    ))}
                  </DropDown>
                )}
              </Drop>
            </TitleCover>

            <LineChartGraph graphData={graphData} />
          </Cover>
          <Cover>
            <TitleCover>
              <TitleGraph>Quality Analysis</TitleGraph>
              <Drop onClick={() => handleDropDown(quality)}>
                {qualityFilter}
                <Icon>
                  <img src={dropdown} alt="icon" />
                </Icon>
                {isDropDown == "quality" && (
                  <DropDown>
                    {filter_keys.map((item, index) => (
                      <Fil
                        key={index}
                        onClick={() => {
                          handleQualityFiter(item);
                          setQualityFilterValues(item.value);
                        }}
                      >
                        {item.title}
                      </Fil>
                    ))}
                  </DropDown>
                )}
              </Drop>
            </TitleCover>
            <BarChartGraph qualityGraphData={qualityGraphData} />
          </Cover>
        </GraphContainer>
      </Container>
      <Outlet />
    </div>
  );
}

export default SocietyStatics;

const Container = styled.div`
  padding: 20px;
  /* height: calc(100vh - 100px);
  overflow-y: scroll; */
`;
const TopBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const Title = styled.h2`
  color: #0a0a0a;
  /* margin-bottom: 20px; */
  font-size: 26px;
`;
const DropDown = styled.div`
  background-color: #e0f3ff;
  border-radius: 5px;
  position: absolute;
  width: 120px;
  top: 30px;
`;
const Fil = styled.div`
  padding: 10px 20px;
  border-bottom: 1px solid #c4c4c4;
  cursor: pointer;
  font-size: 13px;
  &:last-child {
    border-bottom: 1px solid transparent;
  }
  transition: 0.4s ease;
  &:hover {
    background-color: #2382d4;
    color: #fff;
    transition: 0.4s ease;
  }
`;
const FilterSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const Date = styled.div`
  color: #747474;
  font-family: "inter_regular";
  margin-right: 20px;
`;
const Time = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const TimeTab = styled.div`
  color: #747474;
  cursor: pointer;
  border: 1px solid #7fadd4;
  padding: 6px 16px;
  margin-right: 3px;
  &:first-child {
    border-radius: 8px 0 0 8px;
  }
  &:last-child {
    border-radius: 0 8px 8px 0;
    margin-right: 20px;
  }
`;
const Switch = styled.div`
  display: flex;
  border-radius: 8px;
  justify-content: space-between;
  align-items: center;
  padding: 6px 16px;
  border: 1px solid #7fadd4;
  margin-right: 10px;
  cursor: pointer;
  &:last-child {
    margin-right: 0;
  }
`;
const SwitchTitle = styled.div`
  color: #2382d4;
`;
const BottomBar = styled.div`
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  margin-top: 20px;
  @media (max-width:1080px) {
    display:grid;
    grid-template-columns: repeat(1, 1fr);
    gap:20px;
  }
`;
const CoverCard = styled.div`
  display: flex;
  width: 70%;
  @media (min-width: 980px)and (max-width:1080px) {
    display:grid;
    grid-template-columns: repeat(3, 1fr);
    gap:20px;
    width: 100%;
  }
  @media (max-width:979px) {
    display:grid;
    grid-template-columns: repeat(2, 1fr);
    gap:20px;
    width: 100%;
  }
  @media (max-width:640px) {
    display:grid;
    grid-template-columns: repeat(1, 1fr);
    gap:20px;
    width: 100%;
  }
`;
const TotalValue = styled.div`
  width: 30%;
  @media (max-width:1080px) {
    width: 100%;
  }
`;
const TotalCard = styled.div`
  display: flex;
  border: 1px solid #dfe8ed;
  z-index: 1;
  border-radius: 8px;
  padding: 10px 20px;
  background-color: #fff;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin-bottom: 10px;
  margin-left: 20px;
  &:last-child {
    margin-bottom: 0;
  }
  @media (max-width:1080px) {
    margin-left: 0px;
  }
`;
const Icon = styled.div`
  img {
    display: block;
    width: 100%;
  }
`;
const Span = styled.div`
  color: #0a0a0a;
`;
const Curve = styled.div`
  position: absolute;
  width: 20%;
  right: 0;
  z-index: -1;
  bottom: 0;
  img {
    display: block;
    width: 100%;
  }
`;
const Count = styled.h1`
  color: #2382d4;
`;
const GraphContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 30px 0 0;
  @media (max-width:980px) {
    flex-direction:column;
    gap: 40px;
    height:100%;
  }
`;
const Cover = styled.div`
  background-color: #fff;
  margin-right: 20px;
  padding: 20px;
  width: 49%;
  &:last-child {
    margin-right: 0;
  }
  @media (max-width:980px) {
    width: 99%;
    height:100%;
    margin-right: 8px;
  }
`;
const TitleCover = styled.h3`
  color: #0a0a0a;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  box-sizing: border-box;
`;
const TitleGraph = styled.div``;
const Drop = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #0a0a0a;
  font-family: "inter_regular";
  font-size: 14px;
`;
