import React from "react";
import Lottie from "react-lottie";
import styled from "styled-components";
import loaderAnimation from "../../components/assets/json/animation_lkntyz36.json";

export default function SectionLoader() {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loaderAnimation,
    rendererSettings: {},
  };
  return (
    <Container>
      <Lottie options={defaultOptions} height={1000} width={1000} />
    </Container>
  );
}
const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
`;
