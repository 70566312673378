import React, { useContext, useEffect, useState } from "react";
import { styled } from "styled-components";
import { action, curve, left_arrow, plus } from "../../../../components/assets";
import { routs_societies } from "../../../../utils/arrays";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Context } from "../../../../context/Store";
import CreateSociety from "../../creation/CreateSociety";
import { RouteSingleEdit, RouteSocieties } from "../../../../api/auth";
import Nodata from "../../../includes/nodata/Nodata";
import Loader from "../../../../components/loader/Loader";

function RouteSocities() {
  const [isActive, setActive] = useState("all");
  const [getData, setGetData] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };
  const [isAdd, setAdd] = useState(false);
  const {
    dispatch,
    state: { user_data, route_data },
  } = useContext(Context);
  const { id } = useParams();
  const token = user_data.access_token;
  const route_code = route_data.route_code;

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const params = isActive;

  useEffect(() => {
    RouteSocieties(config, id, params).then((res) => {
      if (res.app_data.StatusCode == 6000) {
        setIsLoading(false);
        setGetData(res.app_data.data);
      } else {
        setIsLoading(false);
        setGetData([]);
      }
    });
  }, [isActive, isAdd]);
  return (
    <MainContainer>
      {isAdd ? (
        <CreateSociety type="route" id={id} setAdd={setAdd} />
      ) : (
        <>
          <TopBar>
            <TopCover>
              <Arrow onClick={goBack}>
                <img src={left_arrow} alt="image" />
              </Arrow>
              <Text>
                <TopTitle>Societies</TopTitle>
                <RoutePath>Routes / Route no : {route_code}/ Societies</RoutePath>
              </Text>
            </TopCover>
            <Add onClick={() => setAdd(true)}>
              <Icon>
                <img src={plus} alt="icon" />
              </Icon>
              <span>Add New Societies</span>
            </Add>
            <Curve>
              <img src={curve} alt="" />
            </Curve>
          </TopBar>
          <CoverLi>
            <SocietyList>
              <Tabs>
                <Tab
                  onClick={() => setActive("all")}
                  className={isActive === "all" && "active"}
                >
                  All
                </Tab>
                <Tab
                  onClick={() => setActive("jeeva_society")}
                  className={isActive === "jeeva_society" && "active"}
                >
                  Jeeva societies
                </Tab>
                <Tab
                  onClick={() => setActive("private_society")}
                  className={isActive === "private_society" && "active"}
                >
                  Private societies
                </Tab>
                <Tab
                  onClick={() => setActive("farm")}
                  className={isActive === "farm" && "active"}
                >
                  Farms
                </Tab>
              </Tabs>

              <ListLabels>
                <LabelName type="all">Sl No</LabelName>
                <LabelName type="id">Society ID</LabelName>
                <LabelName type="soc">Society Name</LabelName>
                <LabelName type="soc">President </LabelName>
                <LabelName type="loc">Location</LabelName>
                <LabelName type="no">Society Phone no</LabelName>
                <LabelName type="milk">Average milk </LabelName>
              </ListLabels>
              {isLoading ? (
                <Loader />
              ) : getData?.length == 0 ? (
                <Nodata />
              ) : (
                getData?.map((item, index) => (
                  <List
                    key={index}
                    to={`/routes/${"001"}/societies/${item.id}`}
                  >
                    <Item type="all">
                      <Serial>{index + 1}</Serial>
                    </Item>
                    <Item type="id">
                      <Id>{item.code}</Id>
                    </Item>
                    <Item type="soc">
                      <span>{item.name}</span>
                    </Item>
                    <Item type="soc">
                      <CoverItem>
                        <CoverName>
                          <Name>{item.president_name}</Name>
                          <Phone>{item.president_phone}</Phone>
                        </CoverName>
                      </CoverItem>
                    </Item>
                    <Item type="loc">
                      <span>{item.location}</span>
                    </Item>
                    <Item type="no">
                      <Span>{item.phone}</Span>
                    </Item>
                    <Item type="milk">
                      <Span>{item.average_milk}</Span>
                    </Item>
                  </List>
                ))
              )}
            </SocietyList>
          </CoverLi>
        </>
      )}
    </MainContainer>
  );
}

export default RouteSocities;
const MainContainer = styled.div`
  padding: 20px;
  max-height: calc(100vh - 100px);
  overflow: scroll;
  overflow-x: hidden;
`;
const CoverLi = styled.div`
  overflow: scroll;
`;
const TopBar = styled.div`
  padding: 20px;
  background-color: #fff;
  position: relative;
  border: 1px solid #dfe8ed;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #dfe8ed;
  border-radius: 5px;
`;
const TopCover = styled.div`
  display: flex;
`;
const Arrow = styled.div`
  border: 1px solid #2382d4;
  display: flex;
  cursor: pointer;
  border-radius: 5px;
  padding: 4px;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
`;
const Text = styled.div``;
const TopTitle = styled.h2`
  color: #2382d4;

  &.create {
    width: 70%;
    margin: 0 auto;
    margin-bottom: 10px;
    color: #0a0a0a;
  }
`;
const RoutePath = styled.h4`
  color: #747474;
`;
const Curve = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  img {
    width: 100%;
    display: block;
  }
`;
const Add = styled.div`
  background-color: #fff;
  border: 1px solid #2382d4;
  color: #2382d4;
  /* width: 120px; */
  height: 35px;
  display: flex;
  padding: 20px;
  border-radius: 5px;
  cursor: pointer;
  z-index: 1;
  justify-content: space-between;
  align-items: center;
`;
const Icon = styled.div`
  margin-right: 10px;
  img {
    display: block;
  }
`;

const SocietyList = styled.div`
  background-color: #fff;
  padding: 20px;
  border: 1px solid #dfe8ed;
  border-radius: 5px;
  @media (max-width: 1080px) {
    min-width: 1280px;
    overflow-x: scroll;
  }
`;
const Tab = styled.div`
  border-top: 1px solid #dfe8ed;
  border-left: 1px solid #dfe8ed;
  border-right: 1px solid #dfe8ed;
  border-bottom: 3px solid transparent;
  padding: 14px 20px;
  cursor: pointer;
  margin-right: 30px;
  color: #797d82;
  border-radius: 5px 5px 0 0;
  &.active {
    color: #2382d4;
    background-color: #e0f3ff;
    border-top: 1px solid #2382d4;
    border-left: 1px solid #2382d4;
    border-right: 1px solid #2382d4;
    border-bottom: 3px solid #2382d4;
  }
`;
const Tabs = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
const Item = styled.div`
  /* width: 20%; */
  width: 140px;
  font-size: 16px;
  color: #2e3032;
  span {
    color: #2e3032;
  }
  width: ${({ type }) =>
    type === "all"
      ? "45px"
      : type === "id"
      ? "90px"
      : type === "soc"
      ? "180px"
      : type === "loc"
      ? "200px"
      : type === "no"
      ? "150px"
      : type === "milk"
      ? "110px"
      : type === "action"
      ? "90px"
      : ""};
`;
const LabelName = styled.h4`
  color: #797d82;
  font-family: "inter_regular";
  /* width: 20%; */
  width: ${({ type }) =>
    type === "all"
      ? "45px"
      : type === "id"
      ? "90px"
      : type === "soc"
      ? "170px"
      : type === "loc"
      ? "200px"
      : type === "no"
      ? "150px"
      : type === "milk"
      ? "110px"
      : type === "action"
      ? "90px"
      : ""};
`;
const ListLabels = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border-bottom: 1px solid #dfe8ed;
  border-top: 1px solid #dfe8ed;
`;
const List = styled(Link)`
  padding: 20px;
  text-decoration: none;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #dfe8ed;
  align-items: center;
  cursor: pointer;
`;
const Serial = styled.h4`
  color: #2e3032;
`;
const Id = styled.h4`
  color: #2382d4;
`;
const CoverItem = styled.div`
  display: flex;
  align-items: center;
`;
const Iconz = styled.div`
  background-color: #e0f3ff;
  color: #2382d4;
  padding: 15px;
  font-family: "inter_medium";
  border-radius: 50%;
  margin-right: 10px;
`;
const CoverName = styled.div``;
const Name = styled.h4`
  color: #2e3032;
  font-size: 14px;
  margin-bottom: 5px;
`;
const Phone = styled.h4`
  font-size: 14px;
  color: #808080;
`;
const Span = styled.div``;
const Icons = styled.div``;
