import React, { useContext, useEffect, useState } from "react";
import { styled } from "styled-components";
import "react-datepicker/dist/react-datepicker.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Context } from "../../../../context/Store";
import { ViewLoanData } from "../../../../api/auth";
import api from "../../../../api";
import { curve, left_arrow } from "../../../../components/assets";
import { getYYMMDD } from "../../../../utils/functions/function";
import Loader from "../../../../components/loader/Loader";
import Nodata from "../../../includes/nodata/Nodata";
import ButtonLoader from "../../../../components/button-loader/ButtonLoader";
import Swal from "sweetalert2";

function CreateCard(prop) {
  const [pagination, setPagination] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [getLoanDetails, setLoanDetails] = useState([]);
  const [societyName, setSocietyName] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isButtonLoading, setButtonLoading] = useState(false);
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };
  function handlePageClick({ selected: selectedPage }) {
    setCurrentPage(selectedPage + 1);
  }
  const startIndex = (currentPage - 1) * 8;
  const {
    dispatch,
    state: { user_data },
  } = useContext(Context);

  const { id } = useParams();
  const token = user_data.access_token;
  useEffect(() => {
    dispatch({
      type: "UPDATE_USER_DATA",
      user_data: {
        active_menu: "Loans",
      },
    });
  }, []);

  const confirmDelete = (item) => {
    Swal.fire({
      title: "Are you sure to proceed?",
      showDenyButton: true,
      confirmButtonText: "Yes",
      denyButtonText: `No`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        proceedtoPay(item);
      }
    });
  };
  //payment listing
  useEffect(() => {
    api
      .get(`activities/loan-details/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.app_data.StatusCode == 6000) {
          setLoanDetails(res.app_data.data);
          setIsLoading(false);
          setSocietyName(res.app_data.data[0]);
        } else {
          setLoanDetails([]);
        }
      });
  }, [isButtonLoading]);

  //proceed to pay
  const proceedtoPay = (obj) => {
    setButtonLoading(true);
    api
      .post(`activities/mark-loan-payed/${obj.id}/`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        if (res.app_data.StatusCode == 6000) {
          setButtonLoading(false);
        } else {
          setButtonLoading(false);
        }
      });
  };
  return (
    <MainContainer>
      <>
        <TopBar>
          <TopCover>
            <Arrow onClick={() => goBack()}>
              <img src={left_arrow} alt="image" />
            </Arrow>
            <Text>
              <TopTitle>{societyName?.society_details?.name} </TopTitle>
              <RoutePath>
                Loans / <span>Society single loans </span>
              </RoutePath>
            </Text>
          </TopCover>
          <Curve>
            <img src={curve} alt="" />
          </Curve>
        </TopBar>
        <SocietyList>
          <CoverList>
            <ListLabels>
              <LabelName type="all">Sl No.</LabelName>
              <LabelName type="id"> Particular</LabelName>
              <LabelName type="id"> EMI amount</LabelName>
              <LabelName type="soc">Pending</LabelName>
              <LabelName type="soc">Created Date</LabelName>
              <LabelName type="soc">Repayment date</LabelName>
              <LabelName type="soc">Status </LabelName>
            </ListLabels>
            {isLoading ? (
              <Loader />
            ) : getLoanDetails?.length == 0 ? (
              <Nodata />
            ) : (
              getLoanDetails?.map((obj, index) => (
                <CoverItems>
                  <List key={index}>
                    <Item type="all">
                      <Serial>{index + 1}</Serial>
                    </Item>
                    <Item type="id">
                      <Id>{obj.particular}</Id>
                    </Item>
                    <Item type="soc">
                      <span>{obj.amount}</span>
                    </Item>
                    <Item type="loc">
                      <span>{obj.balance}</span>
                    </Item>
                    <Item type="loc">
                      <span>{obj.created_at}</span>
                    </Item>
                    <Item type="loc">
                      <span>{obj.repayment_date}</span>
                    </Item>
                    <Item type="no">
                      <Span>
                        {obj.paid ? (
                          <span>Paid</span>
                        ) : (
                          <Proceed
                            className="status"
                            onClick={() => confirmDelete(obj)}
                          >
                            Proceed to pay
                          </Proceed>
                        )}
                      </Span>
                    </Item>
                  </List>
                </CoverItems>
              ))
            )}
          </CoverList>
        </SocietyList>
      </>
      {/* {pagination && pagination.total_pages > 1 && getSocieties.length > 0 && (
        <PaginationContainer>
          <PaginationText>
            Showing Societies : {pagination.first_item} - {pagination.last_item}{" "}
            of {pagination.total_items} Societies
          </PaginationText>
          <ReactPaginate
            previousLabel={"<"}
            nextLabel={">"}
            pageCount={pagination.total_pages}
            onPageChange={handlePageClick}
            containerClassName={"pagination"}
            previousLinkClassName={"pagination__link"}
            nextLinkClassName={"pagination__link"}
            disabledClassName={"pagination__link--disabled"}
            activeClassName={"pagination__link--active"}
          />
        </PaginationContainer>
      )} */}
    </MainContainer>
  );
}

export default CreateCard;
const MainContainer = styled.div`
  padding: 20px;
  max-height: calc(100vh - 100px);
  overflow: scroll;
  overflow-x: hidden;
`;
const TopBar = styled.div`
  padding: 20px;
  background-color: #fff;
  position: relative;
  z-index: 1;
  border: 1px solid #dfe8ed;
  margin: 10px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #dfe8ed;
  border-radius: 5px;
`;
const TopCover = styled.div`
  display: flex;
  /* justify-content: space-between; */
  width: 100%;
`;
const Arrow = styled.div`
  border: 1px solid #2382d4;
  display: flex;
  cursor: pointer;
  border-radius: 5px;
  padding: 4px;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
`;
const Text = styled.div``;
const SearchCover = styled.div`
  display: flex;
`;
const TopTitle = styled.h2`
  margin-bottom: 10px;
  &.create {
    width: 70%;
    margin: 0 auto;
    margin-bottom: 10px;
    color: #0a0a0a;
  }
`;
const RoutePath = styled.h4`
  color: #747474;
  span {
    color: #2382d4;
  }
`;
const Curve = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  img {
    width: 100%;
    display: block;
  }
`;
const Add = styled.div`
  background-color: #fff;
  border: 1px solid #2382d4;
  color: #2382d4;
  /* width: 120px; */
  height: 35px;
  display: flex;
  padding: 20px;
  border-radius: 5px;
  cursor: pointer;
  z-index: 1;
  justify-content: space-between;
  align-items: center;
  margin-right: 10px;
`;
const Icon = styled.div`
  margin-right: 10px;
  img {
    display: block;
  }
`;

const SocietyList = styled.div`
  background-color: #fff;
  padding: 20px;
  border: 1px solid #dfe8ed;
  border-radius: 5px;
  /* height: calc(100vh - 290px); */
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-direction: column;
`;
const CoverList = styled.div`
  width: 100%;
`;
const Proceed = styled.div`
  height: 40px;
  text-decoration: none;
  width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #2382d4;
  color: #fff;
  font-size: 14px;
  cursor: pointer;
  &.status {
    width: 150px;
  }
`;

const Tab = styled(Link)`
  border-top: 1px solid #dfe8ed;
  text-decoration: none;
  border-left: 1px solid #dfe8ed;
  border-right: 1px solid #dfe8ed;
  border-bottom: 3px solid transparent;
  padding: 14px 20px;
  cursor: pointer;
  margin-right: 30px;
  color: #797d82;
  border-radius: 5px 5px 0 0;
  &.active {
    color: #2382d4;
    background-color: #e0f3ff;
    border-top: 1px solid #2382d4;
    border-left: 1px solid #2382d4;
    border-right: 1px solid #2382d4;
    border-bottom: 3px solid #2382d4;
  }
`;
const SocietyName = styled.div`
  padding: 20px;
  background: #f4f5f8;
`;
const Item = styled.div`
  width: 150px;
  font-size: 14px;
  color: #2e3032;
  span {
    color: #2e3032;
  }
  &.active {
    i {
      color: #74d874;
    }
  }
  i {
    margin-left: 10px;
    font-size: 16px;
  }
`;
const LabelName = styled.h4`
  color: #797d82;
  font-family: "inter_regular";
  width: 150px;
`;
const ListLabels = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border-bottom: 1px solid #dfe8ed;
`;
const List = styled(Link)`
  padding: 20px;
  text-decoration: none;
  display: flex;
  text-decoration: none;
  justify-content: space-between;
  border-bottom: 1px solid #dfe8ed;
  align-items: center;
  cursor: pointer;
`;
const Serial = styled.h4`
  color: #2e3032;
`;
const Id = styled.h4`
  color: #2382d4;
`;
const CoverItem = styled.div`
  display: flex;
  align-items: center;
`;
const Iconz = styled.div`
  background-color: #e0f3ff;
  color: #2382d4;
  font-size: 12px;
  padding: 10px;
  font-family: "inter_medium";
  border-radius: 50%;
  margin-right: 10px;
`;
const CoverName = styled.div``;
const Name = styled.h4`
  color: #2e3032;
  font-size: 12px;
  margin-bottom: 5px;
`;
const Phone = styled.h4`
  font-size: 12px;
  color: #808080;
`;
const Span = styled.div`
  span {
    color: #70a059;
  }
`;
const Icons = styled.div``;

const PaginationContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0 15px;
  @media all and (max-width: 1380px) {
    padding: 15px 0px;
    margin-right: 40px;
  }
  @media all and (max-width: 1280px) {
    margin-right: 20px;
    padding: 20px 0px;
  }
  @media all and (max-width: 980px) {
    margin-right: 10px;
  }
  @media all and (max-width: 890px) {
    flex-direction: column;
  }
`;
const PaginationText = styled.p`
  color: #898989;
  font-size: 12px;
  @media all and (max-width: 890px) {
    margin-bottom: 10px;
  }
`;
const TopRight = styled.div`
  display: flex;
  gap: 5px;
  display: flex;
`;
const ButContainer = styled.div`
  padding: 5px 8px;
  background: #2382d4;
  border-radius: 8px;
  color: #fff;
  font-size: 14px;
  font-weight: 400;
`;
const CalenderDiv = styled.div`
  background: #fff;
  padding: 5px 8px;
  border-radius: 8px;
  position: relative;
  input {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
  }
`;
const TimeSect = styled.div`
  border-radius: 8px;
  /* overflow:hidden; */
  /* height:25px; */
  display: flex;
  gap: 3px;
`;
const SelectButton = styled.div`
  padding: 5px 8px;
  /* background:#fff; */
  border: 0.5px solid #7fadd4;
  /* background:#2382D4; */
  /* height:40px; */
  /* border-radius:8px; */
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
  color: #000;
  &.left {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }
  &.active {
    background: #2382d4;
    color: #fff;
  }
  &.right {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }
`;
const CoverItems = styled.div``;
const Bottom = styled.div`
  margin-top: 20px;
  background: #fff;
  /* padding:20px; */
`;
const TableHead = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px;
`;
const TableItem = styled.div`
  width: 6%;
  text-align: center;
  color: #747474;
  font-weight: 400;
  /* background:green; */
  &.num {
    /* width:5%; */
    /* width:8%; */
  }
  &.time {
    /* width:5%; */
  }
`;
const TableBottom = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px;
  /* background:red; */
`;
const TableBottomItem = styled.div`
  width: 6%;
  text-align: center;
  font-size: 15px;
  /* background:blue; */
`;
const FilterHead = styled.div`
  width: 100%;
  height: 80px;
  background: #00000033;
  padding: 20px;
  display: flex;
  align-items: center;
  h3: {
    font-size: 18px;
  }
`;
const FilterContainer = styled.div`
  position: absolute;
  width: 300px;
  /* height:500px; */
  background: #fff;
  z-index: 10;
  right: 50px;
  top: 40px;
  padding: 20px;
  transform: ${({ filterModal }) => (filterModal ? "scale(1)" : "scale(0)")};
  transition: 0.4s ease;
  transform-origin: top right;
`;
const FilterTop = styled.div`
  font-size: 33px;
  color: #2382d4;
  text-align: center;
`;
const FilterBottom = styled.div``;
const FilterItem = styled.div`
  display: flex;
  gap: 5px;
  padding: 10px 0px;
`;
const FilterButtons = styled.div`
  display: flex;
  justify-content: space-between;
`;
const FilterApply = styled.button`
  background: #2382d4;
  border: none;
  outline: none;
  width: 120px;
  height: 40px;
  radius: 4px;
  color: #fff;
`;
