import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { Context } from "../../../../context/Store";
import { getTotalMilkStatus } from "../../../../api/society";
import Nodata from "../../../includes/nodata/Nodata";
import { useParams } from "react-router-dom";

import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import { getDDMMYY, getYYMMDD } from "../../../../utils/functions/function";

function MilkStatusDetail() {
  const [currentDate, setCurrentDate] = useState("");
  const [totalStatus, setTotalStatus] = useState([]);
  const [endDate, setEndDate] = useState("");
  const [startDate, setStartDate] = useState("");

  const {
    state: { user_data },
  } = useContext(Context);
  const token = user_data.access_token;

  const { id } = useParams();

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const filter_tabs = [
    {
      id: 1,
      title: "All",
      value: "all",
    },
    {
      id: 1,
      title: "AM",
      value: "am",
    },
    {
      id: 1,
      title: "PM",
      value: "pm",
    },
  ];
  //get total milk status

  useEffect(() => {
    getTotalMilkStatus(config, id, startDate, endDate).then((res) => {
      const data = res.app_data.data;
      if (res.app_data.StatusCode == 6000) {
        setTotalStatus(data);
        setCurrentDate(res.app_data.current_date);
      } else {
        setTotalStatus([]);
      }
    });
  }, [endDate]);
  return (
    <Container>
      <TopBar>
        <Title>Total Milk status</Title>
        <FilterSection>
          <Dates>{currentDate}</Dates>
          <Time>
            <CoverTime>
              <TimeTab className="date">From</TimeTab>
              <ShowDate>
                <DatePicker
                  value={startDate === "" ? "DD/MM/YYYY" : getDDMMYY(startDate)}
                  //   maxDate={new Date()}
                  onChange={(date) => setStartDate(date)}
                />
              </ShowDate>
            </CoverTime>
            <CoverTime>
              <TimeTab className="date">To</TimeTab>
              <ShowDate>
                <DatePicker
                  value={endDate === "" ? "DD/MM/YYYY" : getDDMMYY(endDate)}
                  //   maxDate={new Date()}
                  onChange={(date) => setEndDate(date)}
                />
              </ShowDate>
            </CoverTime>
          </Time>
        </FilterSection>
      </TopBar>
      <TableSection>
        <TopHead>
          <Date>Date</Date>
          <Line></Line>
          <TotalAm className="head">Total Milk Status AM</TotalAm>
          <Line></Line>

          <TotalPm className="head">Total Milk Status PM</TotalPm>
          <Line></Line>

          <TotalMilkStatus className="head">Total Milk Status</TotalMilkStatus>
          <Line></Line>

          <Status className="head">Status</Status>
        </TopHead>
        <TopBlackHead>
          <Date></Date>
          <Line></Line>

          <TotalAm>
            <Can>Milk Quantity</Can>
            <Quantity>Spoiled Milk</Quantity>
          </TotalAm>
          <Line></Line>

          <TotalPm>
            {/* <Fat>Fat</Fat>
            <Fat>Clr</Fat>
            <Fat>Snf</Fat>
            <Fat>Milk</Fat>
            <DamagedMilk>Damaged</DamagedMilk>
            <Amount>Amount</Amount> */}
            <Can>Milk Quantity</Can>
            <Quantity>Spoiled Milk </Quantity>
          </TotalPm>
          <Line></Line>

          <TotalMilkStatus>
            {/* <Quantity>Amount</Quantity> */}
            <Can>Milk Quantity</Can>
            <Quantity>Spoiled Milk</Quantity>
          </TotalMilkStatus>
          <Line></Line>

          <Status></Status>
        </TopBlackHead>
        <CoverScroll>
          {totalStatus?.length == 0 ? (
            <Nodata />
          ) : (
            totalStatus.map((a) => (
              <TableValues>
                <Date>{a.created_at}</Date>
                <Line></Line>

                <TotalAm>
                  <Can>{a.milk_status_am.total_milk}</Can>
                  <Quantity>{a.milk_status_am.spoiled_milk} </Quantity>
                </TotalAm>
                <Line></Line>

                <TotalPm>
                  <Can>{a.milk_status_pm.total_milk}</Can>
                  <Quantity>{a.milk_status_pm.spoiled_milk} </Quantity>
                </TotalPm>
                <Line></Line>

                <TotalMilkStatus>
                  <Can>{a.total_milk}</Can>
                  <Quantity>{a.total_spoiled_milk} </Quantity>
                </TotalMilkStatus>
                <Line></Line>

                <Status className="value">Closed</Status>
              </TableValues>
            ))
          )}
        </CoverScroll>
      </TableSection>
    </Container>
  );
}

export default MilkStatusDetail;
const CoverScroll = styled.div`
  max-height: calc(100vh - 334px);
  overflow: scroll;
`;
const Container = styled.div`
  padding: 20px;
`;
const TopBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
`;
const Title = styled.h2`
  color: #0a0a0a;
  /* margin-bottom: 20px; */
  font-size: 26px;
`;
const FilterSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const Dates = styled.div`
  color: #747474;
  font-family: "inter_regular";
  margin-right: 20px;
`;
const Time = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const TimeTab = styled.div`
  color: #747474;
  cursor: pointer;
  border: 1px solid #7fadd4;
  padding: 6px 16px;
  margin-right: 3px;
  &.active {
    background: #2382d4;
    color: #fff;
  }
  &:first-child {
    border-radius: 8px 0 0 8px;
  }
  &:last-child {
    border-radius: 0 8px 8px 0;
    margin-right: 20px;
  }
  &.date {
    border-radius: 8px;
    background: #2382d4;
    color: #fff;
  }
`;

const TableSection = styled.div`
  background-color: #fff;
  margin-top: 30px;
  border-radius: 5px;
`;
const TopHead = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 75px;
  padding: 0 20px;
`;
const Date = styled.div`
  width: 100px;
`;
const TotalAm = styled.div`
  display: flex;
  justify-content: space-between;
  width: 30%;
  &.head {
    justify-content: center;
  }
`;
const TotalPm = styled.div`
  display: flex;
  width: 30%;
  justify-content: space-between;
  &.head {
    justify-content: center;
  }
`;
const TotalMilkStatus = styled.div`
  display: flex;
  justify-content: space-around;
  width: 20%;
  &.head {
    justify-content: space-around;
  }
`;
const Line = styled.div`
  height: 100px;
  width: 1px;
  background-color: #dcecec;
`;
const Status = styled.div`
  width: 10%;
  display: flex;
  justify-content: flex-end;
  &.value {
    color: #74a45c;
  }
`;
const TopBlackHead = styled.div`
  background-color: #080c24;
  display: flex;
  justify-content: space-between;
  color: #fff;
  height: 50px;
  align-items: center;
  padding: 0 20px;
`;
const Fat = styled.div`
  width: 13.33%;
`;
const DamagedMilk = styled.div`
  width: 26%;
`;
const Amount = styled.div`
  width: 20%;
  font-family: "inter_bold";
`;
const Can = styled.div`
  width: 50%;
  margin-left: 10px;
`;
const Quantity = styled.div`
  width: 50%;
  margin-left: 10px;
  &.er {
    font-family: "inter_bold";
  }
`;
const TableValues = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid #dcecec;
  height: 80px;
  padding: 0 20px;
  justify-content: space-between;
`;
const CoverTime = styled.div`
  display: flex;
  align-items: center;
  margin-right: 10px;
  &:last-child {
    margin-right: 0;
  }
`;
const ShowDate = styled.div`
  background-color: #fff;
  padding: 6px 16px;
  border-radius: 8px;
  margin-left: 5px;
`;
// const TableSection = styled.div``;
