import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isVerified: false,
  accessToken: "",
  refreshToken: "",
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    login: (state, action) => {
      state.isVerified = action.payload.isVerified;
      state.accessToken = action.payload.accessToken;
      state.refreshToken = action.payload.refreshToken;
    },
    loginFunct: (state, action) => {
      state.isVerified = action.payload.isVerified;
      state.accessToken = action.payload.accessToken;
    },
  },
});

const userReducer = userSlice.reducer;
export default userReducer;
export const { login, loginFunct } = userSlice.actions;
