import React, { useContext, useEffect, useState } from "react";
import { styled } from "styled-components";
import List from "../../../../components/List";
import { add, filterIcon } from "../../../../components/assets";
import { Link, useNavigate } from "react-router-dom";
import { ViewLoans } from "../../../../api/auth";
import { Context } from "../../../../context/Store";
import Nodata from "../../../includes/nodata/Nodata";
import Loader from "../../../../components/loader/Loader";

function Loans() {
  const [getLoans, setGetLoans] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  const {
    dispatch,
    state: { user_data, route_data },
  } = useContext(Context);
  const token = user_data.access_token;
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  useEffect(() => {
    ViewLoans(config)
      .then((res) => {
        if (res.app_data.StatusCode == 6000) {
          setGetLoans(res.app_data.data);
          setIsLoading(false);
        } else {
          setGetLoans([]);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        setIsLoading(false);
      });
    setTimeout(() => setIsLoading(false), 1100);
  }, []);

  const getLoanId = (item) => {
    dispatch({
      type: "UPDATE_USER_DATA",
      user_data: {
        loan_id: item.id,
      },
    });
  };
  useEffect(() => {
    dispatch({
      type: "UPDATE_USER_DATA",
      user_data: {
        active_menu: "loans",
      },
    });
  }, []);
  return (
    <Container>
      <TopBox>
        <Title>Loans</Title>
        <NavButtons>
          <Button onClick={() => navigate("/add-loan")}>
            <img src={add} />
            Create
          </Button>
          {/* <Button className="background">
            <img src={filterIcon} />
            Filter
          </Button> */}
        </NavButtons>
      </TopBox>
      <CoverLi>
        <ListContainer>
          <HeadRow>
            <HeadColumnData>Sl. no</HeadColumnData>
            <HeadColumnData>Loan ID</HeadColumnData>
            <HeadColumnData>Society name</HeadColumnData>
            <HeadColumnData>Particulars</HeadColumnData>
            <HeadColumnData>Loan amount</HeadColumnData>
            <HeadColumnData>Pending</HeadColumnData>
            <HeadColumnData>Created Date</HeadColumnData>
            <HeadColumnData>Status</HeadColumnData>
          </HeadRow>
          <BottomContainer>
            {isLoading ? (
              <Loader />
            ) : getLoans.length == 0 ? (
              <Nodata />
            ) : (
              getLoans?.map((row, index) => (
                <Row
                  key={index}
                  to={`/view-loan/${row.id}`}
                  onClick={() => {
                    getLoanId(row);
                  }}
                >
                  <ColumnData>{index + 1}</ColumnData>
                  <ColumnData>{row.loan_id}</ColumnData>
                  <ColumnData>
                    <span>{row.society.name}</span>
                  </ColumnData>
                  <ColumnData>{row.loan_for}</ColumnData>
                  <ColumnData>{row.loan_amount}</ColumnData>
                  <ColumnData
                    style={{
                      color: `${row.pending === "00" ? "#A8A8A8" : "#0A0A0A"}`,
                    }}
                  >
                    {row.pending}
                  </ColumnData>
                  <ColumnData>{row.created_at}</ColumnData>
                  <ColumnData
                    style={{
                      color: `${!row.active ? "#70A059" : "#499DCC"}`,
                    }}
                  >
                    {row.active ? "Active" : "Closed"}
                  </ColumnData>
                </Row>
              ))
            )}
          </BottomContainer>
        </ListContainer>
      </CoverLi>
      {/* <List 
                rowTitle={rowTitle}
                rowValue={rowValue}
            /> */}
    </Container>
  );
}

export default Loans;

const Container = styled.div`
  padding: 20px;
`;
const TopBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  flex-wrap: wrap;
    gap: 10px;
`;
const Title = styled.h2`
  color: #0a0a0a;
  /* margin-bottom: 20px; */
  font-size: 26px;
`;

const NavButtons = styled.div`
  display: flex;
  justify-content: space-between;
`;
const Button = styled.div`
  width: 160px;
  height: 40px;
  background: #fff;
  color: #2382d4;
  align-items: center;
  display: flex;
  justify-content: center;
  border: 1px solid #2382d4;
  border-radius: 4px;
  font-size: 14px;
  padding: 6px 16px;
  cursor: pointer;

  &.background {
    background-color: #2382d4;
    color: #fff;
    margin-left: 15px;
  }

  img {
    margin-right: 10px;
  }
  @media (max-width: 540px) {
    width: 142px;
  }

`;

const BottomContainer = styled.div`
  max-height: calc(100vh - 250px);
  overflow-y: scroll;
`;
const CoverLi = styled.div`
  overflow: scroll;
`;
const ListContainer = styled.div`
  background-color: #fff;
  /* padding: 0 24px; */
  border: 1px solid #dfe8ed;
  border-radius: 8px;
  @media (max-width: 1080px) {
    min-width: 1280px;
    overflow-x: scroll;
  }
  h3 {
    color: #747474;
    font-family: "inter_regular";
    font-size: 22px;
  }
`;

const HeadRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px 20px;
  border-bottom: 1px solid #ccc;
`;
const Row = styled(Link)`
  display: flex;
  text-decoration: none;
  align-items: center;
  justify-content: space-between;
  padding: 30px 20px;
  border-bottom: 1px solid #ccc;
  cursor: pointer;
`;

const HeadColumnData = styled.div`
  color: #747474;
  font-size: 18px;
  font-family: "inter_light";
  width: 10%;
  &:first-child {
    width: 7%;
  }
  &:nth-child(2) {
    width: 15%;
  }
  &:nth-child(3) {
    width: 25%;
  }
  &:nth-child(4) {
    width: 15%;
  }
  &:last-child {
    text-align: right;
  }
`;
const ColumnData = styled.div`
  color: #0a0a0a;
  font-size: 18px;
  font-family: "inter_regular";
  width: 10%;
  text-transform: capitalize;
  &:first-child {
    width: 7%;
  }
  &:nth-child(2) {
    width: 15%;
    color: #2382d4;
  }
  &:nth-child(3) {
    width: 25%;
  }
  &:nth-child(4) {
    width: 15%;
  }
  &:last-child {
    text-align: right;
  }
  span {
    font-family: "inter_light";
  }
`;
