import React, { useContext, useEffect, useState } from "react";
import { styled } from "styled-components";
import { r_build } from "../../../components/assets";
import { useNavigate, useParams } from "react-router-dom";
import { Context } from "../../../context/Store";
import { EditSociety, RouteView, SocietySingleView } from "../../../api/auth";
import Swal from "sweetalert2";
import { deleteSociety } from "../../../api/society";

function SocietyEdit({ isInputDisabled, setInputDisabled, getSocietyDetails }) {
    // edit api states
    const [societyName, setsocietyName] = useState();
    const [societyID, setsocietyID] = useState();
    const [address, setaddress] = useState();
    const [route, setRoute] = useState();
    const [societyPhone, setsocietyPhone] = useState();
    const [presidentName, setpresidentName] = useState();
    const [presidentPhone, setpresidentPhone] = useState();
    const [secretoryName, setSecretoryName] = useState();
    const [secretoryPhone, setSecretoryPhone] = useState();
    const [societyCategory, setSocietyCategory] = useState("");
    const [selectValue, setSelectValue] = useState([]);

    const [accountHolder, setAccountHolder] = useState("");
    const [accountNumber, setAccountNumber] = useState();
    const [ifscCode, setIfscCode] = useState("");
    const [bankName, setBankName] = useState("");
    const [branch, setBranch] = useState("");
    const [societyTransportation, setTransportation] = useState("");
    const [amAmount, setAmAmount] = useState("");
    const [pmAmount, setPmAmount] = useState("");
    const [bonus, setBonus] = useState("");
    const [accountType, setAccountType] = useState("");

    const [bankDetails, setBankDetails] = useState({
        accountHolderName: "",
        accNumber: "",
        ifsc: "",
        bankName: "",
        branch: "",
    });

    const [otherDetails, setOtherDetails] = useState({
        transportation: "",
        amAmount: "",
        pmAmount: "",
        bonus: "",
    });
    const handleToggleInput = () => {
        setInputDisabled((prevDisabled) => !prevDisabled);
    };

    const { id } = useParams();
    const navigate = useNavigate();

    const {
        dispatch,
        state: { user_data },
    } = useContext(Context);
    const token = user_data.access_token;
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };
    useEffect(() => {
        RouteView(config).then((res) => {
            if (res.app_data.StatusCode == 6000) {
                setSelectValue(res.app_data.data);
            }
        });
        SocietySingleView(config, id).then((res) => {
            if (res.app_data.StatusCode == 6000) {
                // setRouteSingleData(res.data);
                setsocietyName(res.app_data.data.name);
                setsocietyID(res.app_data.data.code);
                setaddress(res.app_data.data.location);
                setRoute(res.app_data.data.route);
                setsocietyPhone(res.app_data.data.phone);
                setpresidentName(res.app_data.data.president_name);
                setpresidentPhone(res.app_data.data.president_phone);
                setSecretoryName(res.app_data.data.secretary_name);
                setSecretoryPhone(res.app_data.data.secretary_phone);
                setSocietyCategory(res.app_data.data.type);
                setAccountHolder(res.app_data.data.account_holder_name);
                setAccountNumber(res.app_data.data.account_number);
                setIfscCode(res.app_data.data.ifsc_code);
                setBankName(res.app_data.data.bank_name);
                setBranch(res.app_data.data.branch);
                setTransportation(
                    res.app_data.data.society_travel_expense_status
                );
                setAmAmount(res.app_data.data.am_amount);
                setPmAmount(res.app_data.data.pm_amount);
                setBonus(res.app_data.data.bonus);
                setAccountType(res.app_data.data.accounts_type);
            }
        });
    }, [isInputDisabled]);

    //payload
    const formData = new FormData();
    formData.append("name", societyName);
    formData.append("address", address);
    formData.append("society_phone", societyPhone);
    formData.append("society_type", societyCategory);
    formData.append("route", route);
    formData.append("president_name", presidentName);
    formData.append("president_phone", presidentPhone);
    formData.append("secretary_name", secretoryName);
    formData.append("secretary_phone", secretoryPhone);

    formData.append("account_holder_name", accountHolder);
    formData.append("account_number", accountNumber);
    formData.append("account_type", accountType);
    formData.append("ifsc_code", ifscCode);
    formData.append("bank_name", bankName);
    formData.append("branch", branch);
    formData.append("am_amount", amAmount);
    formData.append("pm_amount", pmAmount);
    formData.append("bonus", bonus);
    formData.append("society_travel_expense_status", societyTransportation);

    const handleSubmit = () => {
        EditSociety(config, formData, id).then((res) => {
            if (res.StatusCode == 6000) {
                window.location.reload();
            }
        });
    };
    const confirmDelete = () => {
        Swal.fire({
            title: "Do you want to delete this society?",
            showDenyButton: true,
            confirmButtonText: "Yes",
            denyButtonText: `No`,
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                deleteRouteFunction();
                navigate("/total-societies");
            }
        });
    };
    const deleteRouteFunction = () => {
        deleteSociety(id, config).then((res) => {
            if (res.app_data.StatusCode == 6000) {
                navigate("/total-societies?society=all");
            } else {
            }
        });
    };
    return (
        <CoverInfo>
            <RouteTop>
                <TitleSection>
                    <IconSection>
                        <img src={r_build} alt="image" />
                    </IconSection>
                    <CoverTitleSec>
                        <TitleRout>{getSocietyDetails?.name}</TitleRout>
                        <Sub>
                            Society ID: <span>{getSocietyDetails?.code}</span>
                        </Sub>
                    </CoverTitleSec>
                </TitleSection>
                <CoverButton>
                    <Button
                        onClick={() => {
                            handleToggleInput();
                            if (!isInputDisabled) {
                                handleSubmit();
                            }
                        }}
                    >
                        {isInputDisabled ? "Edit" : "Submit"}
                    </Button>
                    <More onClick={() => confirmDelete()}>
                        <i class="ri-delete-bin-line"></i>
                    </More>
                </CoverButton>
            </RouteTop>
            <Form>
                <CoverForm>
                    <CoverIn>
                        <Label>Name*</Label>
                        <Input
                            type="text"
                            disabled={isInputDisabled}
                            className={!isInputDisabled && "active"}
                            value={societyName}
                            onChange={(e) => setsocietyName(e.target.value)}
                        />
                    </CoverIn>
                    <CoverIn>
                        <Label>Society ID*</Label>
                        <Input
                            type="text"
                            value={societyID}
                            disabled
                            //   onChange={(e) => setsocietyID(e.target.value)}
                        />
                    </CoverIn>
                </CoverForm>
                <CoverForm>
                    <CoverIn>
                        <Label>Address</Label>
                        <Input
                            type="text"
                            disabled={isInputDisabled}
                            className={!isInputDisabled && "active"}
                            value={address}
                            onChange={(e) => setaddress(e.target.value)}
                        />
                    </CoverIn>
                    <CoverIn>
                        <Label>Society Phone No*</Label>
                        <Input
                            type="number"
                            disabled={isInputDisabled}
                            className={!isInputDisabled && "active"}
                            value={societyPhone}
                            onChange={(e) =>
                                setsocietyPhone(e.target.value.slice(0, 10))
                            }
                        />
                    </CoverIn>
                </CoverForm>
                <CoverForm>
                    <CoverIn>
                        <Label>President Name*</Label>
                        <Input
                            type="text"
                            disabled={isInputDisabled}
                            className={!isInputDisabled && "active"}
                            value={presidentName}
                            onChange={(e) => setpresidentName(e.target.value)}
                        />
                    </CoverIn>
                    <CoverIn>
                        <Label>President Phone No*</Label>
                        <Input
                            type="number"
                            disabled={isInputDisabled}
                            className={!isInputDisabled && "active"}
                            value={presidentPhone}
                            onChange={(e) =>
                                setpresidentPhone(e.target.value.slice(0, 10))
                            }
                        />
                    </CoverIn>
                </CoverForm>
                <CoverForm>
                    <CoverIn>
                        <Label>Secretory Name*</Label>
                        <Input
                            type="text"
                            disabled={isInputDisabled}
                            className={!isInputDisabled && "active"}
                            value={secretoryName}
                            onChange={(e) => setSecretoryName(e.target.value)}
                        />
                    </CoverIn>
                    <CoverIn>
                        <Label>Secretory Phone No*</Label>
                        <Input
                            type="number"
                            disabled={isInputDisabled}
                            className={!isInputDisabled && "active"}
                            value={secretoryPhone}
                            onChange={(e) =>
                                setSecretoryPhone(e.target.value.slice(0, 10))
                            }
                        />
                    </CoverIn>
                </CoverForm>
                <CoverForm>
                    <CoverIn>
                        <Label>Route*</Label>
                        <Select
                            disabled={isInputDisabled}
                            className={!isInputDisabled && "active"}
                            value={route}
                            onChange={(e) => setRoute(e.target.value)}
                        >
                            {selectValue?.map((item) => (
                                <option value={item.id} key={item.code}>
                                    {item.name}
                                </option>
                            ))}
                        </Select>
                    </CoverIn>

                    <CoverIn>
                        <Label>Society Category*</Label>
                        <Select
                            disabled={isInputDisabled}
                            className={!isInputDisabled && "active"}
                            onChange={(e) => setSocietyCategory(e.target.value)}
                            value={societyCategory}
                        >
                            <option value="jeeva_society">Jeeva Society</option>
                            <option value="private_society">
                                Private Society
                            </option>
                            <option value="farm">Farm </option>
                        </Select>
                    </CoverIn>
                </CoverForm>
                <General className="bank">Bank details </General>
                <CoverForm>
                    <CoverIn>
                        <Label>Account Holder Name</Label>
                        <Input
                            type="text"
                            value={accountHolder}
                            disabled={isInputDisabled}
                            className={!isInputDisabled && "active"}
                            onChange={(e) => setAccountHolder(e.target.value)}
                        />
                    </CoverIn>
                    <CoverIn>
                        <Label>Account Number</Label>
                        <Input
                            type="number"
                            value={accountNumber}
                            disabled={isInputDisabled}
                            className={!isInputDisabled && "active"}
                            onChange={(e) => setAccountNumber(e.target.value)}
                        />
                    </CoverIn>
                </CoverForm>
                <CoverForm>
                    <CoverIn>
                        <Label>IFSC Code</Label>
                        <Input
                            type="text"
                            value={ifscCode}
                            disabled={isInputDisabled}
                            className={!isInputDisabled && "active"}
                            onChange={(e) => setIfscCode(e.target.value)}
                        />
                    </CoverIn>
                    <CoverIn>
                        <Label>Bank Name</Label>
                        <Input
                            type="text"
                            // className="auto"
                            value={bankName}
                            disabled={isInputDisabled}
                            className={!isInputDisabled && "active"}
                            onChange={(e) => setBankName(e.target.value)}
                        />
                    </CoverIn>
                </CoverForm>
                <CoverForm>
                    <CoverIn>
                        <Label>Account type</Label>
                        <Select
                            value={accountType}
                            disabled={isInputDisabled}
                            className={!isInputDisabled && "active"}
                            onChange={(e) => setAccountType(e.target.value)}
                        >
                            <option value="10">Savings</option>
                            <option value="11">Current</option>
                        </Select>
                    </CoverIn>
                    <CoverIn className="add">
                        <Label>Branch</Label>
                        <Input
                            type="text"
                            value={branch}
                            disabled={isInputDisabled}
                            className={!isInputDisabled && "active"}
                            onChange={(e) => setBranch(e.target.value)}
                        />
                    </CoverIn>
                </CoverForm>{" "}
                <General className="other">Other Details </General>
                <CoverForm>
                    <CoverIn>
                        <Label>Society Transportation</Label>
                        <Select
                            value={societyTransportation}
                            disabled={isInputDisabled}
                            className={!isInputDisabled && "active"}
                            onChange={(e) => setTransportation(e.target.value)}
                        >
                            <option value="tc_to_society">TC to society</option>
                            <option value="tc_from_society">
                                TC from society
                            </option>
                        </Select>
                    </CoverIn>
                </CoverForm>
                <CoverForm>
                    <CoverIn>
                        <Label>AM Amount</Label>
                        <Input
                            type="text"
                            value={amAmount}
                            disabled={isInputDisabled}
                            className={!isInputDisabled && "active"}
                            onChange={(e) => setAmAmount(e.target.value)}
                        />
                    </CoverIn>
                    <CoverIn>
                        <Label>PM Amount</Label>
                        <Input
                            type="number"
                            // className="auto"
                            value={pmAmount}
                            disabled={isInputDisabled}
                            className={!isInputDisabled && "active"}
                            onChange={(e) => setPmAmount(e.target.value)}
                        />
                    </CoverIn>
                </CoverForm>{" "}
                <CoverForm>
                    <CoverIn className="add">
                        <Label>Bonus (% / Litre)</Label>
                        <Input
                            type="text"
                            value={bonus}
                            disabled={isInputDisabled}
                            className={!isInputDisabled && "active"}
                            onChange={(e) => setBonus(e.target.value)}
                        />
                    </CoverIn>
                </CoverForm>
            </Form>
        </CoverInfo>
    );
}

export default SocietyEdit;

const RouteTop = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
    flex-wrap: wrap;
    gap: 15px;
`;
const TitleRout = styled.h2`
    font-size: 22px;
    text-transform: capitalize;
    color: #0a0a0a;
`;
const General = styled.h3`
    color: #0a0a0a;
    position: relative;
    overflow: hidden;
    font-size: 22px;
    margin-bottom: 30px;
    &::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 1px;
        left: 100px;
        background-color: #ebebeb;
        bottom: 10px;
    }
    &.veh {
        margin-top: 30px;
        &::after {
            content: "";
            position: absolute;
            width: 100%;
            height: 1px;
            left: 260px;
            bottom: 10px;
            background-color: #ebebeb;
        }
    }
    &.bank {
        margin-top: 30px;
        &::after {
            content: "";
            position: absolute;
            height: 1px;
            right: 0px;
            bottom: 10px;
            background-color: #ebebeb;
            left: unset;
            width: 80%;
        }
    }
    &.other {
        margin-top: 30px;
        &::after {
            content: "";
            position: absolute;
            height: 1px;
            right: 0px;
            bottom: 10px;
            background-color: #ebebeb;
            left: unset;
            width: 75%;
        }
    }
`;
const Select = styled.select`
    width: 100%;
    background-color: #f4f5f8;
    border: 1px solid #dfe8ed;
    height: 60px;
    font-size: 18px;
    padding-left: 20px;
    border-radius: 5px;
    outline: none;
    &.auto {
        color: #adadad;
    }
    &.active {
        border: 1px solid #00a4ff;
    }
`;
const Button = styled.div`
    width: 120px;
    color: #fff;
    cursor: pointer;
    border-radius: 5px;
    background-color: #2382d4;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
`;
const Form = styled.form``;
const CoverForm = styled.div`
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
`;
const CoverIn = styled.div`
    width: 50%;
    margin-right: 20px;
    margin-bottom: 30px;
    &:nth-child(even) {
        margin-right: 0;
    }
`;
const Label = styled.h3`
    color: #747474;
    font-family: "inter_regular";
    margin-bottom: 10px;
`;
const Input = styled.input`
    width: 100%;
    background-color: #f4f5f8;
    border: 1px solid #dfe8ed;
    height: 60px;
    font-size: 18px;
    padding-left: 20px;
    border-radius: 5px;
    &.active {
        border: 1px solid #00a4ff;
    }
`;
const CoverInfo = styled.div`
    /* width: 65%; */
`;
const TitleSection = styled.div`
    display: flex;
    align-items: center;
`;
const IconSection = styled.div`
    margin-right: 10px;
`;
const CoverTitleSec = styled.div`
    /* display: flex;
  align-items: center; */
`;
const Sub = styled.div`
    color: #747474;
    margin-top: 5px;
    font-family: "inter_regular";
    font-size: 16px;
    span {
        font-family: "inter_medium";
    }
`;
const CoverButton = styled.div`
    display: flex;
    align-items: center;
`;
const More = styled.div`
    font-size: 28px;
    margin-left: 10px;
    color: #dc3737;
    cursor: pointer;
`;
