import React, { useContext, useEffect, useState } from "react";
import { styled } from "styled-components";
import { action, curve, left_arrow, plus } from "../../../components/assets";
import { routs_societies } from "../../../utils/arrays";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Context } from "../../../context/Store";
import CreateSociety from "../creation/CreateSociety";
import { SocietiesListView } from "../../../api/auth";
import Nodata from "../../includes/nodata/Nodata";
import Loader from "../../../components/loader/Loader";
import ReactPaginate from "react-paginate";
import { SearchInput } from "../../../components";
import { CopyToClipboard } from "react-copy-to-clipboard";

function PlantSociety() {
  const [isActive, setActive] = useState("all");
  const [isCopied, setCopied] = useState("");
  const [getSocieties, setGetSocieties] = useState();
  const [pagination, setPagination] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [searchedItem, setSearchedItem] = useState("");

  const [isLoading, setIsLoading] = useState(true);
  const [isAdd, setAdd] = useState(false);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const type = queryParams.get("society");
  const navigate = useNavigate();

  function handlePageClick({ selected: selectedPage }) {
    setCurrentPage(selectedPage + 1);
  }
  const startIndex = (currentPage - 1) * 8;
  const {
    dispatch,
    state: { user_data },
  } = useContext(Context);
  const token = user_data.access_token;
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  useEffect(() => {
    dispatch({
      type: "UPDATE_USER_DATA",
      user_data: {
        active_menu: "socities",
      },
    });
  }, []);

  const society_type = type == "all" ? "" : type;
  const page = currentPage;

  useEffect(() => {
    SocietiesListView(config, searchedItem, society_type, page)
      .then((res) => {
        if (res.app_data.StatusCode == 6000) {
          setGetSocieties(res.app_data.data);
          setPagination(res.app_data.pagination_data);
          setIsLoading(false);
        } else {
          setIsLoading(false);
          setGetSocieties([]);
        }
      })
      .catch((err) => {
        setIsLoading(false);
      });
  }, [type, currentPage, searchedItem, isAdd]);
  return (
    <MainContainer>
      {isAdd ? (
        <CreateSociety setAdd={setAdd} />
      ) : (
        <>
          <TopBar>
            <TopCover>
              <Text>
                <TopTitle>Societies</TopTitle>
                {/* <RoutePath>Routs / Rout no : 0001/societies</RoutePath> */}
              </Text>
            </TopCover>
            <SearchCover>
              <SearchInput state={searchedItem} setState={setSearchedItem} />
              <Add onClick={() => setAdd(true)}>
                <Icon>
                  <img src={plus} alt="icon" />
                </Icon>
                <span>Add New Societies</span>
              </Add>
            </SearchCover>
          </TopBar>
          <CoverLi>
            <SocietyList>
              <Tabs>
                <Tab
                  to="/total-societies/?society=all"
                  onClick={() => setActive("all")}
                  className={type === "all" && "active"}
                >
                  All
                </Tab>
                <Tab
                  to="/total-societies/?society=jeeva_society"
                  onClick={() => setActive("jeeva_society")}
                  className={type === "jeeva_society" && "active"}
                >
                  Jeeva societies
                </Tab>
                <Tab
                  onClick={() => setActive("private_society")}
                  to="/total-societies/?society=private_society"
                  className={type === "private_society" && "active"}
                >
                  Private societies
                </Tab>
                <Tab
                  to="/total-societies/?society=farm"
                  onClick={() => setActive("farm")}
                  className={type === "farm" && "active"}
                >
                  Farms
                </Tab>
              </Tabs>

            <ListLabels>
              <LabelName type="all"> No.</LabelName>
              <LabelName type="id"> ID</LabelName>
              <LabelName type="soc"> Name</LabelName>
              <LabelName type="soc">President </LabelName>
              <LabelName type="loc">Location</LabelName>
              <LabelName type="no">Phone no</LabelName>
              <LabelName type="milk">Average milk </LabelName>
              <LabelName type="action">Username</LabelName>
              <LabelName type="action">Password</LabelName>
            </ListLabels>
            {isLoading ? (
              <Loader />
            ) : getSocieties?.length == 0 ? (
              <Nodata />
            ) : (
              getSocieties?.map((item, index) => (
                <List key={index} to={`/total-societies/${item.id}`}>
                  <Item type="all">
                    <Serial>{startIndex + index + 1}</Serial>
                  </Item>
                  <Item type="id">
                    <Id title={item.code}>{item.code}</Id>
                  </Item>
                  <Item type="soc">
                    <span>{item.name}</span>
                  </Item>
                  <Item type="soc">
                    <CoverItem>
                      <CoverName>
                        <Name>{item.president_name}</Name>
                        <Phone>{item.president_phone}</Phone>
                      </CoverName>
                    </CoverItem>
                  </Item>
                  <Item type="loc">
                    <span>{item.location ? item.location : "-"}</span>
                  </Item>
                  <Item type="no">
                    <Span>{item.phone ? item.phone : "-"}</Span>
                  </Item>
                  <Item type="milk">
                    <Span>{item.average_milk} Litres</Span>
                  </Item>
                  <Item
                    type="action"
                    className={isCopied == item.code && "active"}
                    onClick={(e) => {
                      e.preventDefault();
                    }}
                  >
                    <span>{item.code}</span>
                    <CopyToClipboard
                      text={item.code}
                      onCopy={() => setCopied(item.code)}
                    >
                      <i class="ri-file-copy-line"></i>
                    </CopyToClipboard>
                  </Item>
                  <Item
                    className={isCopied == item.password && "active"}
                    type="action"
                    onClick={(e) => {
                      e.preventDefault();
                    }}
                  >
                    <span>{item.password}</span>

                      <CopyToClipboard
                        text={item.password}
                        onCopy={() => setCopied(item.password)}
                      >
                        <i class="ri-file-copy-line"></i>
                      </CopyToClipboard>
                    </Item>
                  </List>
                ))
              )}
            </SocietyList>
          </CoverLi>
        </>
      )}
      {!isAdd &&
        pagination &&
        pagination.total_pages > 1 &&
        getSocieties.length > 0 && (
          <PaginationContainer>
            <PaginationText>
              Showing Societies : {pagination.first_item} -{" "}
              {pagination.last_item} of {pagination.total_items} Societies
            </PaginationText>
            <ReactPaginate
              previousLabel={"<"}
              nextLabel={">"}
              pageCount={pagination.total_pages}
              onPageChange={handlePageClick}
              containerClassName={"pagination"}
              previousLinkClassName={"pagination__link"}
              nextLinkClassName={"pagination__link"}
              disabledClassName={"pagination__link--disabled"}
              activeClassName={"pagination__link--active"}
            />
          </PaginationContainer>
        )}
    </MainContainer>
  );
}

export default PlantSociety;
const MainContainer = styled.div`
  padding: 20px;
  max-height: calc(100vh - 100px);
  overflow: scroll;
  overflow-x: hidden;
`;
const TopBar = styled.div`
  position: relative;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
`;
const TopCover = styled.div`
  display: flex;
`;
const Arrow = styled.div`
  border: 1px solid #2382d4;
  display: flex;
  cursor: pointer;
  border-radius: 5px;
  padding: 4px;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
`;
const Text = styled.div``;
const SearchCover = styled.div`
  display: flex;
`;
const TopTitle = styled.h2`
  &.create {
    width: 70%;
    margin: 0 auto;
    margin-bottom: 10px;
    color: #0a0a0a;
  }
`;
const RoutePath = styled.h4`
  color: #747474;
`;
const Curve = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  img {
    width: 100%;
    display: block;
  }
`;
const Add = styled.div`
  background-color: #fff;
  border: 1px solid #2382d4;
  color: #2382d4;
  /* width: 120px; */
  height: 35px;
  display: flex;
  padding: 20px;
  border-radius: 5px;
  cursor: pointer;
  z-index: 1;
  justify-content: space-between;
  align-items: center;
  margin-left: 10px;
  span{
    white-space:nowrap;
    @media (max-width: 1080px) {
     font-size:13px;
    }
  }
  
`;
const Icon = styled.div`
  margin-right: 10px;
  img {
    display: block;
  }
`;
const CoverLi = styled.div`
  overflow: scroll;
`;

const SocietyList = styled.div`
  background-color: #fff;
  padding: 20px;
  border: 1px solid #dfe8ed;
  border-radius: 5px;
  @media (max-width: 1080px) {
    min-width: 1280px;
    overflow-x: scroll;
  }
`;
const Tab = styled(Link)`
  border-top: 1px solid #dfe8ed;
  text-decoration: none;
  border-left: 1px solid #dfe8ed;
  border-right: 1px solid #dfe8ed;
  border-bottom: 3px solid transparent;
  padding: 14px 20px;
  cursor: pointer;
  margin-right: 30px;
  color: #797d82;
  border-radius: 5px 5px 0 0;
  &.active {
    color: #2382d4;
    background-color: #e0f3ff;
    border-top: 1px solid #2382d4;
    border-left: 1px solid #2382d4;
    border-right: 1px solid #2382d4;
    border-bottom: 3px solid #2382d4;
  }
`;
const Tabs = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
const Item = styled.div`
  /* width: 20%; */
  width: 140px;
  font-size: 16px;
  color: #2e3032;
  span {
    color: #2e3032;
  }
  &.active {
    i {
      color: #74d874;
    }
  }
  i {
    margin-left: 10px;
    font-size: 18px;
  }
  width: ${({ type }) =>
    type === "all"
      ? "45px"
      : type === "id"
      ? "90px"
      : type === "soc"
      ? "170px"
      : type === "loc"
      ? "120px"
      : type === "no"
      ? "150px"
      : type === "milk"
      ? "110px"
      : type === "action"
      ? "140px"
      : ""};
  text-overflow: ellipsis;
  overflow: ${({ type }) => (type == "action" ? "" : "hidden")};
  white-space: nowrap;
`;
const LabelName = styled.h4`
  color: #797d82;
  font-family: "inter_regular";
  /* width: 20%; */
  width: ${({ type }) =>
    type === "all"
      ? "45px"
      : type === "id"
      ? "90px"
      : type === "soc"
      ? "170px"
      : type === "loc"
      ? "120px"
      : type === "no"
      ? "150px"
      : type === "milk"
      ? "110px"
      : type === "action"
      ? "140px"
      : ""};
`;
const ListLabels = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border-bottom: 1px solid #dfe8ed;
  border-top: 1px solid #dfe8ed;
`;
const List = styled(Link)`
  padding: 20px;
  text-decoration: none;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #dfe8ed;
  align-items: center;
  cursor: pointer;
`;
const Serial = styled.h4`
  color: #2e3032;
`;
const Id = styled.h4`
  color: #2382d4;
  text-overflow: ellipsis;
  width: 85px;
  overflow: hidden;
`;
const CoverItem = styled.div`
  display: flex;
  align-items: center;
`;
const Iconz = styled.div`
  background-color: #e0f3ff;
  color: #2382d4;
  font-size: 14px;
  padding: 10px;
  font-family: "inter_medium";
  border-radius: 50%;
  margin-right: 10px;
`;
const CoverName = styled.div``;
const Name = styled.h4`
  color: #2e3032;
  font-size: 14px;
  margin-bottom: 5px;
`;
const Phone = styled.h4`
  font-size: 14px;
  color: #808080;
`;
const Span = styled.div`
  span {
    color: #2382d4;
    white-space: nowrap;
  }
`;
const Icons = styled.div``;

const PaginationContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0 15px;
  @media all and (max-width: 1380px) {
    padding: 15px 0px;
    margin-right: 40px;
  }
  @media all and (max-width: 1280px) {
    margin-right: 20px;
    padding: 20px 0px;
  }
  @media all and (max-width: 980px) {
    margin-right: 10px;
  }
  @media all and (max-width: 890px) {
    flex-direction: column;
  }
`;
const PaginationText = styled.p`
  color: #898989;
  font-size: 14px;
  @media all and (max-width: 890px) {
    margin-bottom: 10px;
  }
`;
