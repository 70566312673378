import React, { useContext, useEffect, useState } from "react";
import { calendar, filter, plus, plus_sign } from "../../../components/assets";
import { society_localsales } from "../../../utils/arrays";
import { styled } from "styled-components";
import { Link } from "react-router-dom";
import { GetLocalSales } from "../../../api/society";
import { Context } from "../../../context/Store";
import Nodata from "../../includes/nodata/Nodata";
import Loader from "../../../components/loader/Loader";
import ReactPaginate from "react-paginate";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import api from "../../../api";
import { getYYMMDD } from "../../../utils/functions/function";

function SocietyLocalSales() {
  const [getLocalSaleData, setLocalSaleData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [pagination, setPagination] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEnddate] = useState("");

  const startIndex = (currentPage - 1) * 8;

  function handlePageClick({ selected: selectedPage }) {
    setCurrentPage(selectedPage + 1);
  }
  const CustomInput = ({ value, onClick }) => (
    <input
      type="text"
      value={value}
      onClick={onClick}
      style={{ width: "100px", height: "30px" }}
    />
  );
  //get accesstoken
  const {
    dispatch,
    state: { user_data },
  } = useContext(Context);
  const token = user_data.access_token;
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const customStyles = {
    width: "100px",
    height: "30px",
    borderRadis: "8px",
    // Add any other custom styles here if needed
  };
  useEffect(() => {
    dispatch({
      type: "UPDATE_USER_DATA",
      user_data: {
        active_menu: "local-sales",
      },
    });
  }, []);

  useEffect(() => {
    api
      .get("/activities/view-society-local-sales/", {
        headers: {
          Authorization: "Bearer " + token,
        },
        params: {
          from_date: getYYMMDD(startDate),
          to_date: getYYMMDD(endDate),
          page: currentPage,
        },
      })
      .then((res) => {
        if (res.app_data.StatusCode == 6000) {
          setLocalSaleData(res.app_data.data);
          setPagination(res.app_data.pagination_data);
          setIsLoading(false);
        } else {
          setLocalSaleData([]);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        setIsLoading(false);
      });
    setTimeout(() => setIsLoading(false), 1100);
  }, [endDate, currentPage]);

  return (
    <MainContainer>
      <TopBar>
        <Title>Local sales</Title>
        <CoverTop>
          <TopRight>
            <ButContainer>From</ButContainer>
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              required
              dateFormat="dd-MM-yyyy"
              customInput={<CustomInput />}
              style={customStyles}
            />
            <ButContainer>To</ButContainer>
            <DatePicker
              selected={endDate}
              onChange={(date) => setEnddate(date)}
              required
              dateFormat="dd-MM-yyyy"
              customInput={<CustomInput />}
              style={customStyles}
            />
          </TopRight>
          <Switch to="/add-local-sale">
            <Icon>
              <img src={plus} alt="image" />
            </Icon>
            <SwitchTitle>Add</SwitchTitle>
          </Switch>
        </CoverTop>
      </TopBar>
      <div className="parent-scroll">
        <Table className="scroll">
          <LabelItems>
            <Label>Sl No</Label>
            <Label>Date</Label>
            <Label>Name</Label>
            <Label>Phone</Label>
            <Label>Quantity</Label>
            <Label>Price per litres</Label>
            <Label>Total amount</Label>
          </LabelItems>
          <div
            style={{
              height: "calc(100vh - 325px)",
              overflowY: "scroll",
            }}
          >
            {isLoading ? (
              <Loader />
            ) : getLocalSaleData?.length == 0 ? (
              <Nodata />
            ) : (
              getLocalSaleData?.map((item, index) => (
                <CardLabel key={index}>
                  <Item>{startIndex + index + 1} </Item>
                  <Item>
                    <Icon>
                      <img src={calendar} className="cal" alt="image" />
                      <span>{item.created_at}</span>
                    </Icon>
                  </Item>
                  <Item>{item.name} </Item>
                  <Item>{item.phone} </Item>
                  <Item className="blue">{item.quantity} litre</Item>
                  <Item>{item.price_per_liter} per litre</Item>
                  <Item>₹{item.price}</Item>
                </CardLabel>
              ))
            )}
          </div>
        </Table>
      </div>
      {pagination &&
        pagination.total_pages > 1 &&
        getLocalSaleData.length > 0 && (
          <PaginationContainer>
            <PaginationText>
              Showing Farmers : {pagination.first_item} - {pagination.last_item}{" "}
              of {pagination.total_items} Enquiries
            </PaginationText>
            <ReactPaginate
              previousLabel={"<"}
              nextLabel={">"}
              pageCount={pagination.total_pages}
              onPageChange={handlePageClick}
              containerClassName={"pagination"}
              previousLinkClassName={"pagination__link"}
              nextLinkClassName={"pagination__link"}
              disabledClassName={"pagination__link--disabled"}
              activeClassName={"pagination__link--active"}
            />
          </PaginationContainer>
        )}
    </MainContainer>
  );
}

export default SocietyLocalSales;
const MainContainer = styled.div`
  padding: 30px;
`;
const TopBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  flex-wrap:wrap;
  gap:10px;
`;
const CoverTop = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
  flex-wrap: wrap;
`;
const Title = styled.h2``;
const Icon = styled.div`
  display: flex;
  span {
    font-size: 14px;
  }
  img {
    margin-right: 5px;
    &.cal {
      filter: grayscale(1);
    }
  }
`;
const Switch = styled(Link)`
  display: flex;
  text-decoration: none;
  border-radius: 8px;
  justify-content: space-between;
  align-items: center;
  padding: 6px 16px;
  border: 1px solid #7fadd4;
  margin-right: 10px;
  cursor: pointer;
  &:last-child {
    margin-right: 0;
  }
`;
const SwitchTitle = styled.div`
  color: #2382d4;
  text-decoration: none;
`;
const TopRight = styled.div`
  display: flex;
  gap: 5px;
  display: flex;
`;
const ButContainer = styled.div`
  padding: 5px 8px;
  background: #2382d4;
  border-radius: 8px;
  color: #fff;
  font-size: 14px;
  font-weight: 400;
`;
const Table = styled.div`
  background-color: #fff;
  border-radius: 5px;
`;
const LabelItems = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 30px;
  align-items: center;
  border-bottom: 1px solid #dfe8ed;
`;
const CardLabel = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #dfe8ed;
  align-items: center;
  padding: 30px;
`;
const Item = styled.div`
  width: 20%;
  font-family: "inter_medium";
  color: #2e3032;
  &.blue {
    color: #2382d4;
  }
`;
const Label = styled.div`
  width: 20%;
  color: #797d82;
  font-family: "inter_regular";
  font-size: 18px;
`;
const PaginationContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0 15px;
  @media all and (max-width: 1380px) {
    padding: 15px 0px;
    margin-right: 40px;
  }
  @media all and (max-width: 1280px) {
    margin-right: 20px;
    padding: 20px 0px;
  }
  @media all and (max-width: 980px) {
    margin-right: 10px;
  }
  @media all and (max-width: 890px) {
    flex-direction: column;
  }
`;
const PaginationText = styled.p`
  color: #898989;
  font-size: 14px;
  @media all and (max-width: 890px) {
    margin-bottom: 10px;
  }
`;
