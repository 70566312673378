import React, { useContext, useState } from "react";
import { styled } from "styled-components";
import { DashboardImage, JeevaLogo } from "../../components/assets/images";
import { Input } from "../../components";
import { LoginFunction } from "../../api/auth";
import { useDispatch, useSelector } from "react-redux";
import { loginFunct } from "../../redux/reducers/auth/userSlice";
import { Context } from "../../context/Store";
import { useNavigate } from "react-router-dom";
import ButtonLoader from "../../components/button-loader/ButtonLoader";

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPaswword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const {
    dispatch,
    state: { user_data },
  } = useContext(Context);
  const navigate = useNavigate();
  const handleLogin = () => {
    setIsButtonLoading(true);
    if (!username) {
      setErrorMessage("Please enter username");
      setIsButtonLoading(false);
    } else if (!password) {
      setErrorMessage("Please enter password");
      setIsButtonLoading(false);
    } else {
      LoginFunction(username, password).then((response) => {
        if (response.app_data.StatusCode == 6000) {
          setErrorMessage("");
          setIsButtonLoading(false);
          dispatch({
            type: "UPDATE_USER_DATA",
            user_data: {
              isVerified: true,
              access_token: response.app_data.data.access.access,
              refresh_token: response.app_data.data.access.refresh,
              roles: response.app_data.data.roles[0],
              username: response.app_data.data.username,
              privileges: response.app_data.data.active_privileges,
              society_id: response.app_data.data?.society_id,
            },
          });
          navigate("/");
        } else {
          setErrorMessage(response.app_data.data.message);
          setIsButtonLoading(false);
        }
      });
    }
  };

  return (
    <Cover>
      <Left>
        <FormContainer onSubmit={e =>{
          e.preventDefault();
          handleLogin();
        }}>
          <Head>Login</Head>
          <InputCover
            label="User Name"
            type="text"
            placaholder="username"
            margin="15px"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <InputCover
            label="Password"
            type="password"
            placaholder="password"
            margin=""
            onChange={(e) => setPaswword(e.target.value)}
            value={password}
          />
          {/* <a href="#">Forgot password?</a> */}
          <ErrorMessage>{errorMessage}</ErrorMessage>
          <ButtonLogin
            type="submit"
            onClick={() => {
              //   loginFunction(username, password);
              handleLogin();
            }}
          >
            {isButtonLoading ? <ButtonLoader /> : "Login"}
          </ButtonLogin>
        </FormContainer>
      </Left>
      <Right>
        <Container>
          <LogoContainer>
            <img src={JeevaLogo} alt="Jeeva" />
            <h1>Jeeva milk</h1>
          </LogoContainer>
          {/* <p>Sell yo</p> */}
        </Container>
        <ImageContainer>
          <img src={DashboardImage} />
        </ImageContainer>
        <ImageContainer2>
          <img src={DashboardImage} />
        </ImageContainer2>
      </Right>
    </Cover>
  );
};

export default Login;

const Cover = styled.div`
  height: 100vh;
  display: flex;
`;
const Left = styled.div`
  height: 100%;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 768px) {
    width: 100%;
    background: beige;
  }
`;
const Right = styled.div`
  background: linear-gradient(138deg, #0882cc 0%, #023d61 100%);
  height: 100%;
  width: 50%;
  padding-top: 110px;
  display: flex;
  justify-content: center;
  position: relative;
  overflow: hidden;
  @media (max-width: 768px) {
    display: none;
  }
`;
const Container = styled.div`
  p {
    text-align: center;
    color: #fff;
    margin-top: 15px;
    font-family: "inter_light";
  }
`;
const LogoContainer = styled.div`
  width: 120px;
  height: 120px;
  border: 1px solid #fff;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  flex-direction: column;
  gap: 6px;
  margin: 0 auto;
  h1 {
    font-size: 14px;
    color: #fff;
  }
  img {
    width: 50px;
    object-fit: contain;
  }
`;
const ImageContainer = styled.div`
  position: absolute;
  bottom: -16%;
  right: -20%;
  width: 100%;
  z-index: 5;
  border-radius: 10px;
  oveflow: hidden;
  img {
    width: 100%;
  }
`;
const ImageContainer2 = styled.div`
  position: absolute;
  bottom: -8%;
  right: -30%;
  width: 100%;
  z-index: 2;
  img {
    width: 100%;
  }
`;
const FormContainer = styled.form`
  width: 60%;
  a {
    color: #858585;
    font-size: 14px;
    text-decoration: none;
    text-align: right;
    display: block;
    margin-top: 5px;
    margin-bottom: 20px;
  }
  @media (min-width: 640px)and (max-width:980px) {
    width: 75%;
  }
`;
const Head = styled.div`
  font-size: 22px;
  text-align: left;
  margin-bottom: 20px;
  @media (min-width: 640px)and (max-width:980px) {
    text-align: center;
  }
`;
const InputCover = styled(Input)`
  margin-bottom: 20px;
`;
const ErrorMessage = styled.span`
  color: red;
  margin-bottom: 5px;
  text-align: center;
  width: 100%;
  display: inline-block;
  &::first-letter {
    text-transform: uppercase;
  }
`;
const ButtonLogin = styled.button`
  width: 100%;
  height: 40px;
  border-radius: 5px;
  background: #2382d4;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "inter_medium";
  cursor: pointer;
  outline: none;
  border: none;
`;
