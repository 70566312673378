import React from "react";
import { styled } from "styled-components";

function RectangleColorButton({ label, submitFunction }) {
  return (
    <Cover onClick={() => submitFunction()}>
      <Label>{label}</Label>
    </Cover>
  );
}

export default RectangleColorButton;

const Label = styled.label``;

const Cover = styled.div`
  width: 160px;
  height: 48px;
  /* cursor: pointer; */
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #2382d4;
  border-radius: 4px;
  padding: 6px 12px;
  background: #2382d4;

  ${Label} {
    font-size: 14px;
    color: #fff;
    font-family: "inter_light";
  }
`;
